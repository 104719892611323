
let feature1 = {
    nm: "Online Learning",
    ddd: 0,
    h: 500,
    w: 750,
    meta: { g: "LottieFiles AE " },
    layers: [
      {
        ty: 4,
        nm: "Leaf Table",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [55.726, 76.882, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [660.353, 392.443, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.332, 0],
                      [0, 0],
                      [0, 1.332],
                      [0, 0],
                      [-1.332, 0],
                      [0, 0],
                      [0, -1.332],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-1.331, 0],
                      [0, 0],
                      [0, -1.332],
                      [0, 0],
                      [1.332, 0],
                      [0, 0],
                      [0, 1.332],
                    ],
                    v: [
                      [35.017, 2.965],
                      [-35.018, 2.965],
                      [-37.428, 0.554],
                      [-37.428, -0.553],
                      [-35.017, -2.964],
                      [35.017, -2.964],
                      [37.428, -0.553],
                      [37.428, 0.554],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.672, 92.544], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [18.38, 50.109],
                      [12.711, 50.109],
                      [-17.154, -46.158],
                      [-18.38, -50.109],
                      [-1.751, -50.109],
                      [-1.331, -48.014],
                      [-1.329, -48.014],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [83.362, 99.409], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [18.38, -50.109],
                      [16.296, -43.391],
                      [-12.711, 50.109],
                      [-18.38, 50.109],
                      [0.016, -41.482],
                      [1.749, -50.109],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [26.301, 99.409], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [26.21, -0.723],
                      [25.665, 0.723],
                      [-25.665, 0.723],
                      [-26.21, -0.723],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.747, 17.789], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [28.359, -0.724],
                      [27.813, 0.724],
                      [-27.813, 0.724],
                      [-28.359, -0.724],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.747, 12.086], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.464, -0.724],
                      [29.92, 0.724],
                      [-29.921, 0.724],
                      [-30.464, -0.724],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.747, 6.494], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.588, -1.566],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.674, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.591, -1.566],
                      [0, 0],
                      [1.671, 0],
                    ],
                    v: [
                      [31.326, -15.025],
                      [30.465, -12.742],
                      [29.922, -11.295],
                      [28.36, -7.15],
                      [27.814, -5.703],
                      [26.211, -1.446],
                      [25.667, -0.001],
                      [18.789, 18.262],
                      [-18.786, 18.262],
                      [-25.664, -0.001],
                      [-26.208, -1.446],
                      [-27.812, -5.703],
                      [-28.358, -7.15],
                      [-29.919, -11.295],
                      [-30.462, -12.742],
                      [-31.323, -15.025],
                      [-29.084, -18.262],
                      [29.087, -18.262],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.746, 18.512], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.137, 0],
                      [0, 0],
                      [0, -1.138],
                      [0, 0],
                      [1.137, 0],
                      [0, 0],
                      [0, 1.138],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [1.137, 0],
                      [0, 0],
                      [0, 1.138],
                      [0, 0],
                      [-1.137, 0],
                      [0, 0],
                      [0, -1.138],
                    ],
                    v: [
                      [-34.213, -6.263],
                      [34.214, -6.263],
                      [36.273, -4.202],
                      [36.273, 4.204],
                      [34.214, 6.263],
                      [-34.213, 6.263],
                      [-36.273, 4.204],
                      [-36.273, -4.202],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.746, 43.037], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.332, 0],
                      [0, 0],
                      [0, 1.332],
                      [0, 0],
                      [-1.331, 0],
                      [0, 0],
                      [0, -1.332],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-1.331, 0],
                      [0, 0],
                      [0, -1.332],
                      [0, 0],
                      [1.332, 0],
                      [0, 0],
                      [0, 1.332],
                    ],
                    v: [
                      [35.017, 2.964],
                      [-35.018, 2.964],
                      [-37.428, 0.553],
                      [-37.428, -0.554],
                      [-35.018, -2.965],
                      [35.017, -2.965],
                      [37.428, -0.554],
                      [37.428, 0.553],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.672, 105.436], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 10",
            ix: 10,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -3.028],
                      [30.638, 0],
                      [0, 3.027],
                      [-30.638, 0],
                    ],
                    o: [
                      [0, 3.027],
                      [-30.638, 0],
                      [0, -3.028],
                      [30.638, 0],
                    ],
                    v: [
                      [55.476, 0],
                      [0, 5.481],
                      [-55.476, 0],
                      [0, -5.482],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [55.725, 148.032], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: "Leaf3",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [3.19, 106.725, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [55.786, 35.179, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-5],
                t: 126,
              },
              { s: [0], t: 240.0000097754 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.238, 0],
                      [0.038, 0.009],
                      [-0.063, 0.282],
                      [1.343, 6.626],
                      [0.022, 0.042],
                      [-0.255, 0.133],
                      [-0.133, -0.256],
                      [-1.488, -7.313],
                      [2.922, -12.919],
                    ],
                    o: [
                      [-0.038, 0],
                      [-0.282, -0.064],
                      [2.877, -12.717],
                      [-1.457, -7.184],
                      [-0.133, -0.256],
                      [0.256, -0.132],
                      [0.09, 0.174],
                      [1.371, 6.736],
                      [-0.055, 0.242],
                    ],
                    v: [
                      [0.161, 21.421],
                      [0.045, 21.408],
                      [-0.349, 20.783],
                      [0.186, -9.129],
                      [-3.458, -20.585],
                      [-3.236, -21.289],
                      [-2.531, -21.066],
                      [1.201, -9.381],
                      [0.669, 21.014],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [15.819, 36.631], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.206, 0],
                      [0.065, 0.025],
                      [-0.11, 0.268],
                      [0.012, 0.25],
                      [-0.287, 0.014],
                      [-0.014, -0.288],
                      [8.245, -20.194],
                    ],
                    o: [
                      [-0.065, 0],
                      [-0.267, -0.109],
                      [8.155, -19.975],
                      [-0.013, -0.288],
                      [0.285, -0.015],
                      [0.012, 0.252],
                      [-0.083, 0.202],
                    ],
                    v: [
                      [-4.068, 23.242],
                      [-4.265, 23.204],
                      [-4.551, 22.522],
                      [2.507, -22.68],
                      [3.003, -23.226],
                      [3.55, -22.73],
                      [-3.585, 22.918],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [22.056, 30.834], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.138, 0],
                      [0.101, 0.097],
                      [-0.199, 0.208],
                      [-6.54, 3.382],
                      [-0.195, 0.05],
                      [-0.071, -0.279],
                      [0.279, -0.07],
                      [7.003, -3.625],
                      [9.774, -10.213],
                    ],
                    o: [
                      [-0.13, 0],
                      [-0.208, -0.199],
                      [9.886, -10.33],
                      [7.101, -3.672],
                      [0.278, -0.07],
                      [0.07, 0.281],
                      [-0.048, 0.012],
                      [-6.461, 3.345],
                      [-0.103, 0.107],
                    ],
                    v: [
                      [-19.019, 12.897],
                      [-19.38, 12.752],
                      [-19.396, 12.014],
                      [6.901, -7.898],
                      [18.891, -12.827],
                      [19.526, -12.449],
                      [19.148, -11.814],
                      [7.344, -6.952],
                      [-18.643, 12.736],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [34.11, 47.146], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.175, 0],
                      [0.085, 0.053],
                      [-0.152, 0.245],
                      [-0.216, 0.157],
                      [-0.17, -0.233],
                      [0.232, -0.17],
                      [13.911, -22.59],
                    ],
                    o: [
                      [-0.094, 0],
                      [-0.247, -0.151],
                      [14.021, -22.771],
                      [0.232, -0.17],
                      [0.17, 0.233],
                      [-0.214, 0.156],
                      [-0.098, 0.161],
                    ],
                    v: [
                      [-17.802, 19.865],
                      [-18.076, 19.788],
                      [-18.247, 19.07],
                      [17.499, -19.695],
                      [18.228, -19.581],
                      [18.115, -18.852],
                      [-17.359, 19.617],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [33.561, 38.541], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.286, 0],
                      [0, 0],
                      [-0.004, 0.288],
                      [-4.027, 12.004],
                      [-5.553, 10.664],
                      [-0.095, 0.131],
                      [-0.233, -0.169],
                      [0.169, -0.233],
                      [9.417, -18.085],
                      [0.257, -21.575],
                    ],
                    o: [
                      [0, 0],
                      [-0.288, -0.004],
                      [0.114, -9.629],
                      [3.217, -9.587],
                      [9.453, -18.15],
                      [0.17, -0.234],
                      [0.234, 0.169],
                      [-0.095, 0.131],
                      [-8.678, 16.666],
                      [-0.003, 0.287],
                    ],
                    v: [
                      [-19.246, 47.702],
                      [-19.253, 47.702],
                      [-19.768, 47.174],
                      [-13.526, 14.573],
                      [-0.31, -15.946],
                      [18.757, -47.417],
                      [19.486, -47.533],
                      [19.603, -46.803],
                      [0.609, -15.451],
                      [-18.724, 47.186],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [22.724, 58.498], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-9.229, 19.619],
                      [16.129, -9.872],
                    ],
                    o: [
                      [0, 0],
                      [9.23, -19.617],
                      [-17.256, 10.561],
                    ],
                    v: [
                      [-16.659, 30.018],
                      [21.71, 6.414],
                      [-13.684, -20.146],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [31.19, 30.267], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
        parent: 1,
      },
      {
        ty: 4,
        nm: "Leaf2",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [21.993, 119.74, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [52.91, 7.803, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [3],
                t: 126,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 149,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-3],
                t: 172,
              },
              { s: [0], t: 240.0000097754 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.239, 0],
                      [0.037, 0.008],
                      [-0.062, 0.281],
                      [-4.115, 5.577],
                      [-0.157, 0.123],
                      [-0.178, -0.228],
                      [0.227, -0.178],
                      [4.386, -5.953],
                      [2.796, -12.737],
                    ],
                    o: [
                      [-0.037, 0],
                      [-0.283, -0.062],
                      [2.84, -12.936],
                      [4.468, -6.055],
                      [0.227, -0.177],
                      [0.178, 0.227],
                      [-0.038, 0.029],
                      [-4.046, 5.49],
                      [-0.054, 0.244],
                    ],
                    v: [
                      [-10.327, 18.908],
                      [-10.44, 18.896],
                      [-10.839, 18.274],
                      [1.619, -9.603],
                      [9.99, -18.731],
                      [10.723, -18.64],
                      [10.633, -17.907],
                      [2.434, -8.948],
                      [-9.818, 18.498],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [35.179, 41.219], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.276, 0],
                      [0, 0],
                      [-0.015, 0.288],
                      [-0.118, 0.223],
                      [-0.254, -0.134],
                      [0.136, -0.255],
                      [1.099, -21.548],
                    ],
                    o: [
                      [0, 0],
                      [-0.288, -0.015],
                      [1.11, -21.784],
                      [0.136, -0.255],
                      [0.255, 0.135],
                      [-0.116, 0.221],
                      [-0.014, 0.279],
                    ],
                    v: [
                      [-6.462, 22.628],
                      [-6.488, 22.628],
                      [-6.984, 22.081],
                      [5.939, -22.277],
                      [6.645, -22.494],
                      [6.862, -21.788],
                      [-5.941, 22.133],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [31.182, 33.046], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.055, 0],
                      [0.073, 0.217],
                      [4.429, 5.771],
                      [0.038, 0.031],
                      [-0.184, 0.223],
                      [-0.224, -0.182],
                      [-4.87, -6.34],
                      [-4.564, -13.551],
                      [0.273, -0.092],
                    ],
                    o: [
                      [-0.219, 0],
                      [-4.512, -13.397],
                      [-4.801, -6.256],
                      [-0.223, -0.183],
                      [0.182, -0.223],
                      [0.156, 0.128],
                      [4.485, 5.839],
                      [0.092, 0.272],
                      [-0.055, 0.018],
                    ],
                    v: [
                      [11.977, 19.809],
                      [11.482, 19.454],
                      [-3.686, -9.406],
                      [-12.307, -18.82],
                      [-12.379, -19.554],
                      [-11.645, -19.628],
                      [-2.882, -10.074],
                      [12.471, 19.122],
                      [12.143, 19.783],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.832, 42.498], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.02, 0],
                      [0.031, 0.266],
                      [0.127, 0.232],
                      [-0.253, 0.139],
                      [-0.138, -0.252],
                      [-3.026, -26.57],
                      [0.287, -0.032],
                    ],
                    o: [
                      [-0.262, 0],
                      [-3.002, -26.36],
                      [-0.139, -0.253],
                      [0.253, -0.139],
                      [0.128, 0.234],
                      [0.033, 0.286],
                      [-0.02, 0.003],
                    ],
                    v: [
                      [7.942, 25.601],
                      [7.424, 25.138],
                      [-8.356, -24.753],
                      [-8.15, -25.462],
                      [-7.441, -25.256],
                      [8.462, 25.02],
                      [8.002, 25.597],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [16.956, 34.941], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.236, 0],
                      [0.041, 0.009],
                      [-0.066, 0.281],
                      [1.093, 21.186],
                      [0.023, 0.188],
                      [-0.286, 0.034],
                      [-0.034, -0.286],
                      [-1.186, -23.008],
                      [4.384, -18.511],
                    ],
                    o: [
                      [-0.04, 0],
                      [-0.28, -0.067],
                      [4.356, -18.39],
                      [-1.184, -22.973],
                      [-0.035, -0.286],
                      [0.283, -0.033],
                      [0.023, 0.188],
                      [1.096, 21.253],
                      [-0.058, 0.24],
                    ],
                    v: [
                      [-1.885, 55.979],
                      [-2.007, 55.965],
                      [-2.395, 55.337],
                      [0.48, -13.354],
                      [-2.972, -55.366],
                      [-2.515, -55.947],
                      [-1.936, -55.491],
                      [1.524, -13.408],
                      [-1.377, 55.577],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [23.425, 62.251], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.026, 21.681],
                      [-10.411, -15.786],
                    ],
                    o: [
                      [0, 0],
                      [-0.025, -21.681],
                      [11.139, 16.889],
                    ],
                    v: [
                      [-2.502, 31.232],
                      [-27.218, -6.429],
                      [16.105, -15.446],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.493, 31.482], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 3,
        parent: 1,
      },
      {
        ty: 4,
        nm: "Leaf1",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [63.586, 115.222, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [55.07, 37.392, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [7],
                t: 126,
              },
              { s: [0], t: 240.0000097754 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.028, 0],
                      [0.042, 0.255],
                      [-1.767, 6.642],
                      [-0.102, 0.169],
                      [-0.247, -0.147],
                      [0.148, -0.248],
                      [1.879, -7.085],
                      [-2.12, -12.867],
                      [0.285, -0.047],
                    ],
                    o: [
                      [-0.251, 0],
                      [-2.152, -13.07],
                      [1.919, -7.213],
                      [0.147, -0.248],
                      [0.248, 0.148],
                      [-0.024, 0.04],
                      [-1.733, 6.535],
                      [0.046, 0.285],
                      [-0.028, 0.004],
                    ],
                    v: [
                      [-1.774, 21.291],
                      [-2.289, 20.854],
                      [-1.02, -9.519],
                      [3.398, -20.963],
                      [4.113, -21.144],
                      [4.294, -20.428],
                      [-0.022, -9.208],
                      [-1.258, 20.684],
                      [-1.689, 21.284],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.698, 37.705], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.056, 0],
                      [0.074, 0.217],
                      [-0.027, 0.251],
                      [-0.288, -0.03],
                      [0.031, -0.287],
                      [-6.959, -20.424],
                      [0.273, -0.093],
                    ],
                    o: [
                      [-0.218, 0],
                      [-7.035, -20.646],
                      [0.031, -0.287],
                      [0.287, 0.031],
                      [-0.026, 0.249],
                      [0.093, 0.272],
                      [-0.056, 0.019],
                    ],
                    v: [
                      [3.471, 23.419],
                      [2.977, 23.065],
                      [-1.443, -22.924],
                      [-0.869, -23.389],
                      [-0.405, -22.814],
                      [3.965, 22.729],
                      [3.639, 23.391],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [38.683, 31.49], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.12, 0],
                      [0.102, 0.122],
                      [6.252, 3.721],
                      [0.046, 0.015],
                      [-0.087, 0.275],
                      [-0.275, -0.087],
                      [-6.872, -4.086],
                      [-9.256, -10.898],
                      [0.22, -0.187],
                    ],
                    o: [
                      [-0.147, 0],
                      [-9.153, -10.773],
                      [-6.775, -4.034],
                      [-0.275, -0.087],
                      [0.087, -0.274],
                      [0.193, 0.062],
                      [6.328, 3.763],
                      [0.188, 0.219],
                      [-0.098, 0.084],
                    ],
                    v: [
                      [18.255, 14.005],
                      [17.858, 13.821],
                      [-6.92, -7.37],
                      [-18.413, -12.923],
                      [-18.753, -13.578],
                      [-18.098, -13.918],
                      [-6.42, -8.288],
                      [18.652, 13.146],
                      [18.593, 13.881],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [26.438, 47.031], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.084, 0],
                      [0.094, 0.174],
                      [0.204, 0.168],
                      [-0.184, 0.223],
                      [-0.222, -0.184],
                      [-12.649, -23.56],
                      [0.254, -0.137],
                    ],
                    o: [
                      [-0.186, 0],
                      [-12.55, -23.375],
                      [-0.222, -0.184],
                      [0.183, -0.221],
                      [0.206, 0.17],
                      [0.137, 0.254],
                      [-0.078, 0.041],
                    ],
                    v: [
                      [16.625, 20.883],
                      [16.165, 20.608],
                      [-16.968, -19.893],
                      [-17.038, -20.629],
                      [-16.304, -20.699],
                      [17.085, 20.113],
                      [16.872, 20.821],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.498, 38.48], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.025, 0],
                      [0.038, 0.259],
                      [8.859, 19.274],
                      [0.09, 0.166],
                      [-0.253, 0.138],
                      [-0.138, -0.253],
                      [-9.622, -20.934],
                      [-2.782, -18.819],
                      [0.286, -0.042],
                    ],
                    o: [
                      [-0.255, 0],
                      [-2.764, -18.697],
                      [-9.608, -20.902],
                      [-0.139, -0.253],
                      [0.254, -0.138],
                      [0.09, 0.166],
                      [8.888, 19.336],
                      [0.042, 0.286],
                      [-0.025, 0.004],
                    ],
                    v: [
                      [20.813, 51.956],
                      [20.296, 51.511],
                      [-2.467, -13.362],
                      [-21.233, -51.11],
                      [-21.025, -51.818],
                      [-20.316, -51.609],
                      [-1.52, -13.798],
                      [21.329, 51.358],
                      [20.889, 51.951],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [41.551, 62.238], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [8.051, 20.13],
                      [-15.517, -10.809],
                    ],
                    o: [
                      [0, 0],
                      [-8.052, -20.13],
                      [16.602, 11.564],
                    ],
                    v: [
                      [14.177, 30.53],
                      [-22.727, 4.697],
                      [14.177, -19.721],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [31.029, 30.78], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 4,
        parent: 1,
      },
      {
        ty: 4,
        nm: "Laptop",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [65.807, 42.125, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [222.756, 248.092, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.818, 0],
                      [0, 0],
                      [0.337, -1.172],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0.503, -1.748],
                      [0, 0],
                      [-1.219, 0],
                      [0, 0],
                    ],
                    v: [
                      [-37.482, 3.135],
                      [36.179, 3.135],
                      [36.979, 0.356],
                      [34.351, -3.135],
                      [-33.619, -3.135],
                      [-36.247, -1.156],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [37.731, 80.857], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.781, 1.098],
                      [0, 0],
                      [-1.128, 0],
                      [0, 0],
                      [-0.601, -1.149],
                      [0, 0],
                      [1.246, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.653, -0.919],
                      [0, 0],
                      [1.297, 0],
                      [0, 0],
                      [0.577, 1.105],
                      [0, 0],
                      [-1.348, 0],
                    ],
                    v: [
                      [-16.656, 0.709],
                      [-14.824, -1.865],
                      [-11.985, -3.329],
                      [12.536, -3.329],
                      [15.625, -1.46],
                      [16.86, 0.901],
                      [15.39, 3.329],
                      [-15.304, 3.329],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [67.995, 80.67], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-3.117, -7.614],
                      [-5.826, 7.614],
                      [3.117, 7.614],
                      [5.826, -7.614],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [70.551, 76.386], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.538, 2.271],
                      [0, 0],
                      [-1.679, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-2.336, 0],
                      [0, 0],
                      [0.387, -1.633],
                      [0, 0],
                    ],
                    v: [
                      [11.044, -34.261],
                      [-4.385, 34.261],
                      [-6.982, 34.261],
                      [-10.506, 29.81],
                      [3.983, -31.475],
                      [7.507, -34.261],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [30.534, 34.511], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.425, -3.857],
                      [3.858, 0],
                      [-0.426, 3.857],
                      [-3.858, 0],
                    ],
                    o: [
                      [-0.425, 3.857],
                      [-3.857, 0],
                      [0.425, -3.857],
                      [3.857, 0],
                    ],
                    v: [
                      [6.985, 0],
                      [-0.77, 6.984],
                      [-6.983, 0],
                      [0.771, -6.984],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [80.469, 36.908], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.085, -4.033],
                      [0, 0],
                      [-0.889, 3.72],
                      [3.411, -3.041],
                    ],
                    o: [
                      [0.078, 3.72],
                      [0, 0],
                      [0.964, -4.033],
                      [-2.746, -3.041],
                    ],
                    v: [
                      [-7.734, -1.554],
                      [-1.355, 6.824],
                      [6.856, -1.554],
                      [-0.196, -3.783],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [108.522, 49.248], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [3.093, 0],
                      [0.001, 0.031],
                      [-0.175, 0.037],
                      [-0.005, -0.169],
                      [-2.581, 0],
                      [-0.044, 0.107],
                      [-0.161, -0.036],
                      [0.068, -0.169],
                    ],
                    o: [
                      [-3.093, 0],
                      [-0.005, -0.169],
                      [0.176, -0.037],
                      [0.004, 0.112],
                      [2.583, 0],
                      [0.069, -0.169],
                      [0.161, 0.037],
                      [-0.013, 0.031],
                    ],
                    v: [
                      [-0.614, 1.766],
                      [-3.839, -1.357],
                      [-3.531, -1.729],
                      [-3.203, -1.49],
                      [-0.497, 1.141],
                      [3.19, -1.49],
                      [3.607, -1.729],
                      [3.776, -1.357],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [52.356, 15.854], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.148, -0.794],
                      [0.346, 0],
                      [-0.148, 0.794],
                      [-0.346, 0],
                    ],
                    o: [
                      [-0.148, 0.794],
                      [-0.345, 0],
                      [0.148, -0.794],
                      [0.345, 0],
                    ],
                    v: [
                      [0.626, 0],
                      [-0.269, 1.438],
                      [-0.626, 0],
                      [0.268, -1.438],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [54.528, 12.742], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.148, -0.794],
                      [0.345, 0],
                      [-0.148, 0.794],
                      [-0.346, 0],
                    ],
                    o: [
                      [-0.148, 0.794],
                      [-0.345, 0],
                      [0.148, -0.794],
                      [0.345, 0],
                    ],
                    v: [
                      [0.626, 0],
                      [-0.269, 1.438],
                      [-0.626, 0],
                      [0.268, -1.438],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [50.776, 12.742], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 10",
            ix: 10,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.741, -3.972],
                      [3.971, 0],
                      [-0.741, 3.972],
                      [-3.971, 0],
                    ],
                    o: [
                      [-0.741, 3.972],
                      [-3.972, 0],
                      [0.741, -3.972],
                      [3.973, 0],
                    ],
                    v: [
                      [7.192, 0],
                      [-1.342, 7.191],
                      [-7.192, 0],
                      [1.342, -7.191],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9804, 0.6863, 0.2275], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [52.333, 14.117], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 11",
            ix: 11,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [46.516, -0.737],
                      [46.166, 0.737],
                      [-46.516, 0.737],
                      [-46.516, -0.737],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [71.06, 61.474], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 12",
            ix: 12,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.538, -2.271],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.68, 0],
                      [0, 0],
                      [-0.538, 2.271],
                      [0, 0],
                      [-1.679, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.385, 1.635],
                      [0, 0],
                      [-2.336, 0],
                      [0, 0],
                      [0.385, -1.633],
                      [0, 0],
                      [2.336, 0],
                    ],
                    v: [
                      [55.399, -29.811],
                      [42.148, 26.226],
                      [41.799, 27.698],
                      [40.908, 31.473],
                      [37.386, 34.261],
                      [-51.875, 34.261],
                      [-55.399, 29.808],
                      [-40.908, -31.476],
                      [-37.387, -34.261],
                      [51.875, -34.261],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [75.427, 34.512], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 5,
      },
      {
        ty: 4,
        nm: "Heart Bubble",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [29.835, 31.32, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [95, 95, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 60,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [95, 95, 100],
                t: 120,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 180,
              },
              { s: [95, 95, 100], t: 240.0000097754 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [248.501, 194.378, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.721, -4.558],
                      [0, 0],
                      [0.429, 4.205],
                      [-3.385, -3.438],
                    ],
                    o: [
                      [-0.665, 4.204],
                      [0, 0],
                      [-0.466, -4.558],
                      [3.576, -3.433],
                    ],
                    v: [
                      [8.118, -1.75],
                      [-0.392, 7.714],
                      [-8.373, -1.762],
                      [-0.057, -4.276],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [18.946, 12.491], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [4.083, -5.61],
                      [1.109, 5.61],
                      [-4.083, -4.937],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [29.094, 26.781], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.264, 0.001],
                      [0, 0],
                      [-0.001, 1.264],
                      [0, 0],
                      [-1.263, -0.001],
                      [0, 0],
                      [0.001, -1.263],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-1.263, -0.001],
                      [0, 0],
                      [0.001, -1.263],
                      [0, 0],
                      [1.263, 0.001],
                      [0, 0],
                      [0, 1.264],
                    ],
                    v: [
                      [16.282, 12.307],
                      [-16.298, 12.286],
                      [-18.585, 9.996],
                      [-18.57, -10.022],
                      [-16.281, -12.307],
                      [16.299, -12.285],
                      [18.585, -9.996],
                      [18.571, 10.022],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [18.835, 12.559], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 6,
      },
      {
        ty: 4,
        nm: "Girl.Eyeglasses",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Eyeglasses",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [20.302, 9.386, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [35.161, 33.425, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.105, 0.021],
                      [0.064, 0.099],
                      [0.019, -0.007],
                      [0.074, 0.221],
                      [-0.221, 0.073],
                      [-1.009, -1.557],
                      [0.196, -0.127],
                    ],
                    o: [
                      [-0.106, -0.022],
                      [-0.908, -1.402],
                      [-0.221, 0.073],
                      [-0.074, -0.222],
                      [0.891, -0.297],
                      [0.126, 0.196],
                      [-0.097, 0.064],
                    ],
                    v: [
                      [1.591, 1.09],
                      [1.322, 0.906],
                      [-1.549, 0.139],
                      [-2.084, -0.128],
                      [-1.817, -0.663],
                      [2.032, 0.446],
                      [1.907, 1.03],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [24.798, 7.821], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.167, 0.035],
                      [0.045, 0.029],
                      [-0.125, 0.197],
                      [-0.068, -0.041],
                      [0.12, -0.201],
                      [0.201, 0.12],
                      [2.399, -3.75],
                    ],
                    o: [
                      [-0.049, -0.01],
                      [-0.197, -0.126],
                      [2.84, -4.438],
                      [0.201, 0.119],
                      [-0.118, 0.2],
                      [-0.061, -0.036],
                      [-0.097, 0.152],
                    ],
                    v: [
                      [-4.667, 2.522],
                      [-4.809, 2.465],
                      [-4.938, 1.88],
                      [4.796, 1.467],
                      [4.943, 2.048],
                      [4.364, 2.195],
                      [-4.225, 2.337],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [5.313, 3.718], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.561, -2.712],
                      [0.039, -0.13],
                      [0.072, -0.182],
                      [1.155, -0.715],
                      [0.477, -0.16],
                      [0.313, -0.051],
                      [0.092, -0.009],
                      [0.69, 0.141],
                      [0.063, 0.014],
                      [0.038, 0.007],
                      [0.731, 0.667],
                      [0.1, 0.106],
                      [-0.437, 2.112],
                      [-0.192, 0.423],
                      [-1.327, 0.622],
                      [-0.941, 0.002],
                      [-0.156, -0.009],
                      [-0.272, -0.057],
                      [-0.187, -0.058],
                      [-0.245, -0.114],
                      [-0.182, -0.107],
                    ],
                    o: [
                      [-0.028, 0.136],
                      [-0.05, 0.19],
                      [-0.498, 1.325],
                      [-0.424, 0.264],
                      [-0.299, 0.097],
                      [-0.092, 0.015],
                      [-0.664, 0.083],
                      [-0.063, -0.015],
                      [-0.038, -0.01],
                      [-1.012, -0.264],
                      [-0.109, -0.095],
                      [-1.392, -1.445],
                      [0.099, -0.474],
                      [0.632, -1.377],
                      [0.821, -0.386],
                      [0.151, 0.002],
                      [0.268, 0.021],
                      [0.197, 0.042],
                      [0.263, 0.081],
                      [0.194, 0.085],
                      [2.267, 1.327],
                    ],
                    v: [
                      [6.123, 1.263],
                      [6.025, 1.664],
                      [5.843, 2.22],
                      [3.266, 5.342],
                      [1.909, 5.977],
                      [0.991, 6.202],
                      [0.719, 6.238],
                      [-1.321, 6.158],
                      [-1.509, 6.116],
                      [-1.623, 6.089],
                      [-4.262, 4.66],
                      [-4.576, 4.358],
                      [-6.212, -1.284],
                      [-5.771, -2.634],
                      [-2.712, -5.711],
                      [-0.041, -6.31],
                      [0.418, -6.293],
                      [1.229, -6.177],
                      [1.806, -6.03],
                      [2.566, -5.74],
                      [3.133, -5.448],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 2",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.745, 1.446],
                      [0.205, 0.087],
                      [0.255, 0.072],
                      [0.183, 0.039],
                      [0.329, 0.021],
                      [0.393, -0.043],
                      [0.908, -0.566],
                      [0.59, -1.048],
                      [0.118, -0.289],
                      [0.003, -0.016],
                      [0.084, -0.412],
                      [-2.088, -1.618],
                      [-0.863, -0.257],
                      [-0.134, -0.028],
                      [-0.068, -0.014],
                      [-0.669, 0.06],
                      [-0.105, 0.014],
                      [-0.285, 0.075],
                      [-0.482, 0.254],
                      [-0.449, 2.175],
                    ],
                    o: [
                      [-0.193, -0.102],
                      [-0.241, -0.099],
                      [-0.176, -0.053],
                      [-0.335, -0.07],
                      [-0.403, -0.026],
                      [-1.089, 0.115],
                      [-1, 0.623],
                      [-0.155, 0.266],
                      [-0.006, 0.016],
                      [-0.152, 0.378],
                      [-0.571, 2.758],
                      [0.678, 0.526],
                      [0.13, 0.039],
                      [0.065, 0.02],
                      [0.69, 0.141],
                      [0.108, -0.007],
                      [0.294, -0.04],
                      [0.534, -0.138],
                      [1.827, -0.965],
                      [0.659, -3.186],
                    ],
                    v: [
                      [3.278, -6.336],
                      [2.683, -6.616],
                      [1.938, -6.871],
                      [1.402, -7.007],
                      [0.401, -7.141],
                      [-0.794, -7.115],
                      [-3.826, -6.068],
                      [-6.261, -3.526],
                      [-6.668, -2.691],
                      [-6.683, -2.646],
                      [-7.04, -1.456],
                      [-4.412, 5.642],
                      [-2.089, 6.835],
                      [-1.692, 6.938],
                      [-1.491, 6.986],
                      [0.556, 7.107],
                      [0.876, 7.074],
                      [1.746, 6.905],
                      [3.274, 6.314],
                      [6.952, 1.436],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [32.743, 11.357], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [3.401, 0.705],
                      [0.036, 0.01],
                      [0.851, 1.488],
                      [-0.321, 1.562],
                      [-0.112, 0.3],
                      [-0.006, 0.019],
                      [-2.436, 0.145],
                      [-0.556, -0.116],
                      [-0.825, -0.791],
                      [0.455, -2.205],
                    ],
                    o: [
                      [-0.036, -0.007],
                      [-1.792, -0.401],
                      [-0.734, -1.285],
                      [0.067, -0.322],
                      [0.009, -0.019],
                      [0.889, -2.312],
                      [0.541, -0.033],
                      [1.196, 0.247],
                      [1.518, 1.451],
                      [-0.702, 3.399],
                    ],
                    v: [
                      [-1.157, 6.271],
                      [-1.261, 6.247],
                      [-5.351, 3.223],
                      [-6.051, -1.169],
                      [-5.78, -2.104],
                      [-5.758, -2.16],
                      [-0.26, -6.182],
                      [1.391, -6.064],
                      [4.459, -4.453],
                      [6.284, 1.38],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 2",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.001, 0.468],
                      [0.037, 0.285],
                      [1.045, 1.101],
                      [1.455, 0.313],
                      [0.025, 0.003],
                      [1.354, -2.959],
                      [0.084, -0.274],
                      [0.053, -0.251],
                      [-0.925, -1.497],
                      [-0.432, -0.413],
                      [-1.368, -0.283],
                      [-1.632, 1.458],
                      [-0.321, 1.554],
                      [0, 0.006],
                    ],
                    o: [
                      [0.003, -0.291],
                      [-0.186, -1.535],
                      [-0.955, -1.011],
                      [-0.024, -0.007],
                      [-3.319, -0.685],
                      [-0.118, 0.256],
                      [-0.08, 0.239],
                      [-0.384, 1.859],
                      [0.315, 0.51],
                      [0.938, 0.91],
                      [2.306, 0.476],
                      [1.101, -0.984],
                      [0, -0.006],
                      [0.096, -0.474],
                    ],
                    v: [
                      [7.26, 0.116],
                      [7.209, -0.747],
                      [5.298, -4.807],
                      [1.634, -6.875],
                      [1.563, -6.892],
                      [-6.378, -2.871],
                      [-6.683, -2.079],
                      [-6.879, -1.342],
                      [-5.96, 3.854],
                      [-4.833, 5.249],
                      [-1.33, 7.1],
                      [4.885, 5.422],
                      [7.113, 1.551],
                      [7.116, 1.531],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [16.058, 7.827], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.242, 0.034],
                      [0, 0],
                      [0.055, -0.237],
                      [0.017, -0.036],
                      [0, 0],
                      [-0.039, 0.23],
                    ],
                    o: [
                      [0, 0],
                      [-0.03, 0.24],
                      [-0.009, 0.041],
                      [0, 0],
                      [0.23, 0.04],
                      [0.04, -0.23],
                    ],
                    v: [
                      [2.734, 0.047],
                      [-2.938, -0.92],
                      [-3.079, -0.208],
                      [-3.12, -0.093],
                      [2.592, 0.88],
                      [3.08, 0.535],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [35.723, 12.74], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 7,
        parent: 12,
      },
      {
        ty: 4,
        nm: "Girl.Eyes",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Eyes",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [7.313, 3.604, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [38.591, 32.997, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.232, -1.146],
                      [0.642, 0.13],
                      [-0.233, 1.147],
                      [-0.641, -0.129],
                    ],
                    o: [
                      [-0.232, 1.146],
                      [-0.641, -0.13],
                      [0.232, -1.146],
                      [0.642, 0.13],
                    ],
                    v: [
                      [1.162, 0.235],
                      [-0.421, 2.075],
                      [-1.161, -0.236],
                      [0.421, -2.076],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.983, 4.753], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.232, -1.146],
                      [0.642, 0.13],
                      [-0.233, 1.147],
                      [-0.641, -0.129],
                    ],
                    o: [
                      [-0.232, 1.146],
                      [-0.641, -0.13],
                      [0.232, -1.146],
                      [0.642, 0.13],
                    ],
                    v: [
                      [1.162, 0.235],
                      [-0.421, 2.075],
                      [-1.161, -0.236],
                      [0.421, -2.076],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [1.643, 2.455], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 8,
        parent: 12,
      },
      {
        ty: 4,
        nm: "Girl.R.Arm",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "R Arm",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [9.098, 9.264, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [31.886, 74.601, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-6],
                t: 30,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-6],
                t: 60,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 90,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 156,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-6],
                t: 192,
              },
              { s: [0], t: 231.000009408823 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-2.852, -1.23],
                      [-4.394, -0.638],
                      [-4.11, 0.089],
                      [-3.873, 0.446],
                      [-3.713, 0.592],
                      [0.432, 5.062],
                      [5.31, -0.454],
                      [0, 0],
                      [3.47, -0.145],
                      [3.256, 0.169],
                      [2.854, 0.641],
                      [2.231, 1.185],
                    ],
                    o: [
                      [0, 0],
                      [0.923, 2.726],
                      [4.455, 1.922],
                      [4.403, 0.638],
                      [4.112, -0.093],
                      [3.873, -0.449],
                      [4.933, -0.8],
                      [-0.453, -5.31],
                      [0, 0],
                      [-3.599, 0.306],
                      [-3.469, 0.145],
                      [-3.254, -0.168],
                      [-2.856, -0.641],
                      [0, 0],
                    ],
                    v: [
                      [-32.021, 1.02],
                      [-31.807, 1.662],
                      [-26.003, 7.958],
                      [-12.594, 11.664],
                      [0.178, 12.367],
                      [12.133, 11.49],
                      [23.495, 9.89],
                      [31.59, -0.46],
                      [21.154, -9.255],
                      [21.135, -9.253],
                      [10.52, -8.542],
                      [0.4, -8.538],
                      [-8.822, -9.719],
                      [-16.488, -12.456],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [32.924, 13.823], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.15],
                      [6.151, 0],
                      [0, 6.151],
                      [-6.151, 0],
                    ],
                    o: [
                      [0, 6.151],
                      [-6.151, 0],
                      [0, -6.15],
                      [6.151, 0],
                    ],
                    v: [
                      [11.137, 0],
                      [0, 11.137],
                      [-11.137, 0],
                      [0, -11.137],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.387, 11.387], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 9,
        parent: 10,
      },
      {
        ty: 4,
        nm: "Girl.R.Shoulder",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "R Shoulder",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [15.24, 15.418, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [73.741, 28.104, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [5],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-6],
                t: 30,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-14],
                t: 60,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [5],
                t: 90,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [5],
                t: 156,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-6],
                t: 192,
              },
              { s: [5], t: 231.000009408823 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -7.229],
                      [7.229, 0],
                      [0, 7.229],
                      [-7.229, 0],
                    ],
                    o: [
                      [0, 7.229],
                      [-7.229, 0],
                      [0, -7.229],
                      [7.229, 0],
                    ],
                    v: [
                      [13.09, 0],
                      [0.001, 13.089],
                      [-13.09, 0],
                      [0.001, -13.089],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [14.943, 14.943], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.128],
                      [6.128, 0],
                      [0, 6.128],
                      [-6.128, 0],
                    ],
                    o: [
                      [0, 6.128],
                      [-6.128, 0],
                      [0, -6.128],
                      [6.128, 0],
                    ],
                    v: [
                      [11.095, 0.001],
                      [0, 11.096],
                      [-11.095, 0.001],
                      [0, -11.096],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [34.134, 77.491], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.923, 2.728],
                      [0, 0],
                      [0, 0],
                      [-6.824, 2.277],
                      [-2.277, -6.825],
                      [-0.06, -0.216],
                      [0, 0],
                    ],
                    o: [
                      [-2.852, -1.23],
                      [0, 0],
                      [0, 0],
                      [-2.277, -6.825],
                      [6.825, -2.277],
                      [0.071, 0.214],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [6.51, 43.206],
                      [0.704, 36.908],
                      [0.491, 36.268],
                      [-19.766, -24.449],
                      [-11.531, -40.929],
                      [4.95, -32.694],
                      [5.158, -32.008],
                      [22.042, 29.73],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [22.292, 43.456], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 10,
        parent: 13,
      },
      {
        ty: 4,
        nm: "Girl.Eyebrows",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Eyebrows",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [10.998, 4.009, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [39.683, 27.297, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.506, -0.046],
                      [0.961, -1.511],
                      [3.179, -2.501],
                      [-5.417, 0.03],
                    ],
                    o: [
                      [0, 0],
                      [-0.96, 1.512],
                      [0, 0],
                      [0.432, -0.002],
                    ],
                    v: [
                      [2.535, -1.749],
                      [3.327, 0.303],
                      [-4.035, 1.782],
                      [1.129, -1.813],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [4.538, 2.065], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [6.567, 2.097],
                      [-0.297, -1.767],
                      [-1.956, -3.541],
                    ],
                    o: [
                      [0, 0],
                      [0.296, 1.767],
                      [0, 0],
                    ],
                    v: [
                      [-2.371, -2.812],
                      [-3.899, -1.23],
                      [2.307, 2.997],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [17.55, 4.77], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 11,
        parent: 12,
      },
      {
        ty: 4,
        nm: "Girl.Head",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Head",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [15.629, 46.691, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [97.721, 1.837, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [10],
                t: 40,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [6],
                t: 57,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [10],
                t: 74,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [10],
                t: 186,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [6],
                t: 206,
              },
              { s: [0], t: 226.000009205169 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.245, -2.456],
                      [-4.455, 9.804],
                    ],
                    o: [
                      [0, 0],
                      [-0.335, 3.348],
                      [0, 0],
                    ],
                    v: [
                      [4.747, -7.356],
                      [0.014, 7.291],
                      [-0.292, -10.639],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [17.541, 28.205], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.137, -0.028],
                      [-0.102, 0.053],
                      [-0.771, -0.373],
                      [-0.116, 0.239],
                      [0.239, 0.116],
                      [0.219, -0.116],
                      [-0.124, -0.235],
                    ],
                    o: [
                      [0.104, 0.021],
                      [0.478, -0.249],
                      [0.239, 0.115],
                      [0.116, -0.239],
                      [-1.345, -0.649],
                      [-0.236, 0.123],
                      [0.07, 0.133],
                    ],
                    v: [
                      [-1.639, 0.99],
                      [-1.322, 0.945],
                      [1.337, 0.504],
                      [1.979, 0.28],
                      [1.755, -0.363],
                      [-1.768, 0.094],
                      [-1.971, 0.742],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [13.627, 35.637], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.237, -0.048],
                      [-0.017, -0.001],
                      [-0.024, 0.265],
                      [0.172, 0.113],
                      [0.145, -0.223],
                      [-0.222, -0.145],
                      [0.434, -4.762],
                    ],
                    o: [
                      [0.017, 0.003],
                      [0.265, 0.025],
                      [0.488, -5.351],
                      [-0.223, -0.145],
                      [-0.145, 0.222],
                      [0.152, 0.101],
                      [-0.022, 0.246],
                    ],
                    v: [
                      [1.448, 4.355],
                      [1.499, 4.363],
                      [2.023, 3.928],
                      [-1.699, -4.243],
                      [-2.365, -4.102],
                      [-2.227, -3.438],
                      [1.064, 3.84],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [14.361, 36.023], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.433, -2.138],
                      [-3.175, -0.643],
                      [-0.643, 3.175],
                      [1.883, 1.295],
                      [0.798, 0.161],
                      [0.019, 0.004],
                      [-0.227, -0.25],
                      [-0.503, -0.383],
                      [0.39, -1.927],
                      [2.683, 0.544],
                      [-0.543, 2.681],
                      [-1.819, 0.437],
                    ],
                    o: [
                      [-1.937, 0.641],
                      [-0.644, 3.175],
                      [3.176, 0.644],
                      [0.482, -2.378],
                      [-0.631, -0.435],
                      [-0.019, -0.004],
                      [-0.112, 0.4],
                      [0.642, 0.169],
                      [1.469, 1.117],
                      [-0.544, 2.682],
                      [-2.682, -0.544],
                      [0.395, -1.95],
                      [0, 0],
                    ],
                    v: [
                      [-1.59, -5.978],
                      [-5.678, -1.484],
                      [-1.087, 5.441],
                      [5.84, 0.85],
                      [3.404, -5.163],
                      [1.248, -6.076],
                      [1.191, -6.085],
                      [1.353, -5.114],
                      [3.08, -4.271],
                      [4.946, 0.669],
                      [-0.905, 4.548],
                      [-4.784, -1.303],
                      [-1.129, -5.074],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9804, 0.6863, 0.2275], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.364, 44.947], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [4.338, -4.157],
                      [-6.198, 2.449],
                    ],
                    o: [
                      [0, 0],
                      [-3.575, 3.425],
                      [0, 0],
                    ],
                    v: [
                      [5.624, -2.431],
                      [-2.049, -5.358],
                      [3.7, 7.066],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.36, 35.293], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-2.333, 1.477],
                      [2.375, -0.587],
                      [0.002, 0],
                      [0, 0],
                      [-0.015, 0.811],
                    ],
                    o: [
                      [-0.081, 0.117],
                      [-0.002, 0],
                      [-0.006, -0.009],
                      [-0.015, -0.834],
                      [0.084, 0.003],
                    ],
                    v: [
                      [2.35, -1.948],
                      [-2.321, 1.947],
                      [-2.326, 1.948],
                      [-2.335, 1.934],
                      [-2.333, -0.535],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [16.121, 43.195], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.589, -0.12],
                      [-0.033, 0.001],
                      [-0.086, 0.104],
                      [0.019, 0.134],
                      [0, 0],
                      [0.264, -0.035],
                      [-0.036, -0.263],
                      [0, 0],
                      [0.645, 0.536],
                      [0.17, -0.204],
                      [-0.205, -0.169],
                    ],
                    o: [
                      [0.549, 0.111],
                      [0.135, -0.007],
                      [0.086, -0.104],
                      [0, 0],
                      [-0.035, -0.263],
                      [-0.263, 0.036],
                      [0, 0],
                      [-0.478, -0.073],
                      [-0.205, -0.17],
                      [-0.17, 0.204],
                      [0.71, 0.591],
                    ],
                    v: [
                      [0.431, 3.07],
                      [1.402, 3.163],
                      [1.749, 2.989],
                      [1.855, 2.618],
                      [1.122, -2.735],
                      [0.581, -3.146],
                      [0.17, -2.605],
                      [0.822, 2.161],
                      [-1.026, 1.355],
                      [-1.704, 1.416],
                      [-1.641, 2.093],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [39.138, 34.388], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.97, -0.326],
                      [0.209, -0.941],
                      [-0.159, 0.786],
                    ],
                    o: [
                      [-0.217, 1.062],
                      [-1.928, -0.465],
                      [0.161, -0.789],
                    ],
                    v: [
                      [1.905, -1.439],
                      [1.267, 1.564],
                      [-1.892, -0.642],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.364, 39.12], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.169, -0.834],
                      [2.12, 0.43],
                      [-0.169, 0.835],
                      [-2.12, -0.429],
                    ],
                    o: [
                      [-0.169, 0.834],
                      [-2.12, -0.429],
                      [0.169, -0.834],
                      [2.12, 0.43],
                    ],
                    v: [
                      [3.839, 0.777],
                      [-0.306, 1.51],
                      [-3.839, -0.779],
                      [0.306, -1.511],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [28.753, 35.497], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 10",
            ix: 10,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-3.642, 0],
                      [5.202, 1.821],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-4.048, -1.417],
                    ],
                    v: [
                      [-5.128, -3.642],
                      [5.536, -1.56],
                      [-1.488, 1.82],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [35.958, 43.504], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 11",
            ix: 11,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.431, 0],
                      [0.066, 0.068],
                      [-0.206, 0.2],
                      [-0.2, -0.205],
                      [-1.98, 0.676],
                      [-0.093, -0.272],
                      [0.272, -0.093],
                    ],
                    o: [
                      [-1.893, 0],
                      [-0.201, -0.207],
                      [0.206, -0.2],
                      [0.069, 0.07],
                      [0.272, -0.093],
                      [0.092, 0.272],
                      [-0.485, 0.166],
                    ],
                    v: [
                      [0.83, 1.155],
                      [-2.424, -0.22],
                      [-2.414, -0.956],
                      [-1.679, -0.945],
                      [1.872, -0.062],
                      [2.533, 0.262],
                      [2.208, 0.922],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [34.36, 47.207], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 12",
            ix: 12,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-14.561, -3.917],
                      [-0.257, -3.381],
                      [0, 0],
                      [2.599, 2.084],
                      [0, 0],
                      [8.241, 2.298],
                    ],
                    o: [
                      [0, 0],
                      [14.561, 3.916],
                      [0.256, 3.382],
                      [0, 0],
                      [-3.187, -2.555],
                      [0, 0],
                      [-4.68, -1.306],
                    ],
                    v: [
                      [-18.201, 2.971],
                      [3.658, -7.149],
                      [19.249, 6.912],
                      [11.708, 4.304],
                      [8.842, 8.982],
                      [2.089, 0.912],
                      [-14.824, 7.137],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [35.504, 14.504], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 13",
            ix: 13,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.797, -9.016],
                      [0.204, -0.978],
                      [0.209, -0.942],
                      [2.062, -1.419],
                      [0.546, 0.468],
                      [0, 0],
                      [-0.017, 0.811],
                      [-1.859, 5.717],
                      [-6.235, 0.942],
                      [-3.039, -1.655],
                    ],
                    o: [
                      [-0.217, 1.081],
                      [-0.217, 1.061],
                      [-1.546, 7.071],
                      [-10.428, 7.171],
                      [0, 0],
                      [-0.014, -0.833],
                      [0.126, -6.878],
                      [0.928, -6.238],
                      [6.461, -0.972],
                      [4.589, 2.502],
                    ],
                    v: [
                      [16.164, -0.839],
                      [15.536, 2.248],
                      [14.898, 5.252],
                      [10.015, 17.528],
                      [-17.933, 9.709],
                      [-17.947, 9.696],
                      [-17.944, 7.226],
                      [-14.787, -11.545],
                      [-2.785, -23.727],
                      [11.575, -19.304],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [31.732, 35.433], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 14",
            ix: 14,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.433, -2.138],
                      [-3.175, -0.644],
                      [-0.643, 3.175],
                      [1.883, 1.294],
                      [0.798, 0.162],
                      [0.02, 0.004],
                      [-0.228, -0.249],
                      [-0.503, -0.384],
                      [0.39, -1.926],
                      [2.683, 0.544],
                      [-0.544, 2.682],
                      [-1.818, 0.438],
                    ],
                    o: [
                      [-1.937, 0.642],
                      [-0.643, 3.175],
                      [3.176, 0.643],
                      [0.482, -2.378],
                      [-0.631, -0.435],
                      [-0.02, -0.004],
                      [-0.112, 0.4],
                      [0.641, 0.169],
                      [1.468, 1.116],
                      [-0.544, 2.682],
                      [-2.683, -0.543],
                      [0.395, -1.949],
                      [0, 0],
                    ],
                    v: [
                      [-1.59, -5.978],
                      [-5.679, -1.484],
                      [-1.087, 5.442],
                      [5.84, 0.85],
                      [3.404, -5.162],
                      [1.248, -6.076],
                      [1.19, -6.085],
                      [1.353, -5.114],
                      [3.08, -4.27],
                      [4.946, 0.669],
                      [-0.905, 4.548],
                      [-4.784, -1.303],
                      [-1.13, -5.074],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9804, 0.6863, 0.2275], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [41.898, 50.531], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 15",
            ix: 15,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-1.026, -4.081],
                      [4.542, 2.204],
                    ],
                    o: [
                      [0, 0],
                      [1.026, 4.08],
                      [0, 0],
                    ],
                    v: [
                      [-2.236, -4.625],
                      [2.692, -3.626],
                      [-3.718, 5.503],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [46.353, 41.682], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 16",
            ix: 16,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.105, 0],
                      [0.115, 0.202],
                      [-4.19, 11.94],
                      [-3.865, 1.488],
                      [-0.149, -0.007],
                      [0.017, -0.345],
                      [0.35, 0.017],
                      [3.936, -1.526],
                      [1.967, -5.608],
                      [-0.05, -0.088],
                      [0.299, -0.17],
                    ],
                    o: [
                      [-0.217, 0],
                      [-0.217, -0.381],
                      [2.617, -7.456],
                      [4.176, -1.608],
                      [0.345, 0.017],
                      [-0.017, 0.344],
                      [-0.034, -0.002],
                      [-5.239, 2.032],
                      [-3.994, 11.381],
                      [0.17, 0.3],
                      [-0.098, 0.055],
                    ],
                    v: [
                      [-6.332, 17.799],
                      [-6.875, 17.484],
                      [-7.95, -3.91],
                      [3.687, -16.191],
                      [11.53, -17.639],
                      [12.123, -16.984],
                      [11.469, -16.39],
                      [4.09, -15.007],
                      [-6.77, -3.495],
                      [-5.789, 16.865],
                      [-6.023, 17.717],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9804, 0.6863, 0.2275], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.39, 19.061], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 17",
            ix: 17,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [18.292, 9.262],
                      [3.381, 12.1],
                      [-3.348, 6.224],
                      [-11.216, -2.273],
                      [-1.252, -9.311],
                    ],
                    o: [
                      [0, 0],
                      [-3.219, -11.522],
                      [2.522, -4.688],
                      [11.324, 2.294],
                      [2.341, 17.422],
                    ],
                    v: [
                      [-6.878, 19.92],
                      [-22.251, 5.563],
                      [-19.645, -18.161],
                      [2.089, -26.91],
                      [23.128, -9.493],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.739, 29.433], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 12,
        parent: 13,
      },
      {
        ty: 4,
        nm: "Girl.Body",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Body",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [145.09, 144.874, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [186.664, 327.375, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -7.229],
                      [7.229, 0],
                      [0, 7.229],
                      [-7.229, 0],
                    ],
                    o: [
                      [0, 7.229],
                      [-7.229, 0],
                      [0, -7.229],
                      [7.229, 0],
                    ],
                    v: [
                      [13.09, 0],
                      [0.001, 13.089],
                      [-13.09, 0],
                      [0.001, -13.089],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [73.444, 27.628], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.098, -0.682],
                      [7.921, 0.682],
                      [-7.921, 0.682],
                      [-8.098, -0.682],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.6941, 0.0902, 0.1333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.397, 105.559], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.568, -0.691],
                      [8.391, 0.691],
                      [-8.391, 0.691],
                      [-8.568, -0.691],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.6941, 0.0902, 0.1333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.398, 101.909], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.042, -0.699],
                      [8.86, 0.699],
                      [-8.861, 0.699],
                      [-9.042, -0.699],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.6941, 0.0902, 0.1333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.398, 98.259], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.515, -0.709],
                      [9.331, 0.709],
                      [-9.331, 0.709],
                      [-9.515, -0.709],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.6941, 0.0902, 0.1333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.397, 94.611], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.986, -0.718],
                      [9.802, 0.718],
                      [-9.801, 0.718],
                      [-9.986, -0.718],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.6941, 0.0902, 0.1333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.397, 90.961], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [10.458, -0.726],
                      [10.271, 0.726],
                      [-10.271, 0.726],
                      [-10.458, -0.726],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.6941, 0.0902, 0.1333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.398, 87.312], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [10.665, -11.217],
                      [10.458, -9.617],
                      [10.27, -8.165],
                      [9.986, -5.96],
                      [9.801, -4.525],
                      [9.514, -2.301],
                      [9.33, -0.883],
                      [9.042, 1.357],
                      [8.861, 2.755],
                      [8.568, 5.014],
                      [8.391, 6.395],
                      [8.098, 8.674],
                      [7.921, 10.038],
                      [7.768, 11.217],
                      [-7.77, 11.217],
                      [-7.921, 10.038],
                      [-8.098, 8.674],
                      [-8.393, 6.395],
                      [-8.568, 5.014],
                      [-8.861, 2.755],
                      [-9.042, 1.357],
                      [-9.331, -0.883],
                      [-9.516, -2.301],
                      [-9.801, -4.525],
                      [-9.986, -5.96],
                      [-10.271, -8.165],
                      [-10.46, -9.617],
                      [-10.665, -11.217],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.398, 96.203], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.023, -0.165],
                      [0, 0],
                      [-0.165, -0.023],
                      [-0.024, 0.165],
                      [0, 0],
                      [0.166, 0.024],
                    ],
                    o: [
                      [0, 0],
                      [-0.024, 0.166],
                      [0.165, 0.024],
                      [0, 0],
                      [0.024, -0.165],
                      [-0.165, -0.023],
                    ],
                    v: [
                      [1.253, -10.97],
                      [-1.851, 10.884],
                      [-1.595, 11.226],
                      [-1.253, 10.969],
                      [1.851, -10.885],
                      [1.594, -11.227],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [50.909, 85.839], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 10",
            ix: 10,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.023, -0.166],
                      [0, 0],
                      [-0.165, -0.023],
                      [-0.023, 0.165],
                      [0, 0],
                      [0.165, 0.024],
                    ],
                    o: [
                      [0, 0],
                      [-0.023, 0.166],
                      [0.165, 0.024],
                      [0, 0],
                      [0.024, -0.165],
                      [-0.165, -0.023],
                    ],
                    v: [
                      [1.253, -10.97],
                      [-1.852, 10.884],
                      [-1.595, 11.226],
                      [-1.254, 10.969],
                      [1.851, -10.886],
                      [1.594, -11.227],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [49.378, 85.623], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 11",
            ix: 11,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-1.848, -1.047],
                      [2.066, -0.49],
                      [1.848, 1.047],
                      [-2.066, 0.49],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [48.043, 99.937], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 12",
            ix: 12,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.668, 0.77],
                      [-0.668, 0.581],
                      [0.205, -0.77],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [52.218, 69.871], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 13",
            ix: 13,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.144, 0.021],
                      [0, 0],
                      [-0.021, 0.144],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.144, -0.021],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.02, 0.144],
                    ],
                    v: [
                      [1.568, 1.104],
                      [-1.827, 0.622],
                      [-2.049, 0.324],
                      [-1.844, -1.125],
                      [2.07, -0.568],
                      [1.865, 0.88],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [47.515, 103.696], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 14",
            ix: 14,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.805, 1.35],
                      [-2.11, 0.794],
                      [-1.805, -1.35],
                      [2.11, -0.794],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [47.781, 101.778], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 15",
            ix: 15,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.958, 2.258],
                      [-1.958, 1.701],
                      [-0.271, -0.907],
                      [0.602, -2.258],
                      [1.064, -0.718],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.821, 71.359], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 16",
            ix: 16,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-0.139, 15.034],
                      [-4.053, 14.477],
                      [0.14, -15.034],
                      [4.053, -14.479],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [49.725, 88.094], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 17",
            ix: 17,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -0.167],
                      [0, 0],
                      [-0.167, 0.001],
                      [0.001, 0.166],
                      [0, 0],
                      [0.167, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.001, 0.167],
                      [0.167, -0.001],
                      [0, 0],
                      [0, -0.167],
                      [-0.167, 0.001],
                    ],
                    v: [
                      [-0.339, -11.036],
                      [-0.266, 11.038],
                      [0.036, 11.339],
                      [0.337, 11.036],
                      [0.266, -11.038],
                      [-0.038, -11.34],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.876, 85.704], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 18",
            ix: 18,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -0.167],
                      [0, 0],
                      [-0.167, 0.001],
                      [0.001, 0.167],
                      [0, 0],
                      [0.167, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.001, 0.167],
                      [0.167, 0],
                      [0, 0],
                      [0, -0.167],
                      [-0.167, 0.001],
                    ],
                    v: [
                      [-0.339, -11.037],
                      [-0.266, 11.038],
                      [0.036, 11.339],
                      [0.337, 11.036],
                      [0.266, -11.039],
                      [-0.038, -11.34],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.329, 85.711], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 19",
            ix: 19,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-1.979, -0.77],
                      [1.975, -0.783],
                      [1.979, 0.77],
                      [-1.974, 0.783],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.068, 100.068], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 20",
            ix: 20,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.674, 0.726],
                      [-0.674, 0.731],
                      [-0.005, -0.731],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.971, 69.654], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 21",
            ix: 21,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.145, 0],
                      [0, 0],
                      [0, 0.146],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.145, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.001, 0.146],
                    ],
                    v: [
                      [1.717, 0.857],
                      [-1.712, 0.87],
                      [-1.975, 0.607],
                      [-1.98, -0.857],
                      [1.975, -0.87],
                      [1.979, 0.594],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.08, 103.873], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 22",
            ix: 22,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.98, 1.076],
                      [-1.973, 1.089],
                      [-1.98, -1.077],
                      [1.973, -1.089],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.074, 101.928], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 23",
            ix: 23,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.977, 2.13],
                      [-1.977, 2.143],
                      [-0.684, -0.682],
                      [-0.014, -2.143],
                      [0.665, -0.686],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.98, 71.067], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 24",
            ix: 24,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [2.025, 14.898],
                      [-1.928, 14.911],
                      [-2.025, -14.897],
                      [1.927, -14.911],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.029, 88.106], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 25",
            ix: 25,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.025, -0.164],
                      [0, 0],
                      [-0.165, 0.026],
                      [0.026, 0.165],
                      [0, 0],
                      [0.164, -0.025],
                    ],
                    o: [
                      [0, 0],
                      [0.025, 0.165],
                      [0.165, -0.025],
                      [0, 0],
                      [-0.025, -0.165],
                      [-0.165, 0.026],
                    ],
                    v: [
                      [-1.97, -10.865],
                      [1.373, 10.955],
                      [1.717, 11.208],
                      [1.969, 10.864],
                      [-1.373, -10.956],
                      [-1.717, -11.209],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [40.675, 85.591], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 26",
            ix: 26,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.025, -0.165],
                      [0, 0],
                      [-0.165, 0.026],
                      [0.026, 0.165],
                      [0, 0],
                      [0.165, -0.025],
                    ],
                    o: [
                      [0, 0],
                      [0.026, 0.165],
                      [0.165, -0.025],
                      [0, 0],
                      [-0.026, -0.165],
                      [-0.164, 0.026],
                    ],
                    v: [
                      [-1.97, -10.864],
                      [1.372, 10.955],
                      [1.717, 11.208],
                      [1.969, 10.864],
                      [-1.373, -10.956],
                      [-1.718, -11.209],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [39.147, 85.826], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 27",
            ix: 27,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-2.072, -0.468],
                      [1.836, -1.067],
                      [2.072, 0.469],
                      [-1.837, 1.067],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [42.005, 99.916], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 28",
            ix: 28,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.667, 0.568],
                      [-0.667, 0.772],
                      [-0.221, -0.772],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [37.51, 69.902], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 29",
            ix: 29,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.144, -0.021],
                      [0, 0],
                      [0.022, 0.143],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.144, 0.022],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.022, 0.144],
                    ],
                    v: [
                      [1.834, 0.602],
                      [-1.554, 1.122],
                      [-1.854, 0.902],
                      [-2.076, -0.544],
                      [1.833, -1.143],
                      [2.054, 0.303],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [42.572, 103.668], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 30",
            ix: 30,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [2.118, 0.771],
                      [-1.791, 1.37],
                      [-2.118, -0.771],
                      [1.79, -1.37],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [42.287, 101.754], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 31",
            ix: 31,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.954, 1.666],
                      [-1.954, 2.265],
                      [-1.094, -0.721],
                      [-0.648, -2.265],
                      [0.24, -0.925],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [37.936, 71.395], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 32",
            ix: 32,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [4.211, 14.433],
                      [0.302, 15.032],
                      [-4.211, -14.433],
                      [-0.303, -15.032],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [40.194, 88.092], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 33",
            ix: 33,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [11.692, 74.72],
                      [-24.351, -74.72],
                      [-11.693, -74.72],
                      [24.35, 74.72],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [232.97, 205.307], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 34",
            ix: 34,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-11.692, 74.72],
                      [24.35, -74.72],
                      [11.693, -74.72],
                      [-24.35, 74.72],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [43.588, 205.307], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 35",
            ix: 35,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [93.083, 3.628],
                      [-93.083, 3.628],
                      [-93.083, -3.628],
                      [93.083, -3.628],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [138.404, 186.458], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 36",
            ix: 36,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.932, 0],
                      [0, 0],
                      [0, 0.932],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.931, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0.932],
                    ],
                    v: [
                      [104.087, 5.291],
                      [-104.087, 5.291],
                      [-105.774, 3.604],
                      [-105.774, -5.291],
                      [105.774, -5.291],
                      [105.774, 3.604],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [136.75, 125.295], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 37",
            ix: 37,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [113.915, 0.938],
                      [-113.914, 0.938],
                      [-113.914, -0.938],
                      [113.915, -0.938],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [133.577, 113.923], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 38",
            ix: 38,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -2.356],
                      [0, 0],
                      [1.759, 0],
                      [0, 0],
                      [0, 2.358],
                      [0, 0],
                      [-1.758, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 2.358],
                      [0, 0],
                      [-1.758, 0],
                      [0, 0],
                      [0, -2.356],
                      [0, 0],
                      [1.759, 0],
                    ],
                    v: [
                      [113.915, -2.05],
                      [113.915, 2.048],
                      [110.73, 6.317],
                      [-110.731, 6.317],
                      [-113.914, 2.048],
                      [-113.914, -2.05],
                      [-110.731, -6.317],
                      [110.73, -6.317],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [133.577, 113.736], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 39",
            ix: 39,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.371],
                      [-0.436, 0.108],
                      [0, 0],
                      [-0.108, -0.437],
                      [0.435, -0.107],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.436, -0.11],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.239],
                      [-2.331, 0.621],
                      [-1.737, -0.364],
                      [1.346, -1.129],
                      [2.331, -0.535],
                      [1.738, 0.449],
                      [-1.345, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [199.306, 267.682], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 40",
            ix: 40,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.064, 0],
                      [0.092, 0.37],
                      [-0.436, 0.108],
                      [0, 0],
                      [-0.108, -0.436],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.436, -0.109],
                      [0.108, 0.436],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.621],
                      [-1.737, -0.365],
                      [1.345, -1.13],
                      [2.331, -0.536],
                      [1.737, 0.448],
                      [-1.346, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [195.661, 266.324], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 41",
            ix: 41,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.109],
                      [0, 0],
                      [-0.108, -0.436],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.107, -0.436],
                      [0, 0],
                      [0.436, -0.11],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.239],
                      [-2.331, 0.622],
                      [-1.738, -0.365],
                      [1.345, -1.128],
                      [2.33, -0.535],
                      [1.738, 0.45],
                      [-1.346, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [192.3, 264.731], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 42",
            ix: 42,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.435, 0.109],
                      [0, 0],
                      [-0.108, -0.437],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.435, -0.11],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.065, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.622],
                      [-1.738, -0.365],
                      [1.346, -1.128],
                      [2.331, -0.535],
                      [1.737, 0.449],
                      [-1.346, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [189.217, 263.008], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 43",
            ix: 43,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.109],
                      [0, 0],
                      [-0.108, -0.437],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.437],
                      [0, 0],
                      [0.435, -0.109],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.065, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.622],
                      [-1.737, -0.365],
                      [1.346, -1.129],
                      [2.331, -0.535],
                      [1.737, 0.449],
                      [-1.346, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [183.071, 258.65], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 44",
            ix: 44,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.109],
                      [0, 0],
                      [-0.108, -0.436],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.436, -0.11],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.065, 0.015],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.621],
                      [-1.737, -0.365],
                      [1.345, -1.129],
                      [2.331, -0.535],
                      [1.737, 0.449],
                      [-1.346, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [186.134, 261.216], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 45",
            ix: 45,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.384, -1.848],
                      [0, 0],
                      [0.179, 3.666],
                      [2.033, 1.713],
                      [-0.053, 0.414],
                      [-0.288, -5.84],
                    ],
                    o: [
                      [0, 0],
                      [1.463, -1.672],
                      [-0.238, -4.876],
                      [0.079, -0.491],
                      [2.191, 1.675],
                      [0.17, 3.502],
                    ],
                    v: [
                      [0.739, 9.571],
                      [-0.727, 9.571],
                      [1.926, 1.669],
                      [-3.271, -8.207],
                      [-3.071, -9.571],
                      [3.101, 1.61],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [166.027, 269.164], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 46",
            ix: 46,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.082, 1.128],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.367, -0.667],
                      [0, 0],
                      [0.321, 1.669],
                    ],
                    v: [
                      [24.637, 1.37],
                      [-24.715, 1.37],
                      [-25.363, -1.371],
                      [25.042, -1.371],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [186.446, 277.364], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 47",
            ix: 47,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.42, -0.228],
                      [2.268, 0],
                      [0.098, 0.01],
                      [4.786, 2.629],
                      [1.801, 1.19],
                      [0.116, 0.517],
                      [-2.258, 0.702],
                      [-0.585, -0.491],
                      [-0.107, -0.683],
                      [-6.984, -3.822],
                      [-1.121, -0.094],
                      [-1.458, 1.089],
                    ],
                    o: [
                      [-1.271, 1.012],
                      [-0.1, 0],
                      [-1.601, -0.133],
                      [-2.742, -1.509],
                      [-2.955, -1.946],
                      [-0.432, -1.946],
                      [0.278, 0.217],
                      [-2.805, 0.765],
                      [0.275, 0.577],
                      [5.329, 2.916],
                      [1.519, 0.122],
                      [0.473, 0.228],
                    ],
                    v: [
                      [14.997, 5.493],
                      [8.631, 8.529],
                      [8.333, 8.517],
                      [-3.935, 2.864],
                      [-10.982, -1.317],
                      [-14.565, -4.524],
                      [-9.518, -8.53],
                      [-8.235, -7.483],
                      [-13.236, -4.837],
                      [-3.057, 1.79],
                      [8.447, 7.158],
                      [13.658, 4.808],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [190.043, 263.864], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 48",
            ix: 48,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.082, 1.128],
                      [-0.653, 4.2],
                      [-0.052, 0.414],
                      [0, 0.43],
                      [0, 0],
                      [-3.302, -2.409],
                      [-0.302, -0.237],
                      [-0.585, -0.491],
                      [-1.599, -1.438],
                      [-5.674, -2.372],
                      [-0.623, -0.3],
                      [-0.42, -0.227],
                      [-0.335, -1.74],
                    ],
                    o: [
                      [0, 0],
                      [-0.366, -0.666],
                      [-0.31, -4.176],
                      [0.079, -0.492],
                      [0.091, -0.703],
                      [0, -3.427],
                      [4.994, 4.898],
                      [0, 0],
                      [0.278, 0.217],
                      [0.841, 0.704],
                      [3.916, 3.524],
                      [0.723, 0.303],
                      [0.473, 0.228],
                      [4.638, 2.504],
                      [0.321, 1.669],
                    ],
                    v: [
                      [24.793, 13.072],
                      [-24.561, 13.072],
                      [-25.208, 10.332],
                      [-23.535, -4.706],
                      [-23.334, -6.069],
                      [-23.186, -7.786],
                      [-20.152, -10.626],
                      [-6.202, -10.663],
                      [-5.766, -10.328],
                      [-4.482, -9.281],
                      [-0.861, -6.118],
                      [15.393, 2.105],
                      [17.411, 3.01],
                      [18.749, 3.695],
                      [25.198, 10.332],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [186.291, 265.663], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 49",
            ix: 49,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.609, 2.027],
                      [-6.967, 1.885],
                      [-7.609, -2.027],
                      [7.484, -2.027],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [170.931, 244.846], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 50",
            ix: 50,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.781, 7.654],
                      [-5.268, 7.654],
                      [-7.14, -3.742],
                      [-7.781, -7.654],
                      [7.311, -7.654],
                      [7.436, -3.599],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [171.103, 250.473], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 51",
            ix: 51,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.469, 11.843],
                      [-4.579, 11.843],
                      [-7.092, -3.466],
                      [-8.469, -11.843],
                      [7.742, -11.843],
                      [7.999, -3.466],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [170.415, 246.284], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 52",
            ix: 52,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.359, 0],
                      [0, 0],
                      [0, 0],
                      [-0.008, 0.365],
                      [-0.369, -0.015],
                      [0, 0],
                      [0.007, -0.366],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-0.366, -0.008],
                      [0.008, -0.365],
                      [0, 0],
                      [0.365, 0.008],
                      [-0.008, 0.36],
                    ],
                    v: [
                      [10.279, 0.89],
                      [10.265, 0.89],
                      [-10.292, 0.449],
                      [-10.94, -0.227],
                      [-10.264, -0.875],
                      [10.293, -0.433],
                      [10.941, 0.243],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [169.467, 224.013], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 53",
            ix: 53,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.043, 0],
                      [0.064, 0.314],
                      [0.72, 13.201],
                      [1.406, 1.243],
                      [5.987, -0.994],
                      [19.847, 4.619],
                      [4.864, 7.842],
                      [-0.04, 0.167],
                      [-0.355, -0.085],
                      [0.084, -0.357],
                      [-8.1, -13.049],
                      [-10.45, -2.431],
                      [-11.806, 1.96],
                      [-1.61, -1.423],
                      [-0.783, -14.349],
                      [-2.647, -13.105],
                      [0.358, -0.072],
                    ],
                    o: [
                      [-0.309, 0],
                      [-2.666, -13.199],
                      [-0.597, -10.947],
                      [-1.139, -1.007],
                      [-11.909, 1.977],
                      [-10.811, -2.515],
                      [-8.387, -13.522],
                      [0.084, -0.355],
                      [0.355, 0.084],
                      [-0.038, 0.162],
                      [4.672, 7.526],
                      [19.591, 4.559],
                      [7.07, -1.173],
                      [1.74, 1.538],
                      [0.717, 13.16],
                      [0.073, 0.358],
                      [-0.045, 0.009],
                    ],
                    v: [
                      [50.195, 55.705],
                      [49.547, 55.174],
                      [45.266, 12.344],
                      [42.7, -10.184],
                      [30.845, -9.411],
                      [-18.908, -9.065],
                      [-42.53, -24.673],
                      [-47.054, -55.128],
                      [-46.258, -55.62],
                      [-45.766, -54.824],
                      [-41.399, -25.36],
                      [-18.609, -10.354],
                      [30.628, -10.717],
                      [43.576, -11.176],
                      [46.588, 12.271],
                      [50.844, 54.912],
                      [50.327, 55.691],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [113.005, 168.85], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 54",
            ix: 54,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.435, 11.505],
                      [0, 0],
                      [30.838, 1.804],
                      [0, 0],
                      [-9.666, -1.524],
                      [-15.441, -6.662],
                      [-2.895, -1.785],
                      [-1.403, -9.156],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-1.437, -11.506],
                      [0, 0],
                      [-68.329, -3.991],
                      [2.309, 3.189],
                      [29.666, 2.848],
                      [3.718, 1.603],
                      [3.723, 2.295],
                      [4.219, 27.521],
                    ],
                    v: [
                      [68.055, 61.01],
                      [49.568, 61.01],
                      [43.855, 28.986],
                      [41.674, -9.799],
                      [-3.896, -6.817],
                      [-46.318, -61.01],
                      [-17.025, -51.903],
                      [50.633, -37.356],
                      [60.551, -32.271],
                      [68.007, -13.858],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [111.338, 173.432], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 55",
            ix: 55,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-5.188, -0.639],
                      [0, 0],
                      [3.336, 6.219],
                      [-0.418, -0.286],
                    ],
                    o: [
                      [0, 0],
                      [-4.19, -1.867],
                      [0.364, 0.26],
                      [3.692, 2.511],
                    ],
                    v: [
                      [7.855, 1.196],
                      [7.08, 6.04],
                      [-7.855, -6.04],
                      [-6.682, -5.221],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.5294, 0.4314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [106.532, 8.489], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 56",
            ix: 56,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-5.188, -0.639],
                      [0, 0],
                      [13.969, 14.003],
                      [0.439, 4.186],
                      [-1.696, -1.536],
                      [-0.418, -0.286],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [3.75, -2.795],
                      [0, 0],
                      [0.331, 0.3],
                      [3.692, 2.511],
                    ],
                    v: [
                      [11.626, -4.293],
                      [9.99, 5.922],
                      [-11.626, -0.275],
                      [-6.883, -13.728],
                      [-4.085, -11.529],
                      [-2.911, -10.71],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [102.761, 13.978], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 57",
            ix: 57,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-7.177, -1.455],
                      [-0.031, 0.078],
                      [0.307, 0.126],
                      [0.126, -0.307],
                      [6.487, 1.314],
                      [0.127, 0.134],
                      [0.242, -0.228],
                      [-0.228, -0.242],
                    ],
                    o: [
                      [7.473, 1.514],
                      [0.127, -0.307],
                      [-0.309, -0.127],
                      [-0.12, 0.291],
                      [-6.806, -1.379],
                      [-0.228, -0.243],
                      [-0.242, 0.228],
                      [0.531, 0.563],
                    ],
                    v: [
                      [4.799, 7.413],
                      [15.572, 1.122],
                      [15.245, 0.337],
                      [14.46, 0.665],
                      [5.038, 6.234],
                      [-14.596, -8.674],
                      [-15.445, -8.699],
                      [-15.471, -7.849],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [109.9, 45.712], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 58",
            ix: 58,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.169, 0],
                      [0.081, 0.049],
                      [0.028, 0.002],
                      [-0.02, 0.275],
                      [-0.283, -0.018],
                      [-2.533, -1.538],
                      [0.143, -0.237],
                    ],
                    o: [
                      [-0.088, 0],
                      [-2.328, -1.413],
                      [-0.275, -0.02],
                      [0.019, -0.276],
                      [0.125, 0.009],
                      [0.236, 0.143],
                      [-0.094, 0.155],
                    ],
                    v: [
                      [2.673, 1.358],
                      [2.414, 1.286],
                      [-2.761, -0.343],
                      [-3.224, -0.877],
                      [-2.69, -1.34],
                      [2.933, 0.431],
                      [3.101, 1.118],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [96.969, 84.308], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 59",
            ix: 59,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.084, 0],
                      [0.092, 0.162],
                      [-0.239, 0.137],
                      [-3.861, 0.298],
                      [-0.021, -0.276],
                      [0.275, -0.022],
                      [0.036, -0.021],
                    ],
                    o: [
                      [-0.174, 0],
                      [-0.137, -0.239],
                      [0.156, -0.088],
                      [0.278, -0.022],
                      [0.021, 0.275],
                      [-3.633, 0.28],
                      [-0.079, 0.044],
                    ],
                    v: [
                      [-3.725, 1.714],
                      [-4.16, 1.462],
                      [-3.974, 0.779],
                      [3.739, -1.693],
                      [4.276, -1.232],
                      [3.816, -0.695],
                      [-3.478, 1.65],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [97.232, 86.181], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 60",
            ix: 60,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [3.318, -12.804],
                      [7.207, 2.784],
                      [0.091, 0.626],
                      [0.064, 0.408],
                      [0.614, 2.938],
                      [-12.992, 1.592],
                      [-0.903, 0.096],
                    ],
                    o: [
                      [-2.882, 11.119],
                      [-0.085, -0.625],
                      [-0.059, -0.407],
                      [-0.461, -3.023],
                      [-2.67, -12.815],
                      [0.944, -0.115],
                      [6.507, 2.772],
                    ],
                    v: [
                      [11.021, 1.479],
                      [-9.604, 18.737],
                      [-9.868, 16.864],
                      [-10.053, 15.643],
                      [-11.669, 6.655],
                      [0.53, -21.204],
                      [3.306, -21.521],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [73.446, 35.133], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 61",
            ix: 61,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [5.508, 3.911],
                      [-3.72, -6.834],
                      [-0.016, 1.485],
                      [0, 0.049],
                      [-0.002, 0.15],
                    ],
                    o: [
                      [-0.518, 5.764],
                      [-0.087, -1.475],
                      [0, -0.049],
                      [0.004, -0.153],
                      [0.14, -8.73],
                    ],
                    v: [
                      [-2.495, -11.053],
                      [2.124, 11.053],
                      [2.012, 6.613],
                      [2.011, 6.463],
                      [2.02, 6.01],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [133.167, 37.525], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 62",
            ix: 62,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-8.331, -1.688],
                      [-0.239, -0.02],
                      [-1.34, 1.463],
                      [0.071, 0.411],
                      [0.325, -0.055],
                      [-0.056, -0.328],
                      [3.505, -3.827],
                      [1.879, 0.152],
                      [0.088, 0.202],
                      [0.305, -0.132],
                      [-0.133, -0.304],
                    ],
                    o: [
                      [0.239, 0.049],
                      [2.244, 0.18],
                      [3.901, -4.262],
                      [-0.055, -0.327],
                      [-0.327, 0.056],
                      [0.016, 0.095],
                      [-1.091, 1.191],
                      [-7.862, -0.632],
                      [-0.133, -0.304],
                      [-0.304, 0.134],
                      [0.361, 0.827],
                    ],
                    v: [
                      [3.579, 11.027],
                      [4.296, 11.13],
                      [9.696, 9.196],
                      [11.924, -5.192],
                      [11.231, -5.684],
                      [10.739, -4.99],
                      [8.808, 8.385],
                      [4.393, 9.93],
                      [-12.362, -10.868],
                      [-13.154, -11.178],
                      [-13.464, -10.386],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [102.614, 23.294], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 63",
            ix: 63,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [5.879, 0.427],
                      [1.864, 3.182],
                      [0, 0],
                    ],
                    o: [
                      [-1.405, 2.182],
                      [-8.112, -0.589],
                      [0.253, -0.01],
                      [0, 0],
                    ],
                    v: [
                      [12.753, 1.32],
                      [2.561, 5.548],
                      [-12.753, -5.962],
                      [-12.367, -5.975],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [102.068, 18.643], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 64",
            ix: 64,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.105, -0.315],
                      [-0.206, -0.041],
                      [-0.102, 0.035],
                      [-11.247, -4.66],
                      [-4.106, 0.082],
                      [-0.022, 0.214],
                      [-0.003, 0.027],
                      [-0.398, 0.284],
                      [5.921, 2.451],
                      [0.451, -0.151],
                    ],
                    o: [
                      [0.07, 0.211],
                      [0.1, 0.021],
                      [0.109, -0.036],
                      [5.715, 2.366],
                      [0.018, -0.238],
                      [0.003, -0.03],
                      [0, 0],
                      [-4.037, 0.197],
                      [-11.675, -4.835],
                      [-0.316, 0.105],
                    ],
                    v: [
                      [-19.147, -0.207],
                      [-18.694, 0.192],
                      [-18.387, 0.175],
                      [3.655, 1.338],
                      [18.556, 4.528],
                      [18.614, 3.85],
                      [18.622, 3.766],
                      [19.252, 3.332],
                      [4.117, 0.226],
                      [-18.766, -0.967],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [108.258, 83.118], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 65",
            ix: 65,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.467, -0.174],
                      [0, 0],
                      [0.322, 0.25],
                      [-0.25, 0.322],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.25, 0.322],
                      [-0.322, -0.251],
                      [0, 0],
                      [0.501, 0.182],
                    ],
                    v: [
                      [2.413, -2.111],
                      [-1.001, 2.265],
                      [-2.035, 2.394],
                      [-2.163, 1.359],
                      [0.958, -2.644],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [120.069, 24.585], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 66",
            ix: 66,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.154, 0.083],
                      [-0.205, -0.073],
                      [0.136, -0.384],
                      [0, 0],
                      [0.383, 0.136],
                      [-0.136, 0.384],
                      [0, 0],
                    ],
                    o: [
                      [0.177, -0.096],
                      [0.383, 0.136],
                      [0, 0],
                      [-0.136, 0.383],
                      [-0.384, -0.136],
                      [0, 0],
                      [0.063, -0.179],
                    ],
                    v: [
                      [0.753, -3.793],
                      [1.351, -3.84],
                      [1.799, -2.898],
                      [-0.411, 3.328],
                      [-1.351, 3.776],
                      [-1.799, 2.836],
                      [0.41, -3.392],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [124.694, 34.384], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 67",
            ix: 67,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.046, 0.169],
                      [-0.118, 0.183],
                      [-0.342, -0.221],
                      [0, 0],
                      [0.221, -0.342],
                      [0.342, 0.221],
                      [0, 0],
                    ],
                    o: [
                      [-0.053, -0.194],
                      [0.22, -0.342],
                      [0, 0],
                      [0.341, 0.22],
                      [-0.22, 0.342],
                      [0, 0],
                      [-0.16, -0.103],
                    ],
                    v: [
                      [-3.525, -1.598],
                      [-3.433, -2.191],
                      [-2.415, -2.41],
                      [3.138, 1.172],
                      [3.357, 2.19],
                      [2.339, 2.41],
                      [-3.213, -1.172],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [91.089, 27.968], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 68",
            ix: 68,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.109, -0.138],
                      [0.036, -0.214],
                      [0.401, 0.068],
                      [0, 0],
                      [-0.069, 0.401],
                      [-0.402, -0.068],
                      [0, 0],
                    ],
                    o: [
                      [0.125, 0.158],
                      [-0.068, 0.401],
                      [0, 0],
                      [-0.401, -0.067],
                      [0.068, -0.402],
                      [0, 0],
                      [0.187, 0.032],
                    ],
                    v: [
                      [3.851, 0.097],
                      [3.999, 0.678],
                      [3.15, 1.281],
                      [-3.365, 0.171],
                      [-3.967, -0.678],
                      [-3.117, -1.282],
                      [3.396, -0.172],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [78.265, 88.132], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 69",
            ix: 69,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.176, -0.002],
                      [-0.144, -0.162],
                      [0.305, -0.269],
                      [0, 0],
                      [0.27, 0.304],
                      [-0.305, 0.27],
                      [0, 0],
                    ],
                    o: [
                      [0.202, 0.002],
                      [0.269, 0.305],
                      [0, 0],
                      [-0.305, 0.27],
                      [-0.27, -0.305],
                      [0, 0],
                      [0.142, -0.125],
                    ],
                    v: [
                      [2.481, -2.967],
                      [3.027, -2.719],
                      [2.963, -1.679],
                      [-1.986, 2.699],
                      [-3.026, 2.636],
                      [-2.962, 1.595],
                      [1.987, -2.782],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [70.668, 84.245], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 70",
            ix: 70,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.083, 0.155],
                      [-0.074, 0.204],
                      [-0.382, -0.138],
                      [0, 0],
                      [0.137, -0.384],
                      [0.383, 0.138],
                      [0, 0],
                    ],
                    o: [
                      [-0.095, -0.177],
                      [0.137, -0.382],
                      [0, 0],
                      [0.383, 0.138],
                      [-0.138, 0.382],
                      [0, 0],
                      [-0.178, -0.064],
                    ],
                    v: [
                      [-3.79, -0.771],
                      [-3.834, -1.368],
                      [-2.892, -1.811],
                      [3.327, 0.424],
                      [3.771, 1.368],
                      [2.827, 1.811],
                      [-3.391, -0.426],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [73.893, 73.644], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 71",
            ix: 71,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.371, -0.302],
                      [0, 0],
                      [-0.062, 0.165],
                      [-0.201, 0.078],
                      [-0.15, -0.378],
                    ],
                    o: [
                      [-0.418, 0.366],
                      [0, 0],
                      [-0.072, -0.177],
                      [0.071, -0.189],
                      [0.378, -0.151],
                      [0, 0],
                    ],
                    v: [
                      [1.062, 0.648],
                      [-0.127, 1.653],
                      [-0.991, -0.547],
                      [-0.996, -1.076],
                      [-0.576, -1.502],
                      [0.379, -1.088],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [129.439, 83.42], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 72",
            ix: 72,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.042, 0.17],
                      [-0.191, 0.103],
                      [-0.193, -0.358],
                      [0, 0],
                      [0.358, -0.192],
                      [0.193, 0.359],
                      [0, 0],
                    ],
                    o: [
                      [0.048, -0.196],
                      [0.359, -0.193],
                      [0, 0],
                      [0.193, 0.359],
                      [-0.358, 0.192],
                      [0, 0],
                      [-0.09, -0.167],
                    ],
                    v: [
                      [-2.321, -3.085],
                      [-1.954, -3.559],
                      [-0.956, -3.259],
                      [2.17, 2.562],
                      [1.87, 3.56],
                      [0.872, 3.259],
                      [-2.254, -2.562],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [110.493, 32.388], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 73",
            ix: 73,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.117, -0.131],
                      [0.024, -0.217],
                      [0.404, 0.045],
                      [0, 0],
                      [-0.044, 0.405],
                      [-0.405, -0.044],
                      [0, 0],
                    ],
                    o: [
                      [0.134, 0.15],
                      [-0.044, 0.404],
                      [0, 0],
                      [-0.404, -0.044],
                      [0.045, -0.404],
                      [0, 0],
                      [0.189, 0.02],
                    ],
                    v: [
                      [3.844, -0.134],
                      [4.027, 0.438],
                      [3.215, 1.09],
                      [-3.354, 0.374],
                      [-4.007, -0.439],
                      [-3.194, -1.09],
                      [3.374, -0.375],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [113.878, 43.685], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 74",
            ix: 74,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.173, 0.03],
                      [0.115, 0.184],
                      [-0.344, 0.217],
                      [0, 0],
                      [-0.216, -0.345],
                      [0.344, -0.217],
                      [0, 0],
                    ],
                    o: [
                      [-0.199, -0.034],
                      [-0.216, -0.345],
                      [0, 0],
                      [0.344, -0.217],
                      [0.216, 0.345],
                      [0, 0],
                      [-0.161, 0.101],
                    ],
                    v: [
                      [-2.924, 2.524],
                      [-3.422, 2.19],
                      [-3.19, 1.174],
                      [2.407, -2.337],
                      [3.422, -2.105],
                      [3.19, -1.09],
                      [-2.406, 2.423],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [117.081, 62.305], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 75",
            ix: 75,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.175, 0.018],
                      [-0.16, -0.146],
                      [0.274, -0.302],
                      [0, 0],
                      [0.301, 0.273],
                      [-0.273, 0.301],
                      [0, 0],
                    ],
                    o: [
                      [0.2, -0.021],
                      [0.302, 0.273],
                      [0, 0],
                      [-0.273, 0.301],
                      [-0.302, -0.274],
                      [0, 0],
                      [0.127, -0.14],
                    ],
                    v: [
                      [2.146, -3.212],
                      [2.714, -3.024],
                      [2.766, -1.983],
                      [-1.676, 2.91],
                      [-2.715, 2.96],
                      [-2.766, 1.92],
                      [1.674, -2.974],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [114.626, 79.324], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 76",
            ix: 76,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.16, -0.315],
                      [0.064, -0.115],
                      [0, 0],
                      [0.088, 0.152],
                      [-0.066, 0.206],
                      [-0.388, -0.123],
                    ],
                    o: [
                      [-0.115, 0.356],
                      [-0.059, 0.117],
                      [0, 0],
                      [-0.182, -0.057],
                      [-0.103, -0.172],
                      [0.123, -0.389],
                      [0, 0],
                    ],
                    v: [
                      [2.367, -0.026],
                      [1.952, 0.979],
                      [1.768, 1.329],
                      [-1.82, 0.199],
                      [-2.232, -0.131],
                      [-2.301, -0.725],
                      [-1.378, -1.207],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [134.684, 75.609], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 77",
            ix: 77,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.056, 0.167],
                      [-0.2, 0.087],
                      [-0.162, -0.373],
                      [0, 0],
                      [0.373, -0.162],
                      [0.162, 0.373],
                      [0, 0],
                    ],
                    o: [
                      [0.063, -0.191],
                      [0.373, -0.163],
                      [0, 0],
                      [0.162, 0.372],
                      [-0.373, 0.163],
                      [0, 0],
                      [-0.075, -0.173],
                    ],
                    v: [
                      [-2.059, -3.262],
                      [-1.654, -3.704],
                      [-0.686, -3.323],
                      [1.954, 2.736],
                      [1.572, 3.704],
                      [0.603, 3.323],
                      [-2.036, -2.735],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [126.7, 68.978], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 78",
            ix: 78,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.176, 0],
                      [0.146, 0.161],
                      [-0.302, 0.272],
                      [0, 0],
                      [-0.272, -0.303],
                      [0.303, -0.273],
                      [0, 0],
                    ],
                    o: [
                      [-0.202, 0],
                      [-0.272, -0.302],
                      [0, 0],
                      [0.303, -0.272],
                      [0.272, 0.302],
                      [0, 0],
                      [-0.141, 0.127],
                    ],
                    v: [
                      [-2.455, 2.988],
                      [-3.003, 2.745],
                      [-2.949, 1.704],
                      [1.963, -2.716],
                      [3.004, -2.661],
                      [2.949, -1.621],
                      [-1.962, 2.799],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [69.932, 63.78], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 79",
            ix: 79,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.1, 0.144],
                      [-0.05, 0.212],
                      [-0.396, -0.093],
                      [0, 0],
                      [0.093, -0.396],
                      [0.396, 0.094],
                      [0, 0],
                    ],
                    o: [
                      [-0.115, -0.166],
                      [0.093, -0.396],
                      [0, 0],
                      [0.396, 0.094],
                      [-0.093, 0.396],
                      [0, 0],
                      [-0.185, -0.044],
                    ],
                    v: [
                      [-3.842, -0.338],
                      [-3.955, -0.927],
                      [-3.069, -1.475],
                      [3.363, 0.04],
                      [3.911, 0.926],
                      [3.025, 1.474],
                      [-3.406, -0.041],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [94.265, 54.49], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 80",
            ix: 80,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.079, -0.587],
                      [0, 0],
                      [0.177, 0],
                      [0.145, 0.162],
                      [-0.301, 0.272],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.143, 0.128],
                      [-0.202, 0],
                      [-0.272, -0.302],
                      [0, 0],
                      [0.074, 0.597],
                    ],
                    v: [
                      [2.339, -0.72],
                      [-1.025, 2.309],
                      [-1.519, 2.497],
                      [-2.067, 2.255],
                      [-2.013, 1.214],
                      [2.11, -2.497],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [133.825, 57.885], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 81",
            ix: 81,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.176, 0],
                      [0.146, 0.161],
                      [-0.302, 0.272],
                      [0, 0],
                      [-0.272, -0.303],
                      [0.303, -0.272],
                      [0, 0],
                    ],
                    o: [
                      [-0.202, 0],
                      [-0.272, -0.303],
                      [0, 0],
                      [0.303, -0.272],
                      [0.272, 0.302],
                      [0, 0],
                      [-0.141, 0.126],
                    ],
                    v: [
                      [-2.455, 2.988],
                      [-3.003, 2.745],
                      [-2.949, 1.704],
                      [1.963, -2.716],
                      [3.004, -2.661],
                      [2.949, -1.621],
                      [-1.962, 2.8],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [102.838, 68.936], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 82",
            ix: 82,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.16, -0.017],
                      [0.003, -0.115],
                      [0, -0.049],
                      [-0.003, -0.357],
                      [0, 0],
                      [0.175, 0.03],
                      [0.115, 0.184],
                      [-0.344, 0.216],
                      [0, 0],
                    ],
                    o: [
                      [-0.002, 0.11],
                      [-0.002, 0.05],
                      [-0.005, 0.356],
                      [0, 0],
                      [-0.159, 0.101],
                      [-0.198, -0.034],
                      [-0.216, -0.346],
                      [0, 0],
                      [0.144, -0.091],
                    ],
                    v: [
                      [3.258, -2.492],
                      [3.253, -2.155],
                      [3.253, -2.005],
                      [3.251, -0.936],
                      [-2.027, 2.376],
                      [-2.545, 2.479],
                      [-3.043, 2.145],
                      [-2.81, 1.129],
                      [2.787, -2.383],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [131.926, 46.144], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 83",
            ix: 83,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.366, -4.078],
                      [0.16, -0.315],
                      [0.064, -0.115],
                      [2.006, -1.77],
                      [0.371, -0.301],
                      [0.432, -0.309],
                      [0, 0],
                      [0.003, -0.032],
                      [0.02, -0.239],
                      [0.044, -1.235],
                      [0.027, -1.171],
                      [0.01, -0.945],
                      [13.548, -2.281],
                      [-0.208, 1.12],
                      [-0.214, 4.165],
                      [1.316, 9.629],
                      [0.091, 0.626],
                      [0.066, 0.408],
                      [0.613, 2.937],
                      [-12.993, 1.592],
                      [-0.902, 0.096],
                      [-1.4, 0.054],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.174, -0.417],
                      [-0.467, -0.174],
                      [-1.002, -0.442],
                      [-1.259, -0.896],
                      [0.14, -8.73],
                      [0, -0.039],
                      [0.002, -0.115],
                      [0, -0.049],
                      [-0.002, -0.357],
                      [-0.069, -1.122],
                      [-0.18, -1.606],
                      [-0.078, -0.634],
                      [-0.079, -0.587],
                    ],
                    o: [
                      [-0.115, 0.356],
                      [-0.059, 0.117],
                      [-1.547, 2.872],
                      [-0.418, 0.367],
                      [-0.741, 0.602],
                      [-0.398, 0.284],
                      [-0.003, 0.027],
                      [-0.022, 0.214],
                      [-0.068, 0.909],
                      [-0.039, 0.994],
                      [-0.015, 0.869],
                      [-16.333, -7.295],
                      [0.248, -1.171],
                      [0.958, -5.167],
                      [0.321, -6.203],
                      [-0.083, -0.626],
                      [-0.059, -0.408],
                      [-0.462, -3.023],
                      [-2.672, -12.816],
                      [0.943, -0.115],
                      [6.124, -0.653],
                      [0.256, -0.01],
                      [0, 0],
                      [0, 0],
                      [1.372, 0.442],
                      [0.501, 0.182],
                      [1.217, 0.457],
                      [2.011, 0.888],
                      [5.511, 3.912],
                      [0, 0.039],
                      [-0.003, 0.11],
                      [-0.003, 0.05],
                      [-0.005, 0.356],
                      [0.008, 1.126],
                      [0.093, 1.648],
                      [0.071, 0.643],
                      [0.074, 0.596],
                      [1.007, 7.642],
                    ],
                    v: [
                      [38.288, 20.823],
                      [37.873, 21.828],
                      [37.689, 22.178],
                      [31.739, 29.307],
                      [30.55, 30.312],
                      [28.748, 31.69],
                      [28.117, 32.122],
                      [28.109, 32.208],
                      [28.05, 32.886],
                      [27.876, 36.115],
                      [27.783, 39.371],
                      [27.741, 42.094],
                      [-35.775, 41.684],
                      [-35.092, 38.249],
                      [-33.383, 24.263],
                      [-34.921, -0.89],
                      [-35.184, -2.763],
                      [-35.37, -3.984],
                      [-36.983, -12.972],
                      [-24.786, -40.832],
                      [-22.011, -41.149],
                      [-9.448, -42.079],
                      [-9.06, -42.094],
                      [16.059, -34.798],
                      [18.449, -34.106],
                      [22.265, -32.819],
                      [23.719, -32.286],
                      [27.044, -30.94],
                      [31.908, -28.288],
                      [36.424, -11.226],
                      [36.422, -11.108],
                      [36.417, -10.772],
                      [36.417, -10.622],
                      [36.414, -9.553],
                      [36.53, -6.182],
                      [36.95, -1.29],
                      [37.173, 0.628],
                      [37.402, 2.406],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [98.762, 54.76], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 84",
            ix: 84,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [19.556, -7.232],
                      [-0.246, 2.334],
                      [-11.807, -2.801],
                    ],
                    o: [
                      [-12.307, -0.748],
                      [0.964, -5.026],
                      [12.294, -0.675],
                      [0, 0],
                    ],
                    v: [
                      [28.04, 0.654],
                      [-28.04, 6.878],
                      [-26.29, -4.922],
                      [27.945, -4.077],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [95.281, 97.796], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 85",
            ix: 85,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.213, -1.49],
                      [0.616, 0.088],
                      [-0.214, 1.49],
                      [-0.617, -0.088],
                    ],
                    o: [
                      [-0.214, 1.49],
                      [-0.617, -0.089],
                      [0.213, -1.491],
                      [0.616, 0.088],
                    ],
                    v: [
                      [1.117, 0.16],
                      [-0.386, 2.698],
                      [-1.116, -0.16],
                      [0.387, -2.698],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [101.718, 107.075], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 86",
            ix: 86,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.829, 4.321],
                      [-0.246, 2.334],
                      [-11.808, -2.8],
                      [0, 0],
                    ],
                    o: [
                      [-28.9, 15.896],
                      [1.544, -4.212],
                      [0.963, -5.025],
                      [12.294, -0.675],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [30.229, 4.023],
                      [-30.229, 7.119],
                      [-26.712, -6.164],
                      [-24.962, -17.963],
                      [29.273, -17.119],
                      [29.367, -12.387],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [93.953, 110.837], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 87",
            ix: 87,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [40.009, -12.794],
                      [-5.341, -1.121],
                      [0, 0],
                      [0.678, 4.662],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-31.805, 2.915],
                      [-27.049, 8.021],
                      [28.628, 9.879],
                      [31.712, 3.325],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [94.791, 93.53], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 88",
            ix: 88,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.108],
                      [0, 0],
                      [-0.108, -0.437],
                      [0.436, -0.107],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.435, -0.11],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.239],
                      [-2.331, 0.621],
                      [-1.737, -0.364],
                      [1.346, -1.129],
                      [2.331, -0.535],
                      [1.737, 0.449],
                      [-1.346, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [231.49, 267.619], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 89",
            ix: 89,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.108],
                      [0, 0],
                      [-0.108, -0.436],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.436, -0.109],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.621],
                      [-1.737, -0.365],
                      [1.346, -1.129],
                      [2.331, -0.536],
                      [1.737, 0.449],
                      [-1.345, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [227.845, 266.261], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 90",
            ix: 90,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.109],
                      [0, 0],
                      [-0.108, -0.436],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.437],
                      [0, 0],
                      [0.436, -0.109],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.065, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.621],
                      [-1.737, -0.365],
                      [1.346, -1.13],
                      [2.331, -0.535],
                      [1.737, 0.449],
                      [-1.346, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [224.483, 264.667], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 91",
            ix: 91,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.109],
                      [0, 0],
                      [-0.108, -0.437],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.436, -0.109],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.622],
                      [-1.737, -0.364],
                      [1.346, -1.129],
                      [2.331, -0.535],
                      [1.737, 0.45],
                      [-1.345, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [221.4, 262.944], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 92",
            ix: 92,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.092, 0.37],
                      [-0.436, 0.108],
                      [0, 0],
                      [-0.108, -0.437],
                      [0.436, -0.107],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.107, -0.437],
                      [0, 0],
                      [0.435, -0.109],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.622],
                      [-1.737, -0.364],
                      [1.346, -1.129],
                      [2.33, -0.535],
                      [1.738, 0.449],
                      [-1.345, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [215.254, 258.586], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 93",
            ix: 93,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.065, 0],
                      [0.091, 0.37],
                      [-0.436, 0.109],
                      [0, 0],
                      [-0.108, -0.437],
                      [0.436, -0.108],
                      [0, 0],
                    ],
                    o: [
                      [-0.365, 0],
                      [-0.108, -0.436],
                      [0, 0],
                      [0.435, -0.109],
                      [0.108, 0.435],
                      [0, 0],
                      [-0.066, 0.016],
                    ],
                    v: [
                      [-1.542, 1.238],
                      [-2.331, 0.621],
                      [-1.737, -0.365],
                      [1.345, -1.129],
                      [2.331, -0.535],
                      [1.738, 0.449],
                      [-1.345, 1.215],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [218.318, 261.152], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 94",
            ix: 94,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.383, -1.848],
                      [0, 0],
                      [0.179, 3.666],
                      [2.033, 1.713],
                      [-0.053, 0.414],
                      [-0.287, -5.84],
                    ],
                    o: [
                      [0, 0],
                      [1.463, -1.672],
                      [-0.238, -4.876],
                      [0.079, -0.492],
                      [2.191, 1.675],
                      [0.171, 3.501],
                    ],
                    v: [
                      [0.739, 9.571],
                      [-0.727, 9.571],
                      [1.926, 1.669],
                      [-3.271, -8.207],
                      [-3.071, -9.571],
                      [3.1, 1.61],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [198.211, 269.1], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 95",
            ix: 95,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.083, 1.128],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.367, -0.667],
                      [0, 0],
                      [0.321, 1.669],
                    ],
                    v: [
                      [24.638, 1.371],
                      [-24.714, 1.371],
                      [-25.363, -1.37],
                      [25.043, -1.37],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [218.629, 277.301], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 96",
            ix: 96,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.421, -0.228],
                      [2.267, 0],
                      [0.098, 0.009],
                      [4.786, 2.629],
                      [1.8, 1.19],
                      [0.116, 0.517],
                      [-2.258, 0.702],
                      [-0.585, -0.491],
                      [-0.107, -0.683],
                      [-6.984, -3.823],
                      [-1.121, -0.094],
                      [-1.457, 1.089],
                    ],
                    o: [
                      [-1.271, 1.012],
                      [-0.1, 0],
                      [-1.601, -0.133],
                      [-2.742, -1.509],
                      [-2.955, -1.947],
                      [-0.432, -1.946],
                      [0.277, 0.217],
                      [-2.805, 0.765],
                      [0.275, 0.577],
                      [5.329, 2.916],
                      [1.519, 0.122],
                      [0.473, 0.228],
                    ],
                    v: [
                      [14.997, 5.494],
                      [8.631, 8.53],
                      [8.333, 8.518],
                      [-3.935, 2.865],
                      [-10.982, -1.316],
                      [-14.565, -4.523],
                      [-9.518, -8.529],
                      [-8.235, -7.482],
                      [-13.236, -4.836],
                      [-3.057, 1.791],
                      [8.447, 7.159],
                      [13.657, 4.809],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.0314, 0.3098, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [222.227, 263.801], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 97",
            ix: 97,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.082, 1.129],
                      [-0.653, 4.2],
                      [-0.053, 0.414],
                      [0, 0.43],
                      [0, 0],
                      [-3.302, -2.409],
                      [-0.302, -0.237],
                      [-0.585, -0.491],
                      [-1.598, -1.438],
                      [-5.675, -2.372],
                      [-0.623, -0.3],
                      [-0.421, -0.227],
                      [-0.335, -1.739],
                    ],
                    o: [
                      [0, 0],
                      [-0.366, -0.667],
                      [-0.31, -4.175],
                      [0.079, -0.492],
                      [0.091, -0.703],
                      [0, -3.427],
                      [4.994, 4.898],
                      [0, 0],
                      [0.277, 0.217],
                      [0.841, 0.704],
                      [3.917, 3.524],
                      [0.723, 0.302],
                      [0.473, 0.228],
                      [4.637, 2.504],
                      [0.32, 1.67],
                    ],
                    v: [
                      [24.793, 13.072],
                      [-24.56, 13.072],
                      [-25.208, 10.331],
                      [-23.534, -4.706],
                      [-23.334, -6.069],
                      [-23.186, -7.786],
                      [-20.152, -10.626],
                      [-6.202, -10.663],
                      [-5.765, -10.328],
                      [-4.482, -9.281],
                      [-0.862, -6.118],
                      [15.393, 2.105],
                      [17.41, 3.01],
                      [18.75, 3.695],
                      [25.198, 10.331],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [218.474, 265.599], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 98",
            ix: 98,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.609, 2.027],
                      [-6.967, 1.883],
                      [-7.609, -2.027],
                      [7.484, -2.027],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [203.115, 244.783], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 99",
            ix: 99,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.781, 7.654],
                      [-5.268, 7.654],
                      [-7.139, -3.743],
                      [-7.781, -7.654],
                      [7.312, -7.654],
                      [7.438, -3.6],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [203.286, 250.409], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 100",
            ix: 100,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.469, 11.843],
                      [-4.579, 11.843],
                      [-7.092, -3.466],
                      [-8.469, -11.843],
                      [7.742, -11.843],
                      [8.001, -3.466],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [202.598, 246.221], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 101",
            ix: 101,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.359, 0],
                      [0, 0],
                      [0, 0],
                      [-0.008, 0.365],
                      [-0.369, -0.015],
                      [0, 0],
                      [0.008, -0.366],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-0.366, -0.008],
                      [0.008, -0.365],
                      [0, 0],
                      [0.365, 0.008],
                      [-0.008, 0.36],
                    ],
                    v: [
                      [10.279, 0.89],
                      [10.264, 0.89],
                      [-10.292, 0.449],
                      [-10.94, -0.227],
                      [-10.264, -0.875],
                      [10.293, -0.433],
                      [10.94, 0.243],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [201.651, 223.949], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 102",
            ix: 102,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.043, 0],
                      [0.064, 0.314],
                      [0.72, 13.201],
                      [1.406, 1.243],
                      [5.987, -0.994],
                      [19.847, 4.619],
                      [4.864, 7.842],
                      [-0.039, 0.168],
                      [-0.355, -0.085],
                      [0.084, -0.357],
                      [-8.1, -13.05],
                      [-10.451, -2.431],
                      [-11.805, 1.96],
                      [-1.61, -1.423],
                      [-0.783, -14.349],
                      [-2.647, -13.105],
                      [0.358, -0.072],
                    ],
                    o: [
                      [-0.308, 0],
                      [-2.665, -13.2],
                      [-0.596, -10.947],
                      [-1.139, -1.007],
                      [-11.909, 1.977],
                      [-10.811, -2.515],
                      [-8.387, -13.522],
                      [0.084, -0.354],
                      [0.356, 0.084],
                      [-0.039, 0.162],
                      [4.672, 7.526],
                      [19.591, 4.559],
                      [7.07, -1.174],
                      [1.74, 1.538],
                      [0.717, 13.159],
                      [0.073, 0.358],
                      [-0.044, 0.009],
                    ],
                    v: [
                      [50.195, 55.705],
                      [49.547, 55.174],
                      [45.266, 12.344],
                      [42.7, -10.184],
                      [30.845, -9.411],
                      [-18.908, -9.065],
                      [-42.53, -24.673],
                      [-47.054, -55.129],
                      [-46.258, -55.62],
                      [-45.765, -54.824],
                      [-41.399, -25.36],
                      [-18.608, -10.354],
                      [30.628, -10.717],
                      [43.576, -11.176],
                      [46.588, 12.271],
                      [50.844, 54.912],
                      [50.327, 55.691],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [145.188, 168.787], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 103",
            ix: 103,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [1.435, 11.505],
                      [0, 0],
                      [30.838, 1.804],
                      [0, 0],
                      [-9.666, -1.523],
                      [-15.441, -6.662],
                      [-2.895, -1.785],
                      [-1.403, -9.156],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-1.437, -11.506],
                      [0, 0],
                      [-68.33, -3.991],
                      [2.309, 3.189],
                      [29.666, 2.848],
                      [3.718, 1.602],
                      [3.723, 2.295],
                      [4.219, 27.521],
                    ],
                    v: [
                      [68.055, 61.01],
                      [49.569, 61.01],
                      [43.856, 28.986],
                      [41.675, -9.799],
                      [-3.896, -6.817],
                      [-46.317, -61.01],
                      [-20.201, -58.509],
                      [50.634, -37.356],
                      [60.552, -32.271],
                      [68.007, -13.858],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [143.521, 173.368], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 104",
            ix: 104,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [2.013, 12.957],
                      [-0.752, 15.398],
                      [-13.69, 10.796],
                      [10.396, -49.728],
                    ],
                    o: [
                      [0, 0],
                      [-13.112, 0],
                      [-2.692, -17.325],
                      [0.849, -17.413],
                      [0, 0],
                      [3.97, 18.894],
                    ],
                    v: [
                      [36.956, 57.022],
                      [-4.432, 57.022],
                      [-33.599, 36.889],
                      [-36.205, -12.613],
                      [-13.35, -57.022],
                      [-15.479, 28.734],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [78.868, 112.105], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 105",
            ix: 105,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.146, 0],
                      [0.223, 1.662],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [1.812, 0.239],
                      [-0.239, 1.811],
                      [0, 0],
                      [-1.66, 0],
                      [0, 0],
                      [-0.221, -1.643],
                      [0, 0],
                      [1.811, -0.242],
                    ],
                    o: [
                      [-1.632, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.239, 1.812],
                      [-1.812, -0.239],
                      [0, 0],
                      [0.217, -1.645],
                      [0, 0],
                      [1.658, 0],
                      [0, 0],
                      [0.243, 1.811],
                      [-0.149, 0.02],
                    ],
                    v: [
                      [45.975, 56.322],
                      [42.7, 53.452],
                      [28.856, -49.913],
                      [-29.074, -49.913],
                      [-42.702, 53.445],
                      [-46.414, 56.293],
                      [-49.262, 52.579],
                      [-35.255, -53.655],
                      [-31.975, -56.531],
                      [31.751, -56.531],
                      [35.031, -53.661],
                      [49.258, 52.574],
                      [46.418, 56.292],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [102.53, 222.35], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 106",
            ix: 106,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.219, 0],
                      [0.285, 0.787],
                      [-1.004, 0.363],
                      [0, 0],
                      [-0.363, -1.004],
                      [1.004, -0.363],
                      [0, 0],
                    ],
                    o: [
                      [-0.791, 0],
                      [-0.363, -1.004],
                      [0, 0],
                      [1.004, -0.363],
                      [0.364, 1.004],
                      [0, 0],
                      [-0.217, 0.078],
                    ],
                    v: [
                      [-38.777, 16.101],
                      [-40.596, 14.826],
                      [-39.436, 12.35],
                      [38.118, -15.738],
                      [40.594, -14.579],
                      [39.436, -12.103],
                      [-38.12, 15.986],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [100.638, 213.186], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 107",
            ix: 107,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.792, 0],
                      [0.217, 0.078],
                      [0, 0],
                      [-0.363, 1.004],
                      [-1.005, -0.363],
                      [0, 0],
                      [0.363, -1.005],
                    ],
                    o: [
                      [-0.218, 0],
                      [0, 0],
                      [-1.004, -0.361],
                      [0.361, -1.005],
                      [0, 0],
                      [1.005, 0.362],
                      [-0.283, 0.787],
                    ],
                    v: [
                      [38.322, 15.874],
                      [37.666, 15.759],
                      [-38.979, -11.874],
                      [-40.141, -14.348],
                      [-37.667, -15.511],
                      [38.977, 12.122],
                      [40.14, 14.597],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [103.367, 213.413], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 108",
            ix: 108,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -4.868],
                      [79.993, 0],
                      [0, 4.868],
                      [-79.993, 0],
                    ],
                    o: [
                      [0, 4.868],
                      [-79.993, 0],
                      [0, -4.868],
                      [79.993, 0],
                    ],
                    v: [
                      [144.84, 0],
                      [0.001, 8.814],
                      [-144.84, 0],
                      [0.001, -8.814],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [145.09, 280.685], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 13,
      },
      {
        ty: 4,
        nm: "Girl.Hair",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Hair",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [38.038, 16.299, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [24.661, 6.863, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.341, 20.547],
                      [13.023, -2.232],
                      [-0.536, -17.512],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-9.104, 1.561],
                      [0.781, 25.49],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [17.947, -26.194],
                      [0.001, -35.818],
                      [-12.445, -5.401],
                      [-20.287, 35.448],
                      [-8.843, 38.049],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [20.538, 38.3], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 14,
        parent: 12,
      },
      {
        ty: 4,
        nm: "Girl.L.Arm",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "L Arm",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [14.758, 9.135, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [126.995, 34.533, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.052, -0.187],
                      [0, 0],
                      [0, 0],
                      [-2.475, -0.555],
                      [-2.819, -0.146],
                      [-3.005, 0.125],
                      [-3.119, 0.266],
                      [0, 0],
                      [-0.392, -4.6],
                      [4.275, -0.692],
                      [3.356, -0.389],
                      [3.563, -0.081],
                      [3.815, 0.554],
                      [3.86, 1.666],
                      [0.799, 2.364],
                      [0, 0],
                      [0, 0],
                      [-5.914, 1.972],
                      [-1.973, -5.914],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [1.933, 1.026],
                      [2.473, 0.557],
                      [2.822, 0.147],
                      [3.007, -0.125],
                      [0, 0],
                      [4.601, -0.392],
                      [0.374, 4.387],
                      [-3.217, 0.513],
                      [-3.355, 0.387],
                      [-3.561, 0.076],
                      [-3.808, -0.552],
                      [-2.471, -1.065],
                      [0, 0],
                      [0, 0],
                      [-1.973, -5.913],
                      [5.914, -1.974],
                      [0.062, 0.185],
                    ],
                    v: [
                      [-13.94, -29.683],
                      [0.691, 23.814],
                      [-4.524, 17.801],
                      [2.118, 20.173],
                      [10.109, 21.197],
                      [18.877, 21.192],
                      [28.076, 20.576],
                      [28.093, 20.574],
                      [37.134, 28.194],
                      [30.12, 37.162],
                      [20.275, 38.549],
                      [9.916, 39.309],
                      [-1.15, 38.699],
                      [-12.768, 35.488],
                      [-17.798, 30.033],
                      [-17.983, 29.477],
                      [-35.535, -23.133],
                      [-28.4, -37.411],
                      [-14.12, -30.277],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [37.758, 39.636], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 15,
        parent: 13,
      },
      {
        ty: 4,
        nm: "Man.L.Shoulder",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "L Shoulder",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [13.437, 14.178, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [77.183, 36.4, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [17],
                t: 60,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [17],
                t: 143,
              },
              { s: [0], t: 191.000007779589 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.306, 0],
                      [0.031, 0.005],
                      [0, 0],
                      [-0.049, 0.342],
                      [-0.343, -0.053],
                      [0, 0],
                      [0.05, -0.341],
                    ],
                    o: [
                      [-0.029, 0],
                      [0, 0],
                      [-0.342, -0.049],
                      [0.049, -0.341],
                      [0, 0],
                      [0.342, 0.05],
                      [-0.046, 0.312],
                    ],
                    v: [
                      [2.41, 0.998],
                      [2.319, 0.992],
                      [-2.5, 0.291],
                      [-3.029, -0.417],
                      [-2.32, -0.945],
                      [2.499, -0.245],
                      [3.028, 0.463],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [15.538, 34.224], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.197, 0],
                      [0.109, 0.076],
                      [0.122, 0.101],
                      [-0.221, 0.266],
                      [-0.266, -0.22],
                      [-6.799, -4.759],
                      [0.198, -0.282],
                    ],
                    o: [
                      [-0.123, 0],
                      [-6.842, -4.789],
                      [-0.266, -0.22],
                      [0.22, -0.265],
                      [0.121, 0.101],
                      [0.283, 0.198],
                      [-0.122, 0.174],
                    ],
                    v: [
                      [9.539, 8.137],
                      [9.182, 8.025],
                      [-9.948, -6.955],
                      [-10.029, -7.837],
                      [-9.149, -7.917],
                      [9.898, 7.001],
                      [10.052, 7.87],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [22.561, 37.999], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [2.154, 0.607],
                      [0.985, 0.426],
                      [0, 0],
                      [0, 0],
                      [0.189, 0.097],
                      [0.166, 0.093],
                      [1.149, 0.727],
                      [2.01, 1.454],
                      [1.898, 1.484],
                      [1.828, 1.519],
                      [1.795, 1.535],
                      [1.764, 1.557],
                      [1.731, 1.592],
                      [0, 0],
                      [0, 0],
                      [0.455, 0.443],
                      [0, 0],
                      [0, 0],
                      [-1.668, -1.523],
                      [-1.668, -1.5],
                      [-1.663, -1.462],
                      [-1.65, -1.372],
                      [-1.54, -1.144],
                      [-0.603, -0.392],
                      [0, 0],
                      [0, 0],
                      [-0.019, -0.02],
                      [-0.026, -0.036],
                      [-0.068, -0.055],
                      [-0.29, 0.019],
                    ],
                    o: [
                      [-2.241, 0.052],
                      [-1.078, -0.302],
                      [0, 0],
                      [0, 0],
                      [-0.21, -0.102],
                      [-0.182, -0.095],
                      [-1.401, -0.759],
                      [-2.281, -1.453],
                      [-1.999, -1.46],
                      [-1.894, -1.483],
                      [-1.831, -1.515],
                      [-1.788, -1.541],
                      [-1.755, -1.568],
                      [0, 0],
                      [0, 0],
                      [-0.435, -0.411],
                      [0, 0],
                      [0, 0],
                      [1.656, 1.547],
                      [1.661, 1.531],
                      [1.671, 1.496],
                      [1.661, 1.46],
                      [1.64, 1.376],
                      [0.763, 0.567],
                      [0, 0],
                      [0, 0],
                      [0.035, 0.023],
                      [0.064, 0.059],
                      [0.053, 0.071],
                      [0.065, 0.057],
                      [0, 0],
                    ],
                    v: [
                      [27.171, 27.688],
                      [20.46, 26.834],
                      [17.357, 25.732],
                      [16.636, 25.408],
                      [16.03, 25.111],
                      [15.46, 24.821],
                      [14.914, 24.533],
                      [11.162, 22.316],
                      [4.819, 17.968],
                      [-0.997, 13.545],
                      [-6.583, 9.05],
                      [-12.024, 4.481],
                      [-17.351, -0.166],
                      [-22.585, -4.898],
                      [-25.172, -7.299],
                      [-26.456, -8.519],
                      [-27.759, -9.769],
                      [-10.868, -27.74],
                      [-0.505, -18.74],
                      [4.483, -14.135],
                      [9.472, -9.58],
                      [14.465, -5.126],
                      [19.446, -0.863],
                      [24.273, 2.988],
                      [26.38, 4.467],
                      [26.78, 4.723],
                      [26.944, 4.826],
                      [27.003, 4.877],
                      [27.133, 5.012],
                      [27.247, 5.193],
                      [27.759, 5.271],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [33.079, 32.365], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.783],
                      [6.782, 0],
                      [0, 6.783],
                      [-6.783, 0],
                    ],
                    o: [
                      [0, 6.783],
                      [-6.783, 0],
                      [0, -6.783],
                      [6.782, 0],
                    ],
                    v: [
                      [12.282, -0.001],
                      [0.001, 12.281],
                      [-12.282, -0.001],
                      [0.001, -12.281],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.531, 12.532], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.33],
                      [6.33, 0],
                      [0, 6.33],
                      [-6.33, 0],
                    ],
                    o: [
                      [0, 6.33],
                      [-6.33, 0],
                      [0, -6.33],
                      [6.33, 0],
                    ],
                    v: [
                      [11.462, 0],
                      [0, 11.462],
                      [-11.462, 0],
                      [0, -11.462],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [58.162, 48.597], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 16,
        parent: 24,
      },
      {
        ty: 4,
        nm: "Man.L.Hand",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "L Hand",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [13.132, 39.987, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [47.156, 13.63, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 43,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [30],
                t: 71,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [30],
                t: 179,
              },
              { s: [0], t: 202.000008227629 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.084, 0.014],
                      [-0.065, 0.172],
                      [0.257, 0.098],
                      [0.457, 1.199],
                      [-0.021, 0.043],
                      [0.243, 0.127],
                      [0.127, -0.243],
                      [-1.329, -3.488],
                      [-1.661, -0.628],
                    ],
                    o: [
                      [0.169, -0.028],
                      [0.097, -0.257],
                      [-1.384, -0.522],
                      [-1.172, -3.075],
                      [0.128, -0.243],
                      [-0.243, -0.127],
                      [-0.099, 0.191],
                      [0.562, 1.476],
                      [0.086, 0.031],
                    ],
                    v: [
                      [2.229, 5.857],
                      [2.612, 5.542],
                      [2.323, 4.901],
                      [-0.452, 2.307],
                      [0.593, -5.074],
                      [0.383, -5.744],
                      [-0.288, -5.534],
                      [-1.38, 2.661],
                      [1.971, 5.832],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [13.283, 28.789], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.271, -0.03],
                      [-1.873, 0.313],
                      [-0.685, 0.603],
                      [-0.017, 0.744],
                      [1.617, 0.642],
                      [0, 0],
                      [0.299, -0.009],
                      [0.049, -2.138],
                      [0.353, -0.312],
                      [1.196, 0.125],
                      [0.027, -0.274],
                    ],
                    o: [
                      [0.126, 0.014],
                      [1.037, -0.173],
                      [0.564, -0.498],
                      [0.057, -2.638],
                      [-0.082, 0.531],
                      [0, 0],
                      [1.361, 0.509],
                      [-0.009, 0.459],
                      [-1.323, 1.166],
                      [-0.272, -0.03],
                      [-0.03, 0.272],
                    ],
                    v: [
                      [-3.567, 3.812],
                      [0.361, 3.708],
                      [3.105, 2.601],
                      [3.981, 0.731],
                      [-0.279, -4.02],
                      [-0.404, -3.186],
                      [-0.879, -3.182],
                      [2.988, 0.709],
                      [2.449, 1.855],
                      [-3.464, 2.825],
                      [-4.009, 3.266],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [8.412, 21.344], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.005, -0.002],
                      [-0.016, -0.203],
                      [-0.24, -0.334],
                      [-0.137, 0.005],
                      [-0.316, 0.181],
                      [-0.865, 0.869],
                      [0.069, -0.082],
                      [0.38, -0.838],
                      [0.098, -0.056],
                      [0.28, -0.071],
                      [0.114, -0.004],
                      [0.345, 0.479],
                      [0.066, 0.808],
                      [-0.33, -0.096],
                    ],
                    o: [
                      [0.196, 0.056],
                      [0.051, 0.646],
                      [0.193, 0.262],
                      [0.22, -0.01],
                      [0.786, -0.449],
                      [-0.008, 0.098],
                      [-0.612, 0.698],
                      [-0.098, 0.065],
                      [-0.315, 0.178],
                      [-0.125, 0.03],
                      [-0.544, 0.023],
                      [-0.363, -0.504],
                      [-0.027, -0.342],
                      [0.005, 0.002],
                    ],
                    v: [
                      [-2.056, -1.509],
                      [-1.701, -1.081],
                      [-1.264, 0.459],
                      [-0.753, 0.746],
                      [0.064, 0.446],
                      [2.723, -1.762],
                      [2.605, -1.488],
                      [0.846, 1.118],
                      [0.548, 1.3],
                      [-0.354, 1.686],
                      [-0.71, 1.739],
                      [-2.068, 1.04],
                      [-2.696, -1.008],
                      [-2.072, -1.514],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [14.02, 21.097], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.323, 0.727],
                      [-0.249, -0.885],
                      [-0.517, -0.164],
                      [-0.515, 0.087],
                      [-1.046, 0.511],
                      [0, 0],
                      [-0.454, 0.334],
                      [0.085, -0.048],
                      [0.984, 0.314],
                      [0.091, 0.316],
                      [-1.039, 1.204],
                      [0.173, 0.185],
                      [0.202, -0.151],
                      [1.43, 0.455],
                      [0.012, 0.052],
                      [-0.186, 0.412],
                      [-0.612, 0.699],
                      [-0.009, 0.098],
                      [0.127, 0.111],
                      [0.184, -0.207],
                      [0.57, -1.155],
                      [0.054, -0.393],
                      [-0.059, -0.213],
                      [-0.593, -0.191],
                    ],
                    o: [
                      [-0.507, 1.138],
                      [0.161, 0.57],
                      [0.355, 0.114],
                      [1.287, -0.215],
                      [0.058, -0.603],
                      [0, 0],
                      [-0.092, -0.006],
                      [-1.623, 0.891],
                      [-0.126, -0.042],
                      [-0.273, -0.971],
                      [0.168, -0.193],
                      [-0.173, -0.19],
                      [-0.989, 0.73],
                      [-0.208, -0.067],
                      [-0.066, -0.229],
                      [0.38, -0.838],
                      [0.07, -0.082],
                      [0.017, -0.158],
                      [-0.207, -0.181],
                      [-0.273, 0.312],
                      [-0.207, 0.42],
                      [-0.037, 0.249],
                      [0.085, 0.301],
                      [1.265, 0.399],
                    ],
                    v: [
                      [-0.662, 0.054],
                      [-1.184, 3.317],
                      [-0.149, 4.441],
                      [1.185, 4.46],
                      [5.12, 3.033],
                      [5.184, 2.072],
                      [5.969, 1.553],
                      [5.698, 1.617],
                      [0.15, 3.494],
                      [-0.229, 3.046],
                      [1.265, -1.191],
                      [1.256, -1.851],
                      [0.596, -1.915],
                      [-4.698, 0.146],
                      [-4.946, -0.068],
                      [-4.731, -1.066],
                      [-2.973, -3.673],
                      [-2.853, -3.946],
                      [-3.018, -4.373],
                      [-3.723, -4.326],
                      [-5.513, -1.738],
                      [-5.932, -0.497],
                      [-5.905, 0.201],
                      [-5.001, 1.094],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [20.227, 23.717], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-5.987, 2.388],
                      [-0.234, 2.514],
                      [0, 0],
                      [-0.454, 0.333],
                      [0.192, 1.853],
                      [0, 0],
                      [1.417, 0.937],
                      [0, 0],
                      [2.118, 0.944],
                      [0.131, -0.316],
                      [2.53, -0.756],
                      [0.513, -3.35],
                      [0, 0],
                      [0.299, -0.009],
                      [0.763, -2.908],
                    ],
                    o: [
                      [0, 0],
                      [4.833, -1.929],
                      [0.056, -0.601],
                      [0, 0],
                      [1.064, -0.778],
                      [-0.272, -2.641],
                      [0, 0],
                      [-1.393, -0.921],
                      [0, 0],
                      [-3.186, -1.417],
                      [0.035, -0.348],
                      [-2.208, 0.659],
                      [-0.082, 0.53],
                      [0, 0],
                      [-1.419, 0.041],
                      [-0.925, 3.513],
                    ],
                    v: [
                      [-7.472, 13.902],
                      [4.578, 17.637],
                      [10.716, 6.473],
                      [10.78, 5.514],
                      [11.563, 4.994],
                      [14.19, 0.786],
                      [9.124, -0.383],
                      [8.433, -4.511],
                      [4.183, -3.801],
                      [3.612, -8.653],
                      [-0.764, -4.95],
                      [-1.743, -19.27],
                      [-6.498, -2.951],
                      [-6.623, -2.118],
                      [-7.099, -2.114],
                      [-13.457, 1.21],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [14.632, 20.276], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -4.451],
                      [4.451, 0],
                      [0, 4.45],
                      [-4.45, 0],
                    ],
                    o: [
                      [0, 4.45],
                      [-4.45, 0],
                      [0, -4.451],
                      [4.451, 0],
                    ],
                    v: [
                      [8.059, 0],
                      [-0.001, 8.059],
                      [-8.059, 0],
                      [-0.001, -8.059],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [13.325, 34.666], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 17,
        parent: 18,
      },
      {
        ty: 4,
        nm: "Man.L.Arm",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "L Arm",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [9.829, 44.702, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [56.779, 49.653, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-47],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-19],
                t: 60,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-19],
                t: 143,
              },
              { s: [-47], t: 191.000007779589 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.259, 0.134],
                      [-0.036, 0.031],
                      [0.226, 0.269],
                      [0.424, 2.081],
                      [0.345, -0.07],
                      [-0.07, -0.345],
                      [-0.081, -0.097],
                    ],
                    o: [
                      [0.04, -0.021],
                      [0.27, -0.225],
                      [-0.017, -0.022],
                      [-0.07, -0.345],
                      [-0.343, 0.07],
                      [0.49, 2.401],
                      [0.195, 0.233],
                    ],
                    v: [
                      [1.458, 2.822],
                      [1.573, 2.744],
                      [1.652, 1.848],
                      [-0.561, -2.389],
                      [-1.312, -2.886],
                      [-1.808, -2.135],
                      [0.677, 2.666],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [15.111, 37.193], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.118, 0.061],
                      [-0.034, 0.206],
                      [-1.183, 2.322],
                      [0.314, 0.16],
                      [0.159, -0.313],
                      [0.028, -0.171],
                      [-0.348, -0.058],
                    ],
                    o: [
                      [0.172, -0.089],
                      [0.007, -0.04],
                      [0.16, -0.314],
                      [-0.313, -0.159],
                      [-1.274, 2.499],
                      [-0.058, 0.347],
                      [0.14, 0.023],
                    ],
                    v: [
                      [-0.687, 3.864],
                      [-0.352, 3.403],
                      [1.506, -2.934],
                      [1.228, -3.791],
                      [0.372, -3.512],
                      [-1.608, 3.195],
                      [-1.083, 3.927],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [13.122, 37.583], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.281, -0.541],
                      [-0.54, 0.281],
                      [0.281, 0.541],
                      [0.541, -0.281],
                    ],
                    o: [
                      [0.281, 0.541],
                      [0.541, -0.281],
                      [-0.281, -0.541],
                      [-0.541, 0.281],
                    ],
                    v: [
                      [-0.979, 0.509],
                      [0.508, 0.979],
                      [0.979, -0.509],
                      [-0.509, -0.979],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [42.216, 11.95], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.163, 0.085],
                      [-0.061, 0.141],
                      [0, 0],
                      [0.324, 0.138],
                      [0.138, -0.323],
                      [0, 0],
                      [-0.323, -0.139],
                    ],
                    o: [
                      [0.127, -0.066],
                      [0, 0],
                      [0.139, -0.323],
                      [-0.323, -0.139],
                      [0, 0],
                      [-0.138, 0.323],
                      [0.183, 0.078],
                    ],
                    v: [
                      [-1.2, 4.083],
                      [-0.908, 3.769],
                      [2.079, -3.208],
                      [1.744, -4.043],
                      [0.908, -3.709],
                      [-2.079, 3.268],
                      [-1.744, 4.104],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [47.789, 15.084], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.049, 0.025],
                      [0.063, 0.287],
                      [0.344, -0.074],
                      [0.044, 0.087],
                      [0.314, -0.16],
                      [0.002, -0.001],
                      [-0.159, -0.311],
                      [-10.026, 2.184],
                    ],
                    o: [
                      [0.249, -0.129],
                      [-0.075, -0.344],
                      [-9.076, 1.978],
                      [-0.16, -0.314],
                      [-0.002, 0.001],
                      [-0.309, 0.161],
                      [0.049, 0.097],
                      [0.056, -0.012],
                    ],
                    v: [
                      [7.487, 3.076],
                      [7.814, 2.376],
                      [7.056, 1.889],
                      [-6.583, -4.878],
                      [-7.44, -5.157],
                      [-7.445, -5.154],
                      [-7.719, -4.301],
                      [7.327, 3.133],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [43.768, 15.924], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -4.451],
                      [4.451, 0],
                      [0, 4.45],
                      [-4.45, 0],
                    ],
                    o: [
                      [0, 4.45],
                      [-4.45, 0],
                      [0, -4.451],
                      [4.451, 0],
                    ],
                    v: [
                      [8.059, 0],
                      [-0.001, 8.059],
                      [-8.059, 0],
                      [-0.001, -8.059],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [47.349, 8.309], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.33],
                      [6.33, 0],
                      [0, 6.33],
                      [-6.33, 0],
                    ],
                    o: [
                      [0, 6.33],
                      [-6.33, 0],
                      [0, -6.33],
                      [6.33, 0],
                    ],
                    v: [
                      [11.462, 0],
                      [0, 11.462],
                      [-11.462, 0],
                      [0, -11.462],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.712, 44.647], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-2.241, 0.052],
                      [-1.941, 0.575],
                      [-1.565, 0.843],
                      [-1.273, 0.963],
                      [-1.871, 2.078],
                      [-1.502, 2.137],
                      [-2.225, 4.412],
                      [-1.689, 4.591],
                      [3.595, 4.343],
                      [2.461, -3.551],
                      [2.686, -3.011],
                      [1.362, -1.226],
                      [1.244, -0.718],
                      [0.577, -0.036],
                      [0.065, 0.057],
                      [0.053, 0.071],
                      [0.063, 0.059],
                      [0.035, 0.024],
                      [0, 0],
                    ],
                    o: [
                      [2.154, 0.608],
                      [2.247, -0.042],
                      [1.942, -0.578],
                      [1.568, -0.845],
                      [2.542, -1.933],
                      [1.869, -2.076],
                      [2.997, -4.277],
                      [2.202, -4.425],
                      [-5.989, 0.721],
                      [-2.223, 3.777],
                      [-2.447, 3.555],
                      [-1.342, 1.501],
                      [-1.364, 1.218],
                      [-1.241, 0.721],
                      [-0.29, 0.019],
                      [-0.068, -0.055],
                      [-0.027, -0.035],
                      [-0.02, -0.019],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-23.765, 24.445],
                      [-17.054, 25.3],
                      [-10.719, 24.3],
                      [-5.472, 22.114],
                      [-1.242, 19.368],
                      [5.253, 13.303],
                      [10.24, 6.954],
                      [17.925, -6.102],
                      [23.765, -19.58],
                      [8.665, -25.352],
                      [2.242, -15.283],
                      [-5.485, -5.33],
                      [-9.577, -1.221],
                      [-13.56, 1.756],
                      [-16.466, 2.883],
                      [-16.978, 2.804],
                      [-17.092, 2.624],
                      [-17.222, 2.488],
                      [-17.281, 2.437],
                      [-17.983, 2.596],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [30.854, 30.802], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 18,
        parent: 16,
      },
      {
        ty: 4,
        nm: "Man.Eyebrows",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Eyebrows",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [13.315, 3.755, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [19.147, 31.549, 0],
                t: 0,
                ti: [0.007, -0.083, 0],
                to: [-0.007, 0.083, 0],
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [19.104, 32.047, 0],
                t: 25.638,
                ti: [0, 0, 0],
                to: [0, 0, 0],
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [19.104, 32.047, 0],
                t: 119.645,
                ti: [0.018, 0.166, 0],
                to: [-0.004, -0.25, 0],
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [19.081, 30.547, 0],
                t: 136.738,
                ti: [0.014, -0.084, 0],
                to: [-0.018, -0.166, 0],
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [18.998, 31.053, 0],
                t: 170.923,
                ti: [0, 0, 0],
                to: [0, 0, 0],
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [18.998, 31.053, 0],
                t: 226.473,
                ti: [-0.025, -0.083, 0],
                to: [0.025, 0.083, 0],
              },
              { s: [19.147, 31.549, 0], t: 241.000009816131 },
            ],
            ix: 2,
          },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.419, -0.807],
                      [2.845, 2.268],
                      [-2.178, -0.187],
                    ],
                    o: [
                      [0, 0],
                      [-2.844, -2.269],
                      [2.179, 0.186],
                    ],
                    v: [
                      [4.523, -0.054],
                      [-3.098, 1.236],
                      [2.861, -3.317],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [6.192, 3.755], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.42, -0.807],
                      [-2.844, 2.268],
                      [2.179, -0.187],
                    ],
                    o: [
                      [0, 0],
                      [2.845, -2.269],
                      [-2.178, 0.186],
                    ],
                    v: [
                      [-4.522, -0.054],
                      [3.098, 1.236],
                      [-2.862, -3.317],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [20.438, 3.755], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 19,
        parent: 22,
      },
      {
        ty: 4,
        nm: "Man.Eyes",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Eyes",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [6.859, 2.232, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [17.9, 36.739, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.095],
                      [-0.612, 0],
                      [0, 1.094],
                      [0.614, 0],
                    ],
                    o: [
                      [0, 1.094],
                      [0.614, 0],
                      [0, -1.095],
                      [-0.612, 0],
                    ],
                    v: [
                      [-1.109, 0],
                      [-0.001, 1.982],
                      [1.109, 0],
                      [-0.001, -1.982],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [1.359, 2.232], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.095],
                      [-0.612, 0],
                      [0, 1.094],
                      [0.613, 0],
                    ],
                    o: [
                      [0, 1.094],
                      [0.613, 0],
                      [0, -1.095],
                      [-0.612, 0],
                    ],
                    v: [
                      [-1.109, 0],
                      [-0.001, 1.982],
                      [1.109, 0],
                      [-0.001, -1.982],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.359, 2.232], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 20,
        parent: 22,
      },
      {
        ty: 4,
        nm: "Man.Mouse",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Mouse",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [20.02, 47.347, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [87, 87, 100],
                t: 20,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 40,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 95,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [87, 87, 100],
                t: 115,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 135,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 188,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [87, 87, 100],
                t: 208,
              },
              { s: [100, 100, 100], t: 228.00000928663 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [20.02, 51.347, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.509, 0.315],
                            [-5.171, 1.305],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [4.023, -1.015],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.812, -5.213],
                            [-4.993, 1.874],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [3.805, -1.428],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 20,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.41, -0.918],
                            [-4.993, 1.874],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [3.805, -1.428],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 40,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.509, 0.315],
                            [-5.171, 1.305],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [4.023, -1.015],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 95,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.812, -5.213],
                            [-4.993, 1.874],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [3.805, -1.428],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 115,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.41, -0.918],
                            [-4.993, 1.874],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [3.805, -1.428],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 135,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.509, 0.315],
                            [-5.171, 1.305],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [4.023, -1.015],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 188,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.812, -5.213],
                            [-4.993, 1.874],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [3.805, -1.428],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 208,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [3.41, -0.918],
                            [-4.993, 1.874],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [3.805, -1.428],
                          ],
                          v: [
                            [5.207, -3.127],
                            [-5.25, -2.043],
                            [1.227, 1.822],
                          ],
                        },
                      ],
                      t: 228.00000928663,
                    },
                  ],
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 0,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [75, 100],
                      t: 20,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 40,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 95,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [75, 100],
                      t: 116,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 135,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 188,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [75, 100],
                      t: 209,
                    },
                    { s: [100, 100], t: 228.00000928663 },
                  ],
                  ix: 3,
                },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [20.041, 47.893], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 21,
        parent: 22,
      },
      {
        ty: 4,
        nm: "Man.Head",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Head",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [36.695, 54.674, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [60.389, 5.83, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-5],
                t: 22.773,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-5],
                t: 44.673,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [1],
                t: 141.898,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [9],
                t: 166.423,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [4],
                t: 181.315,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [1],
                t: 227.738,
              },
              { s: [0], t: 240.0000097754 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-2.669, 0],
                      [0, 0.249],
                      [0.249, 0],
                      [0.256, 0.378],
                      [-0.018, 0.038],
                      [0.225, 0.108],
                      [0.107, -0.225],
                      [1.831, -3.771],
                      [-0.058, -0.123],
                    ],
                    o: [
                      [0.249, 0],
                      [0, -0.249],
                      [-1.657, 0],
                      [1.795, -3.703],
                      [0.108, -0.224],
                      [-0.224, -0.107],
                      [-0.019, 0.038],
                      [-0.059, 0.122],
                      [0.036, 0.079],
                    ],
                    v: [
                      [1.323, 5.167],
                      [1.772, 4.718],
                      [1.323, 4.268],
                      [-1.366, 3.014],
                      [2.233, -4.459],
                      [2.021, -5.061],
                      [1.422, -4.847],
                      [-2.281, 2.838],
                      [-2.284, 3.225],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [17.413, 37.082], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.1, 0],
                      [-0.089, 0.11],
                      [0.193, 0.156],
                      [1.352, -0.346],
                      [-0.061, -0.241],
                      [-0.241, 0.062],
                      [-0.392, -0.317],
                    ],
                    o: [
                      [0.131, 0],
                      [0.157, -0.192],
                      [-0.181, -0.147],
                      [-0.242, 0.062],
                      [0.063, 0.241],
                      [0.778, -0.199],
                      [0.083, 0.068],
                    ],
                    v: [
                      [1.371, 1.109],
                      [1.721, 0.942],
                      [1.656, 0.31],
                      [-1.491, -0.763],
                      [-1.817, -0.215],
                      [-1.268, 0.11],
                      [1.088, 1.008],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [41.343, 44.171], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.017, 0],
                      [-0.025, 0.231],
                      [-0.158, 0.064],
                      [0.093, 0.23],
                      [0.232, -0.093],
                      [0.547, -5],
                      [-0.247, -0.028],
                    ],
                    o: [
                      [0.227, 0],
                      [0.487, -4.45],
                      [0.231, -0.093],
                      [-0.092, -0.231],
                      [-0.178, 0.071],
                      [-0.028, 0.247],
                      [0.017, 0.001],
                    ],
                    v: [
                      [-2.343, 3.697],
                      [-1.896, 3.297],
                      [2.476, -2.768],
                      [2.726, -3.354],
                      [2.141, -3.604],
                      [-2.791, 3.199],
                      [-2.393, 3.696],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [40.855, 44.312], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-3.206, -4.62],
                      [5.229, 3.399],
                    ],
                    o: [
                      [0, 0],
                      [2.643, 3.807],
                      [0, 0],
                    ],
                    v: [
                      [-5.113, -3.295],
                      [2.47, -4.554],
                      [-5.113, 5.775],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [43.027, 44.005], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.901, 1.823],
                      [-2.072, -0.979],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0.052, 0.121],
                      [0.007, -0.007],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-1.849, -2.221],
                      [1.715, 2.221],
                      [1.725, 2.21],
                      [1.849, -0.091],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [37.758, 50.534], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-0.305, 0],
                      [0, 0.797],
                      [2.022, 0],
                      [0.256, -0.023],
                    ],
                    o: [
                      [0.282, 0.028],
                      [2.022, 0],
                      [0, -0.797],
                      [-0.275, 0],
                      [0, 0],
                    ],
                    v: [
                      [-2.274, 1.401],
                      [-1.39, 1.443],
                      [2.274, 0.001],
                      [-1.39, -1.443],
                      [-2.187, -1.409],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [11.214, 41.125], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -0.797],
                      [-2.025, 0],
                      [0, 0.797],
                      [2.024, 0],
                    ],
                    o: [
                      [0, 0.797],
                      [2.024, 0],
                      [0, -0.797],
                      [-2.025, 0],
                    ],
                    v: [
                      [-3.666, 0],
                      [0.001, 1.443],
                      [3.666, 0],
                      [0.001, -1.443],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.544, 41.126], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.112, -0.142],
                      [-2.782, -1.222],
                      [-3.976, 2.032],
                      [1.355, -1.856],
                      [-1.502, -2.366],
                      [-2.4, -1.856],
                      [0.409, -1.089],
                      [-1.247, 0.232],
                      [0, 0],
                      [0, 0],
                      [10.922, 2.096],
                      [3.776, -4.163],
                    ],
                    o: [
                      [1.787, -0.633],
                      [4.025, 1.768],
                      [3.976, -2.032],
                      [-1.353, 1.855],
                      [1.502, 2.364],
                      [1.012, 0.781],
                      [-0.446, 1.187],
                      [1.294, -0.329],
                      [0, 0],
                      [0, 0],
                      [-7.981, -1.532],
                      [-0.122, 0.135],
                    ],
                    v: [
                      [-15.257, -6.278],
                      [-9.398, -5.1],
                      [3.101, -6.954],
                      [8.107, -4.303],
                      [8.402, 1.42],
                      [7.96, 6.74],
                      [8.502, 9.778],
                      [10.346, 11.947],
                      [13.467, 9.324],
                      [13.81, 2.941],
                      [4.335, -10.648],
                      [-14.905, -6.691],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [26.786, 29.178], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 10",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.416, -0.037],
                      [-0.069, 0.06],
                      [0.18, 0.211],
                      [0.21, -0.18],
                      [1.85, 0.823],
                      [0.112, -0.255],
                      [-0.253, -0.113],
                    ],
                    o: [
                      [1.823, 0.164],
                      [0.211, -0.181],
                      [-0.182, -0.21],
                      [-0.074, 0.062],
                      [-0.254, -0.112],
                      [-0.114, 0.254],
                      [0.454, 0.202],
                    ],
                    v: [
                      [-0.906, 0.912],
                      [2.349, -0.132],
                      [2.403, -0.842],
                      [1.694, -0.896],
                      [-1.805, -0.352],
                      [-2.469, -0.096],
                      [-2.215, 0.568],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [21.218, 51.669], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 11",
            ix: 10,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-11.387, 0.025],
                      [-0.402, 0.411],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [10.922, 2.096],
                      [3.776, -4.163],
                      [0.113, -0.142],
                      [0.203, -2.104],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [11.317, -0.022],
                      [0.007, -0.007],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-7.98, -1.532],
                      [-0.121, 0.135],
                      [-1.188, 1.463],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-16.675, 11.603],
                      [-5.305, 22.525],
                      [14.124, 13.206],
                      [14.135, 13.195],
                      [14.26, 10.895],
                      [14.902, -1.047],
                      [15.247, -7.43],
                      [5.771, -21.018],
                      [-13.469, -17.062],
                      [-13.822, -16.648],
                      [-15.969, -11.296],
                      [-16.322, 0.168],
                      [-16.408, 2.978],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [25.35, 39.549], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 12",
            ix: 11,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [1.699, -3.553],
                      [-4.577, 1.177],
                    ],
                    o: [
                      [0, 0],
                      [-1.7, 3.553],
                      [0, 0],
                    ],
                    v: [
                      [3.203, -3.596],
                      [-1.503, -3.596],
                      [2.681, 5.971],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [8.677, 42.288], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 13",
            ix: 12,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [4.277, 2.768],
                      [0, 0],
                      [2.265, 1.51],
                      [0, 0],
                      [4.529, -2.013],
                      [0, 0],
                      [-0.754, -5.788],
                      [0, 0],
                      [-1.006, -5.285],
                      [0, 0],
                      [0, 0],
                      [-1.509, 7.297],
                      [0, 0],
                    ],
                    o: [
                      [-3.885, -2.514],
                      [0, 0],
                      [-5.15, -3.433],
                      [0, 0],
                      [-4.531, 2.014],
                      [0, 0],
                      [0.755, 5.788],
                      [0, 0],
                      [1.007, 5.284],
                      [0, 0],
                      [0, 0],
                      [1.51, -7.299],
                      [0, 0],
                    ],
                    v: [
                      [17.617, -5.914],
                      [1.51, -9.689],
                      [-2.768, -16.483],
                      [-8.556, -12.96],
                      [-18.118, -17.993],
                      [-16.862, -9.436],
                      [-22.901, -4.908],
                      [-15.603, 3.397],
                      [-16.609, 11.954],
                      [-13.337, 17.491],
                      [14.848, 20.007],
                      [22.146, 12.71],
                      [18.622, 2.392],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [23.905, 20.257], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 22,
        parent: 24,
      },
      {
        ty: 4,
        nm: "Mask",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        td: 1,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [374, 251, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Rectangle 1",
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "rc",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Rect",
                nm: "Rectangle Path 1",
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 0, ix: 4 },
                s: { a: 0, k: [319.477, 81.77], ix: 2 },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2235], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.1686, 0.1686, 0.1686], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [81.238, 91.885], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 23,
      },
      {
        ty: 4,
        nm: "Man.Body",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "Body",
        ddd: 0,
        bm: 0,
        tt: 2,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [48.623, 117.085, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [467.45, 240.403, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.371, 0],
                      [0, 0.371],
                      [0, 0],
                      [0.37, 0],
                      [0, -0.371],
                      [0, 0],
                    ],
                    o: [
                      [0.37, 0],
                      [0, 0],
                      [0, -0.371],
                      [-0.371, 0],
                      [0, 0],
                      [0, 0.371],
                    ],
                    v: [
                      [0, 2.17],
                      [0.671, 1.499],
                      [0.671, -1.499],
                      [0, -2.17],
                      [-0.671, -1.499],
                      [-0.671, 1.499],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.049, 24.099], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.029, 0],
                      [-0.119, 0.095],
                      [-0.208, 0.506],
                      [0.343, 0.14],
                      [0.14, -0.343],
                      [5.306, -4.719],
                      [0, 0],
                      [0.215, -0.005],
                      [0.124, -0.176],
                      [0, 0],
                      [2.775, 5.584],
                      [0.332, -0.166],
                      [-0.165, -0.333],
                      [-0.043, -0.077],
                      [-0.231, -0.012],
                      [-0.133, 0.189],
                      [0, 0],
                      [0, 0],
                      [-0.182, -0.024],
                    ],
                    o: [
                      [0.151, 0],
                      [5.798, -4.623],
                      [0.141, -0.343],
                      [-0.344, -0.141],
                      [-0.048, 0.116],
                      [0, 0],
                      [-0.128, -0.174],
                      [-0.216, 0.002],
                      [0, 0],
                      [-1.254, -2.266],
                      [-0.164, -0.332],
                      [-0.332, 0.165],
                      [3.502, 7.047],
                      [0.113, 0.201],
                      [0.229, 0.013],
                      [0, 0],
                      [0, 0],
                      [0.108, 0.147],
                      [0.029, 0.004],
                    ],
                    v: [
                      [7.61, 9.113],
                      [8.028, 8.967],
                      [18.969, -8.109],
                      [18.602, -8.984],
                      [17.727, -8.617],
                      [7.725, 7.467],
                      [-1.093, -4.51],
                      [-1.64, -4.783],
                      [-2.183, -4.499],
                      [-10.411, 7.181],
                      [-17.744, -6.693],
                      [-18.643, -6.995],
                      [-18.946, -6.095],
                      [-11.063, 8.77],
                      [-10.511, 9.112],
                      [-9.928, 8.828],
                      [-1.622, -2.964],
                      [7.068, 8.84],
                      [7.521, 9.107],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [52.682, 29.71], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [5.734, -0.718],
                      [-0.122, 7.988],
                      [-5.734, -0.718],
                      [-0.122, -7.988],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.156, 33.585], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [2.273, -2.59],
                      [0.087, 0.659],
                      [-2.118, -2.762],
                      [-2.502, 0.16],
                      [0.053, 2.762],
                      [2.502, -0.327],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [50.947, 40.914], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-4.562, -5.312],
                      [-2.955, -1.427],
                      [3.784, 5.312],
                      [4.562, 3.811],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [47.028, 127.473], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-7.616, -6.222],
                      [6.841, 8.234],
                      [7.616, 6.733],
                      [-7.35, -8.234],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [46.511, 119.652], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-8.451, -7.059],
                      [7.676, 9.07],
                      [8.451, 7.569],
                      [-8.186, -9.07],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [48.213, 113.916], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-9.288, -7.89],
                      [8.51, 9.904],
                      [9.288, 8.406],
                      [-9.022, -9.904],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [49.917, 108.179], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-9.664, -8.659],
                      [9.664, 10.669],
                      [9.406, 8.134],
                      [-9.398, -10.669],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.16, 102.374], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 10",
            ix: 10,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-8.809, -7.802],
                      [8.809, 9.815],
                      [8.551, 7.279],
                      [-8.543, -9.815],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.172, 94.948], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 11",
            ix: 11,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-7.953, -6.949],
                      [7.953, 8.96],
                      [7.697, 6.424],
                      [-7.688, -8.96],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.184, 87.522], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 12",
            ix: 12,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-7.098, -6.093],
                      [7.098, 8.104],
                      [6.84, 5.57],
                      [-6.833, -8.104],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.196, 80.097], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 13",
            ix: 13,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-6.243, -5.238],
                      [6.243, 7.249],
                      [5.985, 4.714],
                      [-5.978, -7.249],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.208, 72.67], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 14",
            ix: 14,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-5.388, -4.383],
                      [5.388, 6.394],
                      [5.132, 3.859],
                      [-5.122, -6.394],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.221, 65.245], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 15",
            ix: 15,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-4.533, -3.526],
                      [4.533, 5.54],
                      [4.276, 3.003],
                      [-4.267, -5.54],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.232, 57.819], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 16",
            ix: 16,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-3.678, -2.674],
                      [3.678, 4.685],
                      [3.421, 2.148],
                      [-3.412, -4.685],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.244, 50.394], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 17",
            ix: 17,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-2.823, -1.818],
                      [2.823, 3.829],
                      [2.566, 1.295],
                      [-2.557, -3.829],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.257, 42.968], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 18",
            ix: 18,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-10.988, 25.923],
                      [-7.406, 34.57],
                      [-7.406, 34.573],
                      [-5.798, 38.458],
                      [-1.253, 49.436],
                      [0.941, 45.197],
                      [1.719, 43.696],
                      [3.481, 40.298],
                      [4.256, 38.797],
                      [6.018, 35.398],
                      [6.793, 33.897],
                      [8.555, 30.498],
                      [8.555, 30.495],
                      [9.333, 28.997],
                      [10.988, 25.802],
                      [10.952, 25.456],
                      [10.695, 22.921],
                      [10.11, 17.175],
                      [9.852, 14.638],
                      [9.266, 8.893],
                      [9.009, 6.359],
                      [8.423, 0.613],
                      [8.165, -1.921],
                      [7.58, -7.669],
                      [7.323, -10.204],
                      [6.738, -15.949],
                      [6.48, -18.484],
                      [5.894, -24.229],
                      [5.636, -26.766],
                      [5.051, -32.509],
                      [4.793, -35.046],
                      [4.208, -40.792],
                      [3.95, -43.326],
                      [3.346, -49.265],
                      [-1.044, -49.436],
                      [-1.173, -48.449],
                      [-1.438, -46.438],
                      [-2.04, -41.879],
                      [-2.305, -39.868],
                      [-2.907, -35.309],
                      [-3.173, -33.296],
                      [-3.774, -28.737],
                      [-4.04, -26.726],
                      [-4.641, -22.167],
                      [-4.907, -20.156],
                      [-5.509, -15.595],
                      [-5.774, -13.584],
                      [-6.376, -9.025],
                      [-6.641, -7.015],
                      [-7.243, -2.455],
                      [-7.509, -0.442],
                      [-8.11, 4.117],
                      [-8.376, 6.128],
                      [-8.977, 10.687],
                      [-9.243, 12.7],
                      [-9.844, 17.259],
                      [-10.11, 19.27],
                      [-10.712, 23.829],
                      [-10.977, 25.842],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [49.872, 87.588], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 19",
            ix: 19,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [4.485, 6.918],
                      [2.149, -7.07],
                      [-8.163, -2.073],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [5.571, 6.779],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-0.681, -9.829],
                      [-8.355, -1.573],
                      [8.355, 9.829],
                      [8.355, -2.838],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [42.679, 24.889], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 20",
            ix: 20,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-4.484, 6.917],
                      [-1.7, -7.082],
                      [8.625, -1.597],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-6.028, 6.306],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [2.106, -10.744],
                      [9.992, -2.627],
                      [-9.992, 10.744],
                      [-9.992, -1.923],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [61.025, 23.974], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 21",
            ix: 21,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.628, -0.377],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.619, 0.5],
                      [7.828, -3.099],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-7.7, -1.104],
                      [-7.7, 4.344],
                      [-5.826, 5.656],
                      [7.7, -5.656],
                      [-5.197, -2.509],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.5294, 0.4314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [49.891, 14.265], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 22",
            ix: 22,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.628, -0.377],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.619, 0.5],
                      [9.194, 5.521],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-10.493, 0.467],
                      [-10.493, 5.762],
                      [-8.619, 7.075],
                      [10.493, 5.762],
                      [10.493, -12.596],
                      [-7.99, -1.091],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [52.684, 12.846], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 23",
            ix: 23,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.783],
                      [6.782, 0],
                      [0, 6.783],
                      [-6.783, 0],
                    ],
                    o: [
                      [0, 6.783],
                      [-6.783, 0],
                      [0, -6.783],
                      [6.782, 0],
                    ],
                    v: [
                      [12.282, -0.001],
                      [0.001, 12.281],
                      [-12.282, -0.001],
                      [0.001, -12.281],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [76.277, 34.753], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 24",
            ix: 24,
            cix: 2,
            np: 4,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-3.187, -3.703],
                      [-1.707, 0],
                      [-1.048, 1.215],
                      [0.255, 2.234],
                    ],
                    o: [
                      [-0.256, 2.233],
                      [1.047, 1.215],
                      [1.708, 0],
                      [3.184, -3.698],
                      [0, 0],
                    ],
                    v: [
                      [-6.344, -7.965],
                      [-4.094, 6.158],
                      [0, 7.965],
                      [4.094, 6.159],
                      [6.344, -7.965],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 2",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.299, 1.508],
                      [-0.066, 0.448],
                      [-0.332, 0],
                      [0, 0],
                      [-0.048, -0.329],
                      [4.089, -4.75],
                      [2.093, 0],
                    ],
                    o: [
                      [-4.089, -4.75],
                      [0.05, -0.329],
                      [0, 0],
                      [0.333, 0],
                      [0.068, 0.448],
                      [-1.299, 1.508],
                      [-2.092, 0],
                    ],
                    v: [
                      [-5.111, 7.034],
                      [-7.6, -8.736],
                      [-6.936, -9.307],
                      [6.935, -9.307],
                      [7.599, -8.736],
                      [5.111, 7.034],
                      [0, 9.307],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 2",
                mm: 4,
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [33.295, 58.531], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 25",
            ix: 25,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.073, -0.864],
                      [-0.865, 0.073],
                      [0.074, 0.866],
                      [0.864, -0.073],
                    ],
                    o: [
                      [0.074, 0.865],
                      [0.864, -0.075],
                      [-0.074, -0.864],
                      [-0.866, 0.074],
                    ],
                    v: [
                      [-1.566, 0.133],
                      [0.133, 1.566],
                      [1.566, -0.135],
                      [-0.133, -1.566],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [48.089, 149.004], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 26",
            ix: 26,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.074, -0.864],
                      [-0.864, 0.074],
                      [0.074, 0.865],
                      [0.864, -0.074],
                    ],
                    o: [
                      [0.074, 0.865],
                      [0.865, -0.074],
                      [-0.074, -0.864],
                      [-0.865, 0.074],
                    ],
                    v: [
                      [-1.565, 0.133],
                      [0.133, 1.566],
                      [1.565, -0.134],
                      [-0.134, -1.565],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [48.339, 141.406], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 27",
            ix: 27,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.025, 0],
                      [-0.039, 0.343],
                      [0, 0.245],
                      [0.371, 0],
                      [0, -0.371],
                      [1.375, -12.192],
                      [-0.369, -0.042],
                    ],
                    o: [
                      [0.337, 0],
                      [1.384, -12.268],
                      [0, -0.371],
                      [-0.371, 0],
                      [0, 0.245],
                      [-0.042, 0.369],
                      [0.025, 0.003],
                    ],
                    v: [
                      [-0.677, 19.093],
                      [-0.011, 18.498],
                      [1.387, -18.423],
                      [0.718, -19.093],
                      [0.047, -18.423],
                      [-1.346, 18.347],
                      [-0.753, 19.089],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [18.917, 76.241], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 28",
            ix: 28,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.274, 0],
                      [0.056, 0.014],
                      [-0.09, 0.333],
                      [0.025, 0.431],
                      [-0.344, 0.021],
                      [-0.021, -0.343],
                      [11.622, -42.613],
                    ],
                    o: [
                      [-0.055, 0],
                      [-0.333, -0.091],
                      [11.569, -42.418],
                      [-0.019, -0.345],
                      [0.348, -0.018],
                      [0.024, 0.433],
                      [-0.076, 0.277],
                    ],
                    v: [
                      [-5.765, 43.717],
                      [-5.93, 43.695],
                      [-6.369, 42.927],
                      [2.84, -43.037],
                      [3.428, -43.699],
                      [4.089, -43.11],
                      [-5.162, 43.256],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [86.778, 106.711], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 29",
            ix: 29,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [3.193, -12.479],
                      [-27.783, 13.529],
                      [-0.897, 8.884],
                      [-0.118, 1.772],
                      [0.412, 8.985],
                      [0.023, 0.491],
                      [0.511, 8.133],
                      [9.339, 1.485],
                      [2.533, 1.196],
                      [0.284, 0.146],
                      [0, 0],
                      [0, 0],
                      [2.43, -1.161],
                      [0.275, -0.12],
                      [1.778, -8.917],
                      [0.45, -23.35],
                      [0.088, -1.842],
                      [0.138, -1.845],
                      [0.181, -1.819],
                      [0.204, -1.676],
                      [0.261, -1.808],
                    ],
                    o: [
                      [15.243, 14.229],
                      [3.048, -8.329],
                      [0.182, -1.77],
                      [0.561, -8.523],
                      [-0.024, -0.488],
                      [-0.364, -7.388],
                      [-1.015, -16.105],
                      [-4.343, -0.691],
                      [-0.31, -0.147],
                      [-3.012, -1.535],
                      [0, 0],
                      [-1.793, 2.615],
                      [-0.269, 0.128],
                      [-7.394, 3.191],
                      [-1.117, 5.598],
                      [-0.032, 1.713],
                      [-0.081, 1.726],
                      [-0.126, 1.714],
                      [-0.158, 1.594],
                      [-0.208, 1.719],
                      [-1.387, 9.703],
                    ],
                    v: [
                      [-43.686, 53.457],
                      [35.385, 61.722],
                      [42.682, 14.783],
                      [43.125, 9.472],
                      [43.154, -16.619],
                      [43.084, -18.086],
                      [41.665, -41.273],
                      [25.936, -68.787],
                      [15.627, -71.896],
                      [14.741, -72.332],
                      [10.024, -75.251],
                      [-10.805, -75.251],
                      [-17.289, -69.831],
                      [-18.105, -69.46],
                      [-35.898, -57.987],
                      [-33.178, -17.522],
                      [-33.357, -12.191],
                      [-33.684, -6.839],
                      [-34.146, -1.534],
                      [-34.687, 3.371],
                      [-35.386, 8.659],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [53.31, 92.107], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 30",
            ix: 30,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.07, -0.385],
                      [-2.875, -5.066],
                      [-3.22, -2.073],
                      [-3.299, -0.016],
                      [-0.042, 0.461],
                      [2.505, 1.609],
                      [1.586, 2.728],
                      [0.013, 0.079],
                      [0.382, -0.07],
                    ],
                    o: [
                      [0.062, 0.327],
                      [1.695, 2.987],
                      [2.706, 1.741],
                      [0.046, -0.483],
                      [-3.078, 0.003],
                      [-2.878, -1.851],
                      [-2.929, -5.038],
                      [-0.072, -0.385],
                      [-0.384, 0.074],
                    ],
                    v: [
                      [-10.133, -10.107],
                      [-6.229, 0.302],
                      [1.043, 8.363],
                      [10.073, 11.003],
                      [10.203, 9.59],
                      [1.809, 7.175],
                      [-4.809, -0.107],
                      [-8.741, -10.365],
                      [-9.565, -10.933],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [76.391, 160.884], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 31",
            ix: 31,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-1.424, 15.448],
                      [-0.042, 0.462],
                      [0, 0],
                      [6.519, -1.85],
                      [0.474, -0.144],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [2.179, -19.925],
                      [0.047, -0.485],
                      [0.628, -6.816],
                      [-3.378, -1.407],
                      [-0.472, 0.132],
                      [-11.585, 3.471],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [13.389, 66.155],
                      [19.509, 4.122],
                      [19.639, 2.709],
                      [20.654, -8.4],
                      [4.628, -6.989],
                      [3.21, -6.578],
                      [-20.654, 2.751],
                      [-20.654, 66.155],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [66.953, 167.766], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 32",
            ix: 32,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.35, -0.167],
                      [-1.258, -0.38],
                      [-0.484, -0.096],
                      [-0.446, -0.026],
                      [-0.689, 0.329],
                      [-0.193, 1],
                      [-0.726, 7.699],
                      [-0.042, 0.449],
                      [-0.087, 0.952],
                      [0.47, 0.062],
                      [0.118, -1.248],
                      [0.044, -0.476],
                      [0.498, -2.555],
                      [0.469, -0.225],
                      [1.116, 0.243],
                      [0.443, 0.143],
                      [0.425, 0.201],
                      [0.172, -0.338],
                    ],
                    o: [
                      [0.221, 0.105],
                      [0.44, 0.134],
                      [0.443, 0.085],
                      [0.856, 0.049],
                      [0.859, -0.412],
                      [0.514, -2.635],
                      [0.047, -0.473],
                      [0.115, -1.231],
                      [-0.466, -0.057],
                      [-0.088, 0.957],
                      [-0.042, 0.446],
                      [-0.728, 7.68],
                      [-0.112, 0.589],
                      [-0.748, 0.357],
                      [-0.476, -0.104],
                      [-0.808, -0.262],
                      [-0.35, -0.168],
                      [-0.173, 0.337],
                    ],
                    v: [
                      [-5.723, 12.809],
                      [-3.253, 13.755],
                      [-1.857, 14.107],
                      [-0.519, 14.281],
                      [1.852, 13.91],
                      [3.461, 11.75],
                      [5.78, -9.468],
                      [5.912, -10.852],
                      [6.217, -14.155],
                      [4.811, -14.33],
                      [4.502, -10.991],
                      [4.372, -9.608],
                      [2.071, 11.495],
                      [1.219, 12.687],
                      [-1.768, 12.726],
                      [-3.16, 12.347],
                      [-5.099, 11.587],
                      [-6.044, 11.895],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [46.251, 155.03], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 33",
            ix: 33,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.129, -2.646],
                      [-0.387, -0.023],
                      [0, 0],
                      [-0.018, 0.376],
                      [-0.165, 2.615],
                      [-0.031, 0.46],
                      [-0.532, 5.922],
                      [-0.042, 0.446],
                      [-0.092, 0.976],
                      [0.463, 0.078],
                      [0.119, -1.313],
                      [0.044, -0.479],
                      [0.555, -8.493],
                      [0.032, -0.469],
                    ],
                    o: [
                      [-0.019, 0.373],
                      [0, 0],
                      [0.389, 0.019],
                      [0.124, -2.556],
                      [0.028, -0.46],
                      [0.557, -8.544],
                      [0.045, -0.479],
                      [0.119, -1.3],
                      [-0.469, -0.073],
                      [-0.091, 0.977],
                      [-0.042, 0.445],
                      [-0.526, 5.87],
                      [-0.032, 0.467],
                      [-0.17, 2.709],
                    ],
                    v: [
                      [-2.08, 18.142],
                      [-1.413, 18.858],
                      [-1.408, 18.858],
                      [-0.669, 18.211],
                      [-0.229, 10.422],
                      [-0.141, 9.041],
                      [1.653, -13.813],
                      [1.781, -15.203],
                      [2.1, -18.631],
                      [0.699, -18.858],
                      [0.38, -15.405],
                      [0.252, -14.015],
                      [-1.532, 8.663],
                      [-1.626, 10.071],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [44.624, 158.715], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 34",
            ix: 34,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.1, -0.526],
                      [-0.792, 0.272],
                      [-1.151, 0.741],
                      [-0.964, 0.976],
                      [-0.084, 0.453],
                      [0.382, 0.074],
                      [0.071, -0.382],
                      [6.163, -5.827],
                      [0.907, -0.582],
                      [1.21, -0.396],
                      [0.523, -0.11],
                    ],
                    o: [
                      [0.834, -0.147],
                      [1.263, -0.427],
                      [1.193, -0.768],
                      [6.07, -6.124],
                      [0.072, -0.384],
                      [-0.384, -0.067],
                      [-0.02, 0.11],
                      [-0.77, 0.73],
                      [-1.094, 0.704],
                      [-0.503, 0.164],
                      [-0.093, 0.478],
                    ],
                    v: [
                      [-8.653, 10.872],
                      [-6.215, 10.244],
                      [-2.594, 8.491],
                      [0.64, 5.853],
                      [8.581, -9.979],
                      [8.016, -10.805],
                      [7.191, -10.239],
                      [-0.852, 5.325],
                      [-3.366, 7.307],
                      [-6.824, 8.955],
                      [-8.362, 9.368],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [17.221, 160.756], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 35",
            ix: 35,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [4.253, -10.409],
                      [14.033, 9.368],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-4.254, 10.41],
                      [-5.756, -3.843],
                      [0, 0],
                    ],
                    v: [
                      [39.709, -8.16],
                      [37.427, 20.945],
                      [-35.924, 16.629],
                      [-29.421, -22.67],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [47.477, 154.903], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 36",
            ix: 36,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.775, -3.948],
                      [0.1, -0.523],
                      [1.253, -23.434],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.093, 0.477],
                      [-2.457, 12.695],
                      [0, 0],
                    ],
                    v: [
                      [12.057, 63.193],
                      [21.154, 9.51],
                      [21.899, 5.122],
                      [-4.287, -4.12],
                      [-5.781, -4.646],
                      [-12.016, -6.846],
                      [-13.29, -0.603],
                      [-13.581, 0.898],
                      [-21.899, 63.193],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [22.149, 170.728], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 24,
      },
      {
        ty: 4,
        nm: "Man.R.Hand",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "R Hand",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [26.019, 71.51, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [12.518, 11.961, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 38,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-12],
                t: 98,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [7],
                t: 122,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [7],
                t: 196,
              },
              { s: [0], t: 240.0000097754 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.232, -0.207],
                      [1.94, -0.943],
                      [1.032, 0.107],
                      [0.505, 0.677],
                      [-1.075, 1.659],
                      [0, 0],
                      [-0.282, 0.188],
                      [-1.452, -1.947],
                      [-0.533, -0.055],
                      [-1.024, 0.903],
                      [-0.205, -0.237],
                    ],
                    o: [
                      [-0.107, 0.096],
                      [-1.074, 0.522],
                      [-0.85, -0.09],
                      [-1.79, -2.405],
                      [0.426, 0.437],
                      [0, 0],
                      [-0.925, 1.367],
                      [0.311, 0.42],
                      [1.992, 0.21],
                      [0.232, -0.207],
                      [0.207, 0.232],
                    ],
                    v: [
                      [5.277, 0.855],
                      [1.57, 3.344],
                      [-1.699, 4.126],
                      [-3.742, 2.971],
                      [-2.926, -4.233],
                      [-2.26, -3.542],
                      [-1.818, -3.852],
                      [-2.836, 2.295],
                      [-1.582, 3.001],
                      [4.53, 0.009],
                      [5.326, 0.059],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [21.359, 51.25], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.05, 0.064],
                      [0.119, 0.337],
                      [0.197, 0.206],
                      [0.162, 0.122],
                      [0, 0],
                      [-0.225, 0.087],
                      [-0.108, 0.073],
                      [-0.117, 0.379],
                      [-0.669, -0.069],
                      [-0.186, 0.005],
                    ],
                    o: [
                      [-3.763, 2.428],
                      [0, 0],
                      [0.055, -0.058],
                      [0.422, -0.569],
                      [-0.087, -0.236],
                      [-0.128, -0.137],
                      [0, 0],
                      [0.285, -0.047],
                      [0.136, -0.055],
                      [0.343, -0.223],
                      [0.456, 0.437],
                      [0.181, 0.02],
                      [0, 0],
                    ],
                    v: [
                      [3.618, 5.976],
                      [-1.201, 6.607],
                      [-3.109, -4.21],
                      [-2.951, -4.398],
                      [-2.701, -5.821],
                      [-3.137, -6.485],
                      [-3.576, -6.872],
                      [-3.618, -7.106],
                      [-2.844, -7.304],
                      [-2.477, -7.492],
                      [-1.779, -8.404],
                      [-0.088, -7.645],
                      [0.459, -7.627],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [19.747, 63.019], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.005, -0.003],
                      [-0.824, -0.782],
                      [-0.55, -0.505],
                      [-0.037, 0.06],
                      [0, 0],
                      [-0.125, -0.715],
                      [0, 0],
                    ],
                    o: [
                      [0.974, 0.603],
                      [0.804, 0.764],
                      [0.039, -0.064],
                      [0, 0],
                      [-0.163, -0.707],
                      [0, 0],
                      [0.005, 0.003],
                    ],
                    v: [
                      [1.512, 19.753],
                      [4.158, 21.697],
                      [6.018, 23.354],
                      [6.018, 23.354],
                      [-4.838, -22.706],
                      [-5.932, -22.482],
                      [1.512, 19.753],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.415, 23.663], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.003, -0.707],
                      [0.518, -0.337],
                      [0.136, -0.056],
                      [0.381, -0.043],
                      [-0.198, -0.212],
                      [-0.087, -0.236],
                      [0.422, -0.57],
                      [1.704, -0.201],
                      [-0.352, -0.98],
                      [0.37, -0.492],
                      [0.535, -0.258],
                      [1.303, -0.215],
                      [0, 0],
                      [0.639, 0.009],
                      [-0.111, 0.011],
                      [-0.704, 0.937],
                      [0.125, 0.355],
                      [1.757, 0.433],
                      [-0.039, 0.286],
                      [-0.285, -0.006],
                      [-1.024, 1.364],
                      [0.023, 0.056],
                      [0.445, 0.259],
                      [1.027, 0.244],
                      [0.073, 0.088],
                      [-0.046, 0.186],
                      [-0.308, -0.073],
                      [-0.503, -0.157],
                      [-0.69, 0.07],
                      [-0.212, 0.137],
                      [-0.006, 0.372],
                      [0.378, 0.632],
                      [-0.146, 0.183],
                      [-0.003, 0.006],
                      [-0.197, -0.334],
                    ],
                    o: [
                      [-0.003, 0.669],
                      [-0.107, 0.073],
                      [-0.288, 0.113],
                      [0.276, 0.183],
                      [0.198, 0.207],
                      [0.12, 0.337],
                      [-0.907, 1.201],
                      [1.218, 0.718],
                      [0.223, 0.634],
                      [-0.255, 0.34],
                      [-1.332, 0.649],
                      [-0.451, -0.52],
                      [0, 0],
                      [0.081, -0.067],
                      [2.09, -0.244],
                      [0.09, -0.125],
                      [-0.386, -1.079],
                      [-0.282, -0.067],
                      [0.034, -0.29],
                      [1.396, 0.026],
                      [0.148, -0.198],
                      [-0.09, -0.256],
                      [-0.905, -0.526],
                      [-0.119, -0.029],
                      [-0.119, -0.13],
                      [0.073, -0.303],
                      [0.195, 0.046],
                      [0.948, 0.111],
                      [0.413, -0.04],
                      [0.129, -0.084],
                      [0.005, -0.465],
                      [-0.119, -0.197],
                      [0.002, -0.003],
                      [0.241, -0.305],
                      [0.471, 0.791],
                    ],
                    v: [
                      [5.563, -5.207],
                      [4.766, -3.669],
                      [4.4, -3.482],
                      [3.382, -3.253],
                      [4.106, -2.663],
                      [4.542, -1.999],
                      [4.292, -0.575],
                      [-0.41, 1.319],
                      [2.222, 3.994],
                      [2.003, 5.716],
                      [0.779, 6.614],
                      [-3.804, 7.882],
                      [-4.496, 7.033],
                      [-5.566, 7.071],
                      [-5.272, 6.952],
                      [1.102, 5.038],
                      [1.157, 4.372],
                      [-3.016, 1.432],
                      [-3.44, 0.815],
                      [-2.874, 0.321],
                      [3.388, -1.255],
                      [3.475, -1.616],
                      [2.62, -2.398],
                      [-0.725, -3.654],
                      [-1.015, -3.832],
                      [-1.143, -4.334],
                      [-0.46, -4.753],
                      [0.643, -4.448],
                      [3.196, -4.355],
                      [4.152, -4.617],
                      [4.435, -5.219],
                      [3.827, -6.932],
                      [3.874, -7.562],
                      [3.885, -7.577],
                      [4.795, -7.519],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.503, 59.196], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.086, -0.042],
                      [0.173, 0.117],
                      [-0.174, 0.259],
                      [0.366, 1.412],
                      [0.049, 0.025],
                      [-0.141, 0.278],
                      [-0.278, -0.142],
                      [-1.065, -4.105],
                      [1.126, -1.675],
                    ],
                    o: [
                      [-0.175, 0.084],
                      [-0.259, -0.173],
                      [0.937, -1.395],
                      [-0.939, -3.618],
                      [-0.278, -0.141],
                      [0.142, -0.278],
                      [0.217, 0.111],
                      [0.451, 1.738],
                      [-0.057, 0.086],
                    ],
                    v: [
                      [2.191, 6.131],
                      [1.628, 6.092],
                      [1.475, 5.308],
                      [2.335, 1.079],
                      [-3.492, -5.067],
                      [-3.739, -5.826],
                      [-2.979, -6.074],
                      [3.428, 0.795],
                      [2.411, 5.938],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.851, 0.4235, 0.3882], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [20.815, 61.785], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [7.115, -1.731],
                      [1.873, 2.173],
                      [0, 0],
                      [0.638, 0.009],
                      [1.042, 1.843],
                      [0, 0],
                      [-0.694, 1.801],
                      [0, 0],
                      [-1.341, 2.268],
                      [-0.329, -0.207],
                      [-2.84, 0.966],
                      [-2.68, -2.763],
                      [0, 0],
                      [-0.282, 0.187],
                      [-2.621, -2.189],
                    ],
                    o: [
                      [0, 0],
                      [-5.746, 1.395],
                      [-0.447, -0.521],
                      [0, 0],
                      [-1.498, -0.02],
                      [-1.486, -2.624],
                      [0, 0],
                      [0.685, -1.77],
                      [0, 0],
                      [2.016, -3.411],
                      [-0.261, -0.3],
                      [2.478, -0.844],
                      [0.426, 0.437],
                      [0, 0],
                      [1.341, -0.897],
                      [3.169, 2.645],
                    ],
                    v: [
                      [14.913, 3.866],
                      [6.214, 15.259],
                      [-6.822, 8.962],
                      [-7.513, 8.115],
                      [-8.579, 8.15],
                      [-13.783, 5.98],
                      [-9.861, 1.564],
                      [-11.94, -2.715],
                      [-7.536, -4.854],
                      [-10.199, -9.723],
                      [-3.711, -9.174],
                      [-6.32, -15.81],
                      [2.914, -11.1],
                      [3.579, -10.409],
                      [4.022, -10.719],
                      [12.099, -11.828],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.9176, 0.6078, 0.4784], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [15.518, 58.117], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -4.45],
                      [4.451, 0],
                      [0, 4.451],
                      [-4.45, 0],
                    ],
                    o: [
                      [0, 4.451],
                      [-4.45, 0],
                      [0, -4.45],
                      [4.451, 0],
                    ],
                    v: [
                      [8.059, -0.001],
                      [-0.001, 8.059],
                      [-8.059, -0.001],
                      [-0.001, -8.059],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [20.725, 65.006], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 25,
        parent: 26,
      },
      {
        ty: 4,
        nm: "Man.R.Arm",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "R Arm",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [52.781, 38.33, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [14.62, 57.997, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 38,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-19],
                t: 98,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-8],
                t: 122,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-8],
                t: 196,
              },
              { s: [0], t: 240.0000097754 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.279, 0.083],
                      [0.041, 0.023],
                      [-0.171, 0.307],
                      [-0.023, 2.124],
                      [-0.352, -0.004],
                      [0.003, -0.351],
                      [0.062, -0.11],
                    ],
                    o: [
                      [-0.044, -0.013],
                      [-0.308, -0.171],
                      [0.014, -0.024],
                      [0.004, -0.351],
                      [0.35, 0.003],
                      [-0.027, 2.451],
                      [-0.147, 0.266],
                    ],
                    v: [
                      [-0.864, 3.024],
                      [-0.992, 2.971],
                      [-1.239, 2.105],
                      [0.134, -2.475],
                      [0.778, -3.104],
                      [1.407, -2.46],
                      [-0.127, 2.723],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [46.08, 31.303], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.128, 0.038],
                      [0.072, 0.196],
                      [1.6, 2.057],
                      [-0.278, 0.216],
                      [-0.215, -0.278],
                      [-0.059, -0.162],
                      [0.33, -0.122],
                    ],
                    o: [
                      [-0.186, -0.055],
                      [-0.014, -0.037],
                      [-0.215, -0.278],
                      [0.277, -0.216],
                      [1.722, 2.213],
                      [0.122, 0.33],
                      [-0.133, 0.05],
                    ],
                    v: [
                      [1.376, 3.666],
                      [0.96, 3.276],
                      [-2.061, -2.596],
                      [-1.949, -3.489],
                      [-1.056, -3.377],
                      [2.154, 2.835],
                      [1.778, 3.654],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [48.17, 31.286], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.174, -0.584],
                      [0.584, 0.174],
                      [-0.174, 0.584],
                      [-0.584, -0.173],
                    ],
                    o: [
                      [-0.174, 0.584],
                      [-0.584, -0.175],
                      [0.174, -0.584],
                      [0.584, 0.174],
                    ],
                    v: [
                      [1.058, 0.314],
                      [-0.316, 1.057],
                      [-1.058, -0.315],
                      [0.314, -1.059],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [14.731, 11.611], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.176, 0.052],
                      [0.086, 0.126],
                      [0, 0],
                      [-0.291, 0.196],
                      [-0.197, -0.291],
                      [0, 0],
                      [0.291, -0.197],
                    ],
                    o: [
                      [-0.136, -0.041],
                      [0, 0],
                      [-0.197, -0.291],
                      [0.292, -0.198],
                      [0, 0],
                      [0.197, 0.292],
                      [-0.165, 0.111],
                    ],
                    v: [
                      [1.944, 3.785],
                      [1.598, 3.532],
                      [-2.653, -2.756],
                      [-2.483, -3.639],
                      [-1.598, -3.468],
                      [2.653, 2.818],
                      [2.483, 3.702],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [9.856, 15.738], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.053, 0.016],
                      [-0.007, 0.294],
                      [-0.352, -0.008],
                      [-0.026, 0.094],
                      [-0.338, -0.097],
                      [-0.002, 0],
                      [0.096, -0.336],
                      [10.257, 0.252],
                    ],
                    o: [
                      [-0.269, -0.08],
                      [0.008, -0.352],
                      [9.286, 0.228],
                      [0.098, -0.338],
                      [0.002, 0.001],
                      [0.334, 0.1],
                      [-0.031, 0.104],
                      [-0.058, -0.001],
                    ],
                    v: [
                      [-6.587, 5.361],
                      [-7.041, 4.736],
                      [-6.389, 4.115],
                      [5.728, -5.107],
                      [6.516, -5.543],
                      [6.522, -5.54],
                      [6.952, -4.755],
                      [-6.42, 5.387],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [13.774, 14.878], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.84, -4.37],
                      [-4.371, 0.84],
                      [0.84, 4.37],
                      [4.37, -0.84],
                    ],
                    o: [
                      [0.84, 4.371],
                      [4.37, -0.841],
                      [-0.841, -4.371],
                      [-4.371, 0.84],
                    ],
                    v: [
                      [-7.913, 1.521],
                      [1.522, 7.914],
                      [7.914, -1.522],
                      [-1.521, -7.914],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [9.003, 9.004], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.195, -6.216],
                      [-6.217, 1.196],
                      [1.195, 6.217],
                      [6.217, -1.195],
                    ],
                    o: [
                      [1.195, 6.216],
                      [6.216, -1.195],
                      [-1.196, -6.216],
                      [-6.216, 1.195],
                    ],
                    v: [
                      [-11.255, 2.164],
                      [2.164, 11.255],
                      [11.255, -2.165],
                      [-2.165, -11.256],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [50.861, 37.959], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [2.211, -0.373],
                      [2.015, 0.198],
                      [1.697, 0.532],
                      [1.431, 0.704],
                      [2.229, 1.687],
                      [1.879, 1.815],
                      [3.019, 3.912],
                      [2.527, 4.189],
                      [-2.71, 4.944],
                      [-3.088, -3.022],
                      [-3.206, -2.449],
                      [-1.569, -0.947],
                      [-1.356, -0.47],
                      [-0.574, 0.074],
                      [-0.052, 0.068],
                      [-0.039, 0.079],
                      [-0.051, 0.069],
                      [-0.031, 0.03],
                      [0, 0],
                    ],
                    o: [
                      [-2.001, 1.003],
                      [-2.214, 0.383],
                      [-2.016, -0.201],
                      [-1.699, -0.533],
                      [-2.862, -1.419],
                      [-2.227, -1.686],
                      [-3.751, -3.635],
                      [-2.997, -3.93],
                      [6.018, -0.423],
                      [2.896, 3.29],
                      [3.074, 3.029],
                      [1.601, 1.22],
                      [1.569, 0.938],
                      [1.356, 0.474],
                      [0.288, -0.036],
                      [0.058, -0.067],
                      [0.02, -0.04],
                      [0.015, -0.023],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [27.495, 20.13],
                      [21.064, 22.237],
                      [14.655, 22.451],
                      [9.089, 21.294],
                      [4.417, 19.397],
                      [-3.105, 14.667],
                      [-9.202, 9.375],
                      [-19.215, -1.996],
                      [-27.495, -14.128],
                      [-13.755, -22.649],
                      [-5.547, -13.974],
                      [3.921, -5.658],
                      [8.715, -2.395],
                      [13.188, -0.225],
                      [16.255, 0.334],
                      [16.742, 0.16],
                      [16.82, -0.038],
                      [16.922, -0.196],
                      [16.971, -0.258],
                      [17.689, -0.234],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [29.908, 27.366], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 26,
        parent: 27,
      },
      {
        ty: 4,
        nm: "Man.R.Shoulder",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        cl: "R Shoulder",
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [49.646, 15.163, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [26.258, 40.174, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 38,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-24],
                t: 98,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-24],
                t: 196,
              },
              { s: [0], t: 240.0000097754 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.3, 0.058],
                      [-0.029, 0.01],
                      [0, 0],
                      [0.113, 0.326],
                      [0.327, -0.116],
                      [0, 0],
                      [-0.113, -0.326],
                    ],
                    o: [
                      [0.029, -0.005],
                      [0, 0],
                      [0.327, -0.113],
                      [-0.113, -0.326],
                      [0, 0],
                      [-0.326, 0.113],
                      [0.104, 0.297],
                    ],
                    v: [
                      [-2.183, 1.43],
                      [-2.095, 1.407],
                      [2.505, -0.192],
                      [2.891, -0.987],
                      [2.095, -1.372],
                      [-2.505, 0.226],
                      [-2.891, 1.021],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [51.849, 35.467], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.193, 0.037],
                      [-0.093, 0.096],
                      [-0.1, 0.123],
                      [0.267, 0.219],
                      [0.219, -0.267],
                      [5.778, -5.957],
                      [-0.248, -0.24],
                    ],
                    o: [
                      [0.121, -0.023],
                      [5.814, -5.994],
                      [0.22, -0.267],
                      [-0.266, -0.219],
                      [-0.1, 0.122],
                      [-0.241, 0.248],
                      [0.153, 0.147],
                    ],
                    v: [
                      [-7.849, 9.781],
                      [-7.519, 9.602],
                      [8.437, -8.719],
                      [8.351, -9.6],
                      [7.472, -9.514],
                      [-8.416, 8.732],
                      [-8.403, 9.617],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [45.678, 40.506], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [-2.001, 1.003],
                      [-0.887, 0.605],
                      [0, 0],
                      [0, 0],
                      [-0.168, 0.132],
                      [-0.146, 0.122],
                      [-0.991, 0.931],
                      [-1.699, 1.809],
                      [-1.584, 1.815],
                      [-1.508, 1.837],
                      [-1.472, 1.846],
                      [-1.44, 1.862],
                      [-1.398, 1.89],
                      [0, 0],
                      [0, 0],
                      [-0.363, 0.522],
                      [0, 0],
                      [0, 0],
                      [1.35, -1.81],
                      [1.354, -1.789],
                      [1.358, -1.75],
                      [1.362, -1.659],
                      [1.296, -1.414],
                      [0.516, -0.5],
                      [0, 0],
                      [0, 0],
                      [0.016, -0.023],
                      [0.019, -0.04],
                      [0.057, -0.067],
                      [0.289, -0.036],
                    ],
                    o: [
                      [2.211, -0.373],
                      [1.002, -0.499],
                      [0, 0],
                      [0, 0],
                      [0.187, -0.139],
                      [0.16, -0.128],
                      [1.233, -1.009],
                      [1.966, -1.857],
                      [1.687, -1.811],
                      [1.579, -1.815],
                      [1.512, -1.834],
                      [1.465, -1.852],
                      [1.427, -1.871],
                      [0, 0],
                      [0, 0],
                      [0.349, -0.486],
                      [0, 0],
                      [0, 0],
                      [-1.335, 1.831],
                      [-1.343, 1.816],
                      [-1.36, 1.784],
                      [-1.356, 1.748],
                      [-1.35, 1.662],
                      [-0.643, 0.701],
                      [0, 0],
                      [0, 0],
                      [-0.03, 0.03],
                      [-0.05, 0.069],
                      [-0.039, 0.079],
                      [-0.052, 0.068],
                      [0, 0],
                    ],
                    v: [
                      [-21.029, 30.807],
                      [-14.599, 28.7],
                      [-11.759, 27.033],
                      [-11.115, 26.578],
                      [-10.576, 26.173],
                      [-10.069, 25.779],
                      [-9.589, 25.393],
                      [-6.323, 22.508],
                      [-0.914, 17.04],
                      [3.962, 11.599],
                      [8.599, 6.131],
                      [13.079, 0.616],
                      [17.434, -4.954],
                      [21.678, -10.588],
                      [23.766, -13.435],
                      [24.797, -14.875],
                      [25.84, -16.35],
                      [5.861, -30.807],
                      [-2.617, -20.012],
                      [-6.646, -14.548],
                      [-10.685, -9.133],
                      [-14.747, -3.816],
                      [-18.835, 1.31],
                      [-22.846, 6.003],
                      [-24.637, 7.854],
                      [-24.982, 8.181],
                      [-25.124, 8.312],
                      [-25.172, 8.374],
                      [-25.273, 8.532],
                      [-25.352, 8.73],
                      [-25.84, 8.904],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [33.842, 38.463], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.281, -6.661],
                      [-6.661, 1.28],
                      [1.281, 6.66],
                      [6.661, -1.281],
                    ],
                    o: [
                      [1.28, 6.661],
                      [6.66, -1.282],
                      [-1.281, -6.661],
                      [-6.661, 1.281],
                    ],
                    v: [
                      [-12.06, 2.319],
                      [2.319, 12.061],
                      [12.06, -2.318],
                      [-2.319, -12.06],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [50.7, 13.592], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.195, -6.216],
                      [-6.217, 1.196],
                      [1.195, 6.217],
                      [6.217, -1.195],
                    ],
                    o: [
                      [1.195, 6.216],
                      [6.216, -1.195],
                      [-1.196, -6.216],
                      [-6.216, 1.195],
                    ],
                    v: [
                      [-11.255, 2.164],
                      [2.164, 11.255],
                      [11.255, -2.165],
                      [-2.165, -11.256],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12.7, 57.626], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 27,
        parent: 24,
      },
      {
        ty: 4,
        nm: "Line6",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [1.5, 1.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [58, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 111.533,
              },
              { s: [58, 100, 100], t: 237.407509669806 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [248.077, 125.172, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.5, 1.5],
                      [59.227, 1.5],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 3 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 28,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Line5",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [1.5, 1.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [79, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [69, 100, 100],
                t: 111.533,
              },
              { s: [79, 100, 100], t: 237.407509669806 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [248.077, 111.969, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.5, 1.5],
                      [59.227, 1.5],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 3 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 29,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Line4",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [1.5, 1.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [58, 100, 100],
                t: 111.533,
              },
              { s: [100, 100, 100], t: 237.407509669806 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [248.077, 98.16, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.5, 1.5],
                      [59.227, 1.5],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 3 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 30,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Line3",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [1.5, 1.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [58, 100, 100],
                t: 112.33,
              },
              { s: [100, 100, 100], t: 237.407509669806 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [71.104, 125.172, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.5, 1.5],
                      [59.227, 1.5],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 3 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 31,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Line2",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [1.5, 1.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [79, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [69, 100, 100],
                t: 111.533,
              },
              { s: [79, 100, 100], t: 237.407509669806 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [71.104, 111.969, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.5, 1.5],
                      [59.227, 1.5],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 3 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 32,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Line1",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [1.5, 1.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [58, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 112.33,
              },
              { s: [58, 100, 100], t: 237.407509669806 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [71.104, 98.16, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.5, 1.5],
                      [59.227, 1.5],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 3 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 33,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Red Circle",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [3.856, 3.856, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [110, 110, 100],
                t: 58.03,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 119.993,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [110, 110, 100],
                t: 177.038,
              },
              { s: [100, 100, 100], t: 239.00000973467 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [265.008, 145.56, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.992],
                      [1.991, 0],
                      [0, 1.991],
                      [-1.993, 0],
                    ],
                    o: [
                      [0, 1.991],
                      [-1.993, 0],
                      [0, -1.992],
                      [1.991, 0],
                    ],
                    v: [
                      [3.607, 0],
                      [0, 3.606],
                      [-3.607, 0],
                      [0, -3.606],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [3.857, 3.856], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 34,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Red Line",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0.25, 2.397, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [170, 100, 100],
                t: 118.703,
              },
              { s: [100, 100, 100], t: 239.00000973467 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [53.304, 160.112, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [67.24, 2.148],
                      [-67.24, 2.148],
                      [-67.24, -2.148],
                      [67.24, -2.148],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [67.49, 2.397], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 35,
        parent: 44,
      },
      {
        ty: 4,
        nm: "89 Outlines",
        sr: 1,
        st: 0,
        op: 241.000009816131,
        ip: 211.000008594206,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [27, 27, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [366, 260, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "8",
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.184, 1.862],
                      [3.741, 0],
                      [2.184, -1.862],
                      [0, -3.216],
                      [-0.897, -1.371],
                      [-1.574, -0.829],
                      [1.016, -1.523],
                      [0, -1.98],
                      [-2.319, -1.921],
                      [-4.012, 0],
                      [-2.277, 1.913],
                      [0, 3.301],
                      [1.032, 1.498],
                      [1.76, 0.863],
                      [-0.889, 1.371],
                      [0, 1.794],
                    ],
                    o: [
                      [-2.184, -1.862],
                      [-3.707, 0],
                      [-2.184, 1.862],
                      [0, 1.794],
                      [0.897, 1.371],
                      [-1.862, 0.88],
                      [-1.016, 1.523],
                      [0, 3.284],
                      [2.319, 1.921],
                      [3.995, 0],
                      [2.276, -1.913],
                      [0, -2.048],
                      [-1.033, -1.498],
                      [1.557, -0.829],
                      [0.889, -1.371],
                      [0, -3.216],
                    ],
                    v: [
                      [-6.195, -34.709],
                      [-15.082, -37.502],
                      [-23.918, -34.709],
                      [-27.193, -27.092],
                      [-25.848, -22.344],
                      [-22.141, -19.043],
                      [-26.457, -15.438],
                      [-27.98, -10.182],
                      [-24.502, -2.374],
                      [-15.006, 0.508],
                      [-5.599, -2.361],
                      [-2.184, -10.182],
                      [-3.732, -15.501],
                      [-7.922, -19.043],
                      [-4.253, -22.344],
                      [-2.92, -27.092],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.778, -0.846],
                      [1.27, 0],
                      [0.804, 0.855],
                      [0, 1.473],
                      [-0.796, 0.821],
                      [-1.287, 0],
                      [-0.787, -0.821],
                      [0, -1.523],
                    ],
                    o: [
                      [-0.779, 0.847],
                      [-1.304, 0],
                      [-0.804, -0.855],
                      [0, -1.523],
                      [0.795, -0.821],
                      [1.286, 0],
                      [0.787, 0.821],
                      [0, 1.49],
                    ],
                    v: [
                      [-11.934, -7.363],
                      [-15.006, -6.094],
                      [-18.167, -7.376],
                      [-19.373, -10.867],
                      [-18.18, -14.384],
                      [-15.057, -15.615],
                      [-11.946, -14.384],
                      [-10.766, -10.867],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.601, -0.762],
                      [0, -1.32],
                      [0.584, -0.812],
                      [1.168, 0],
                      [0.609, 0.812],
                      [0, 1.32],
                      [-0.601, 0.753],
                      [-1.151, 0],
                    ],
                    o: [
                      [0.601, 0.762],
                      [0, 1.32],
                      [-0.584, 0.812],
                      [-1.185, 0],
                      [-0.609, -0.812],
                      [0, -1.337],
                      [0.601, -0.753],
                      [1.185, 0],
                    ],
                    v: [
                      [-12.403, -29.758],
                      [-11.502, -26.635],
                      [-12.378, -23.436],
                      [-15.006, -22.217],
                      [-17.697, -23.436],
                      [-18.611, -26.635],
                      [-17.71, -29.771],
                      [-15.082, -30.9],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "9",
            ix: 2,
            cix: 2,
            np: 5,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "9",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [5.891, -0.288],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.962, 1.608],
                      [-1.6, 2.996],
                      [0, 4.08],
                      [0, 0],
                      [1.1, 2.243],
                      [1.972, 1.227],
                      [2.438, 0],
                      [1.989, -1.185],
                      [1.1, -2.031],
                      [0, -2.37],
                      [-1.964, -2.268],
                      [-3.352, 0],
                      [-1.845, 1.659],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.944, 0],
                      [2.962, -1.608],
                      [1.6, -2.996],
                      [0, 0],
                      [-0.034, -2.928],
                      [-1.101, -2.243],
                      [-1.972, -1.227],
                      [-2.404, 0],
                      [-1.989, 1.185],
                      [-1.101, 2.031],
                      [0, 3.792],
                      [1.963, 2.268],
                      [2.285, 0],
                      [-0.66, 4.994],
                    ],
                    v: [
                      [9.293, -6.373],
                      [7.668, -6.348],
                      [7.668, 0.508],
                      [8.201, 0.508],
                      [18.561, -1.904],
                      [25.403, -8.811],
                      [27.803, -19.424],
                      [27.803, -22.699],
                      [26.102, -30.456],
                      [21.493, -35.661],
                      [14.879, -37.502],
                      [8.29, -35.725],
                      [3.656, -30.9],
                      [2.006, -24.299],
                      [4.951, -15.209],
                      [12.924, -11.807],
                      [19.119, -14.295],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "9",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.829, 1.143],
                      [0, 1.93],
                      [-0.812, 1.287],
                      [-1.202, 0],
                      [-0.796, -1.134],
                      [0, -2.37],
                      [0, 0],
                      [1.862, 0],
                    ],
                    o: [
                      [-0.83, -1.143],
                      [0, -1.828],
                      [0.812, -1.286],
                      [1.354, 0],
                      [0.795, 1.134],
                      [0, 0],
                      [-0.812, 1.608],
                      [-1.439, 0],
                    ],
                    v: [
                      [11.832, -19.69],
                      [10.588, -24.299],
                      [11.807, -28.971],
                      [14.828, -30.9],
                      [18.053, -29.199],
                      [19.246, -23.943],
                      [19.246, -20.389],
                      [15.234, -17.977],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 36,
      },
      {
        ty: 4,
        nm: "90 Outlines",
        sr: 1,
        st: 0,
        op: 211.000008594206,
        ip: 181.000007372281,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [27, 27, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [366, 260, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "9",
            ix: 1,
            cix: 2,
            np: 5,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "9",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [5.891, -0.288],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.962, 1.608],
                      [-1.6, 2.996],
                      [0, 4.08],
                      [0, 0],
                      [1.1, 2.243],
                      [1.972, 1.227],
                      [2.438, 0],
                      [1.989, -1.185],
                      [1.1, -2.031],
                      [0, -2.37],
                      [-1.964, -2.268],
                      [-3.352, 0],
                      [-1.845, 1.659],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.944, 0],
                      [2.962, -1.608],
                      [1.6, -2.996],
                      [0, 0],
                      [-0.034, -2.928],
                      [-1.101, -2.243],
                      [-1.972, -1.227],
                      [-2.404, 0],
                      [-1.989, 1.185],
                      [-1.101, 2.031],
                      [0, 3.792],
                      [1.963, 2.268],
                      [2.285, 0],
                      [-0.66, 4.994],
                    ],
                    v: [
                      [-20.82, -6.373],
                      [-22.445, -6.348],
                      [-22.445, 0.508],
                      [-21.912, 0.508],
                      [-11.553, -1.904],
                      [-4.71, -8.811],
                      [-2.311, -19.424],
                      [-2.311, -22.699],
                      [-4.012, -30.456],
                      [-8.62, -35.661],
                      [-15.234, -37.502],
                      [-21.823, -35.725],
                      [-26.457, -30.9],
                      [-28.107, -24.299],
                      [-25.162, -15.209],
                      [-17.189, -11.807],
                      [-10.994, -14.295],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "9",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.829, 1.143],
                      [0, 1.93],
                      [-0.812, 1.287],
                      [-1.202, 0],
                      [-0.796, -1.134],
                      [0, -2.37],
                      [0, 0],
                      [1.862, 0],
                    ],
                    o: [
                      [-0.83, -1.143],
                      [0, -1.828],
                      [0.812, -1.286],
                      [1.354, 0],
                      [0.795, 1.134],
                      [0, 0],
                      [-0.812, 1.608],
                      [-1.439, 0],
                    ],
                    v: [
                      [-18.281, -19.69],
                      [-19.525, -24.299],
                      [-18.307, -28.971],
                      [-15.285, -30.9],
                      [-12.061, -29.199],
                      [-10.867, -23.943],
                      [-10.867, -20.389],
                      [-14.879, -17.977],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "0",
            ix: 2,
            cix: 2,
            np: 5,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "0",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [2.251, 2.726],
                      [4.096, 0],
                      [2.243, -2.708],
                      [0, -4.993],
                      [0, 0],
                      [-2.251, -2.725],
                      [-4.113, 0],
                      [-2.243, 2.708],
                      [0, 4.994],
                    ],
                    o: [
                      [0, -4.977],
                      [-2.251, -2.725],
                      [-4.097, 0],
                      [-2.243, 2.708],
                      [0, 0],
                      [0, 4.96],
                      [2.251, 2.726],
                      [4.079, 0],
                      [2.243, -2.708],
                      [0, 0],
                    ],
                    v: [
                      [27.93, -21.836],
                      [24.553, -33.389],
                      [15.031, -37.477],
                      [5.522, -33.414],
                      [2.158, -21.861],
                      [2.158, -15.107],
                      [5.535, -3.58],
                      [15.082, 0.508],
                      [24.565, -3.555],
                      [27.93, -15.107],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "0",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0.694, -1.244],
                      [1.456, 0],
                      [0.694, 1.278],
                      [0, 2.759],
                      [0, 0],
                      [-0.669, 1.219],
                      [-1.473, 0],
                      [-0.694, -1.312],
                      [0, -2.657],
                    ],
                    o: [
                      [-0.017, 2.658],
                      [-0.694, 1.244],
                      [-1.507, 0],
                      [-0.694, -1.278],
                      [0, 0],
                      [0.051, -2.471],
                      [0.668, -1.219],
                      [1.506, 0],
                      [0.694, 1.312],
                      [0, 0],
                    ],
                    v: [
                      [19.373, -13.812],
                      [18.307, -7.96],
                      [15.082, -6.094],
                      [11.781, -8.011],
                      [10.74, -14.066],
                      [10.74, -23.512],
                      [11.819, -29.047],
                      [15.031, -30.875],
                      [18.332, -28.907],
                      [19.373, -22.953],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 37,
      },
      {
        ty: 4,
        nm: "86 Outlines",
        sr: 1,
        st: 0,
        op: 181.000007372281,
        ip: 151.000006150356,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [27, 27, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [366, 260, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "8",
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.184, 1.862],
                      [3.741, 0],
                      [2.184, -1.862],
                      [0, -3.216],
                      [-0.897, -1.371],
                      [-1.574, -0.829],
                      [1.016, -1.523],
                      [0, -1.98],
                      [-2.319, -1.921],
                      [-4.012, 0],
                      [-2.277, 1.913],
                      [0, 3.301],
                      [1.032, 1.498],
                      [1.76, 0.863],
                      [-0.889, 1.371],
                      [0, 1.794],
                    ],
                    o: [
                      [-2.184, -1.862],
                      [-3.707, 0],
                      [-2.184, 1.862],
                      [0, 1.794],
                      [0.897, 1.371],
                      [-1.862, 0.88],
                      [-1.016, 1.523],
                      [0, 3.284],
                      [2.319, 1.921],
                      [3.995, 0],
                      [2.276, -1.913],
                      [0, -2.048],
                      [-1.033, -1.498],
                      [1.557, -0.829],
                      [0.889, -1.371],
                      [0, -3.216],
                    ],
                    v: [
                      [-6.195, -34.709],
                      [-15.082, -37.502],
                      [-23.918, -34.709],
                      [-27.193, -27.092],
                      [-25.848, -22.344],
                      [-22.141, -19.043],
                      [-26.457, -15.438],
                      [-27.98, -10.182],
                      [-24.502, -2.374],
                      [-15.006, 0.508],
                      [-5.599, -2.361],
                      [-2.184, -10.182],
                      [-3.732, -15.501],
                      [-7.922, -19.043],
                      [-4.253, -22.344],
                      [-2.92, -27.092],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.778, -0.846],
                      [1.27, 0],
                      [0.804, 0.855],
                      [0, 1.473],
                      [-0.796, 0.821],
                      [-1.287, 0],
                      [-0.787, -0.821],
                      [0, -1.523],
                    ],
                    o: [
                      [-0.779, 0.847],
                      [-1.304, 0],
                      [-0.804, -0.855],
                      [0, -1.523],
                      [0.795, -0.821],
                      [1.286, 0],
                      [0.787, 0.821],
                      [0, 1.49],
                    ],
                    v: [
                      [-11.934, -7.363],
                      [-15.006, -6.094],
                      [-18.167, -7.376],
                      [-19.373, -10.867],
                      [-18.18, -14.384],
                      [-15.057, -15.615],
                      [-11.946, -14.384],
                      [-10.766, -10.867],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.601, -0.762],
                      [0, -1.32],
                      [0.584, -0.812],
                      [1.168, 0],
                      [0.609, 0.812],
                      [0, 1.32],
                      [-0.601, 0.753],
                      [-1.151, 0],
                    ],
                    o: [
                      [0.601, 0.762],
                      [0, 1.32],
                      [-0.584, 0.812],
                      [-1.185, 0],
                      [-0.609, -0.812],
                      [0, -1.337],
                      [0.601, -0.753],
                      [1.185, 0],
                    ],
                    v: [
                      [-12.403, -29.758],
                      [-11.502, -26.635],
                      [-12.378, -23.436],
                      [-15.006, -22.217],
                      [-17.697, -23.436],
                      [-18.611, -26.635],
                      [-17.71, -29.771],
                      [-15.082, -30.9],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "6",
            ix: 2,
            cix: 2,
            np: 5,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "6",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [3.03, -1.727],
                      [1.633, -3.038],
                      [0, -3.809],
                      [0, 0],
                      [-1.151, -2.217],
                      [-2.065, -1.193],
                      [-2.624, 0],
                      [-1.989, 1.109],
                      [-1.109, 1.964],
                      [0, 2.353],
                      [1.896, 2.336],
                      [3.199, 0],
                      [1.93, -1.896],
                      [-2.057, 1.532],
                      [-3.199, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-3.69, 0],
                      [-3.03, 1.727],
                      [-1.634, 3.039],
                      [0, 0],
                      [0.034, 2.895],
                      [1.151, 2.218],
                      [2.065, 1.193],
                      [2.42, 0],
                      [1.989, -1.108],
                      [1.108, -1.963],
                      [0, -3.792],
                      [-1.896, -2.336],
                      [-2.945, 0],
                      [0.474, -2.725],
                      [2.057, -1.532],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [21.633, -37.502],
                      [11.553, -34.912],
                      [4.558, -27.765],
                      [2.107, -17.494],
                      [2.107, -14.066],
                      [3.885, -6.398],
                      [8.709, -1.282],
                      [15.742, 0.508],
                      [22.356, -1.155],
                      [27.003, -5.764],
                      [28.666, -12.238],
                      [25.822, -21.43],
                      [18.18, -24.934],
                      [10.867, -22.09],
                      [14.663, -28.476],
                      [22.547, -30.773],
                      [22.928, -30.773],
                      [22.928, -37.502],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "6",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.889, -1.143],
                      [0, -1.811],
                      [0.872, -1.168],
                      [1.303, 0],
                      [0, 4.672],
                      [0, 0],
                      [-0.762, 0.525],
                      [-1.134, 0],
                    ],
                    o: [
                      [0.889, 1.143],
                      [0, 1.761],
                      [-0.872, 1.168],
                      [-3.233, 0],
                      [0, 0],
                      [0.372, -0.762],
                      [0.762, -0.524],
                      [1.473, 0],
                    ],
                    v: [
                      [18.751, -16.669],
                      [20.084, -12.238],
                      [18.776, -7.846],
                      [15.514, -6.094],
                      [10.664, -13.102],
                      [10.664, -15.666],
                      [12.365, -17.596],
                      [15.209, -18.383],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 38,
      },
      {
        ty: 4,
        nm: "87 Outlines",
        sr: 1,
        st: 0,
        op: 151.000006150356,
        ip: 121.000004928431,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [27, 27, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [366, 260, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "8",
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.184, 1.862],
                      [3.741, 0],
                      [2.184, -1.862],
                      [0, -3.216],
                      [-0.897, -1.371],
                      [-1.574, -0.829],
                      [1.016, -1.523],
                      [0, -1.98],
                      [-2.319, -1.921],
                      [-4.012, 0],
                      [-2.277, 1.913],
                      [0, 3.301],
                      [1.032, 1.498],
                      [1.76, 0.863],
                      [-0.889, 1.371],
                      [0, 1.794],
                    ],
                    o: [
                      [-2.184, -1.862],
                      [-3.707, 0],
                      [-2.184, 1.862],
                      [0, 1.794],
                      [0.897, 1.371],
                      [-1.862, 0.88],
                      [-1.016, 1.523],
                      [0, 3.284],
                      [2.319, 1.921],
                      [3.995, 0],
                      [2.276, -1.913],
                      [0, -2.048],
                      [-1.033, -1.498],
                      [1.557, -0.829],
                      [0.889, -1.371],
                      [0, -3.216],
                    ],
                    v: [
                      [-6.195, -34.709],
                      [-15.082, -37.502],
                      [-23.918, -34.709],
                      [-27.193, -27.092],
                      [-25.848, -22.344],
                      [-22.141, -19.043],
                      [-26.457, -15.438],
                      [-27.98, -10.182],
                      [-24.502, -2.374],
                      [-15.006, 0.508],
                      [-5.599, -2.361],
                      [-2.184, -10.182],
                      [-3.732, -15.501],
                      [-7.922, -19.043],
                      [-4.253, -22.344],
                      [-2.92, -27.092],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.778, -0.846],
                      [1.27, 0],
                      [0.804, 0.855],
                      [0, 1.473],
                      [-0.796, 0.821],
                      [-1.287, 0],
                      [-0.787, -0.821],
                      [0, -1.523],
                    ],
                    o: [
                      [-0.779, 0.847],
                      [-1.304, 0],
                      [-0.804, -0.855],
                      [0, -1.523],
                      [0.795, -0.821],
                      [1.286, 0],
                      [0.787, 0.821],
                      [0, 1.49],
                    ],
                    v: [
                      [-11.934, -7.363],
                      [-15.006, -6.094],
                      [-18.167, -7.376],
                      [-19.373, -10.867],
                      [-18.18, -14.384],
                      [-15.057, -15.615],
                      [-11.946, -14.384],
                      [-10.766, -10.867],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.601, -0.762],
                      [0, -1.32],
                      [0.584, -0.812],
                      [1.168, 0],
                      [0.609, 0.812],
                      [0, 1.32],
                      [-0.601, 0.753],
                      [-1.151, 0],
                    ],
                    o: [
                      [0.601, 0.762],
                      [0, 1.32],
                      [-0.584, 0.812],
                      [-1.185, 0],
                      [-0.609, -0.812],
                      [0, -1.337],
                      [0.601, -0.753],
                      [1.185, 0],
                    ],
                    v: [
                      [-12.403, -29.758],
                      [-11.502, -26.635],
                      [-12.378, -23.436],
                      [-15.006, -22.217],
                      [-17.697, -23.436],
                      [-18.611, -26.635],
                      [-17.71, -29.771],
                      [-15.082, -30.9],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "7",
            ix: 2,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "7",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [27.955, -36.969],
                      [1.32, -36.969],
                      [1.32, -30.367],
                      [18.941, -30.367],
                      [5.104, 0],
                      [14.143, 0],
                      [27.955, -32.398],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 39,
      },
      {
        ty: 4,
        nm: "83 Outlines",
        sr: 1,
        st: 0,
        op: 121.000004928431,
        ip: 91.000003706506,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [27, 27, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [366, 260, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "8",
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.184, 1.862],
                      [3.741, 0],
                      [2.184, -1.862],
                      [0, -3.216],
                      [-0.897, -1.371],
                      [-1.574, -0.829],
                      [1.016, -1.523],
                      [0, -1.98],
                      [-2.319, -1.921],
                      [-4.012, 0],
                      [-2.277, 1.913],
                      [0, 3.301],
                      [1.032, 1.498],
                      [1.76, 0.863],
                      [-0.889, 1.371],
                      [0, 1.794],
                    ],
                    o: [
                      [-2.184, -1.862],
                      [-3.707, 0],
                      [-2.184, 1.862],
                      [0, 1.794],
                      [0.897, 1.371],
                      [-1.862, 0.88],
                      [-1.016, 1.523],
                      [0, 3.284],
                      [2.319, 1.921],
                      [3.995, 0],
                      [2.276, -1.913],
                      [0, -2.048],
                      [-1.033, -1.498],
                      [1.557, -0.829],
                      [0.889, -1.371],
                      [0, -3.216],
                    ],
                    v: [
                      [-6.195, -34.709],
                      [-15.082, -37.502],
                      [-23.918, -34.709],
                      [-27.193, -27.092],
                      [-25.848, -22.344],
                      [-22.141, -19.043],
                      [-26.457, -15.438],
                      [-27.98, -10.182],
                      [-24.502, -2.374],
                      [-15.006, 0.508],
                      [-5.599, -2.361],
                      [-2.184, -10.182],
                      [-3.732, -15.501],
                      [-7.922, -19.043],
                      [-4.253, -22.344],
                      [-2.92, -27.092],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.778, -0.846],
                      [1.27, 0],
                      [0.804, 0.855],
                      [0, 1.473],
                      [-0.796, 0.821],
                      [-1.287, 0],
                      [-0.787, -0.821],
                      [0, -1.523],
                    ],
                    o: [
                      [-0.779, 0.847],
                      [-1.304, 0],
                      [-0.804, -0.855],
                      [0, -1.523],
                      [0.795, -0.821],
                      [1.286, 0],
                      [0.787, 0.821],
                      [0, 1.49],
                    ],
                    v: [
                      [-11.934, -7.363],
                      [-15.006, -6.094],
                      [-18.167, -7.376],
                      [-19.373, -10.867],
                      [-18.18, -14.384],
                      [-15.057, -15.615],
                      [-11.946, -14.384],
                      [-10.766, -10.867],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "8",
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.601, -0.762],
                      [0, -1.32],
                      [0.584, -0.812],
                      [1.168, 0],
                      [0.609, 0.812],
                      [0, 1.32],
                      [-0.601, 0.753],
                      [-1.151, 0],
                    ],
                    o: [
                      [0.601, 0.762],
                      [0, 1.32],
                      [-0.584, 0.812],
                      [-1.185, 0],
                      [-0.609, -0.812],
                      [0, -1.337],
                      [0.601, -0.753],
                      [1.185, 0],
                    ],
                    v: [
                      [-12.403, -29.758],
                      [-11.502, -26.635],
                      [-12.378, -23.436],
                      [-15.006, -22.217],
                      [-17.697, -23.436],
                      [-18.611, -26.635],
                      [-17.71, -29.771],
                      [-15.082, -30.9],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "3",
            ix: 2,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "3",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.931, -0.778],
                      [0, -1.862],
                      [0.897, -0.838],
                      [1.506, 0],
                      [0.914, 0.83],
                      [0, 1.134],
                      [0, 0],
                      [-1.066, -1.633],
                      [-2.074, -0.922],
                      [-2.421, 0],
                      [-2.539, 1.98],
                      [0, 3.301],
                      [1.117, 1.447],
                      [1.963, 0.711],
                      [-0.974, 1.414],
                      [0, 1.591],
                      [2.353, 1.879],
                      [4.113, 0],
                      [1.921, -0.855],
                      [1.083, -1.523],
                      [0, -1.946],
                      [0, 0],
                      [-0.838, 0.66],
                      [-1.134, 0],
                      [-0.762, -0.77],
                      [0, -1.219],
                      [3.199, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [1.642, 0],
                      [0.931, 0.779],
                      [0, 1.304],
                      [-0.897, 0.838],
                      [-1.337, 0],
                      [-0.914, -0.829],
                      [0, 0],
                      [0, 2.082],
                      [1.066, 1.634],
                      [2.073, 0.922],
                      [4.13, 0],
                      [2.539, -1.98],
                      [0, -2.133],
                      [-1.117, -1.447],
                      [1.862, -0.88],
                      [0.973, -1.413],
                      [0, -3.284],
                      [-2.353, -1.879],
                      [-2.302, 0],
                      [-1.921, 0.855],
                      [-1.083, 1.523],
                      [0, 0],
                      [0, -0.982],
                      [0.838, -0.66],
                      [1.388, 0],
                      [0.762, 0.77],
                      [0, 3.132],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [10.08, -15.691],
                      [14.244, -15.691],
                      [18.104, -14.523],
                      [19.5, -10.562],
                      [18.154, -7.351],
                      [14.549, -6.094],
                      [11.172, -7.338],
                      [9.801, -10.283],
                      [1.193, -10.283],
                      [2.793, -4.71],
                      [7.503, -0.876],
                      [14.244, 0.508],
                      [24.248, -2.463],
                      [28.057, -10.385],
                      [26.381, -15.755],
                      [21.76, -18.992],
                      [26.013, -22.433],
                      [27.473, -26.939],
                      [23.943, -34.684],
                      [14.244, -37.502],
                      [7.909, -36.22],
                      [3.402, -32.652],
                      [1.777, -27.447],
                      [10.334, -27.447],
                      [11.591, -29.91],
                      [14.549, -30.9],
                      [17.773, -29.745],
                      [18.916, -26.762],
                      [14.117, -22.064],
                      [10.08, -22.064],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 40,
      },
      {
        ty: 4,
        nm: "76 Outlines",
        sr: 1,
        st: 0,
        op: 91.000003706506,
        ip: 61.0000024845809,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [27, 27, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [366, 260, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "7",
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "7",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-2.158, -36.969],
                      [-28.793, -36.969],
                      [-28.793, -30.367],
                      [-11.172, -30.367],
                      [-25.01, 0],
                      [-15.971, 0],
                      [-2.158, -32.398],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "6",
            ix: 2,
            cix: 2,
            np: 5,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "6",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [3.03, -1.727],
                      [1.633, -3.038],
                      [0, -3.809],
                      [0, 0],
                      [-1.151, -2.217],
                      [-2.065, -1.193],
                      [-2.624, 0],
                      [-1.989, 1.109],
                      [-1.109, 1.964],
                      [0, 2.353],
                      [1.896, 2.336],
                      [3.199, 0],
                      [1.93, -1.896],
                      [-2.057, 1.532],
                      [-3.199, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-3.69, 0],
                      [-3.03, 1.727],
                      [-1.634, 3.039],
                      [0, 0],
                      [0.034, 2.895],
                      [1.151, 2.218],
                      [2.065, 1.193],
                      [2.42, 0],
                      [1.989, -1.108],
                      [1.108, -1.963],
                      [0, -3.792],
                      [-1.896, -2.336],
                      [-2.945, 0],
                      [0.474, -2.725],
                      [2.057, -1.532],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [21.633, -37.502],
                      [11.553, -34.912],
                      [4.558, -27.765],
                      [2.107, -17.494],
                      [2.107, -14.066],
                      [3.885, -6.398],
                      [8.709, -1.282],
                      [15.742, 0.508],
                      [22.356, -1.155],
                      [27.003, -5.764],
                      [28.666, -12.238],
                      [25.822, -21.43],
                      [18.18, -24.934],
                      [10.867, -22.09],
                      [14.663, -28.476],
                      [22.547, -30.773],
                      [22.928, -30.773],
                      [22.928, -37.502],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "6",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.889, -1.143],
                      [0, -1.811],
                      [0.872, -1.168],
                      [1.303, 0],
                      [0, 4.672],
                      [0, 0],
                      [-0.762, 0.525],
                      [-1.134, 0],
                    ],
                    o: [
                      [0.889, 1.143],
                      [0, 1.761],
                      [-0.872, 1.168],
                      [-3.233, 0],
                      [0, 0],
                      [0.372, -0.762],
                      [0.762, -0.524],
                      [1.473, 0],
                    ],
                    v: [
                      [18.751, -16.669],
                      [20.084, -12.238],
                      [18.776, -7.846],
                      [15.514, -6.094],
                      [10.664, -13.102],
                      [10.664, -15.666],
                      [12.365, -17.596],
                      [15.209, -18.383],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "mm",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Filter - Merge",
                nm: "Merge Paths 1",
                mm: 1,
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 41,
      },
      {
        ty: 4,
        nm: "72 Outlines",
        sr: 1,
        st: 0,
        op: 61.0000024845809,
        ip: 31.0000012626559,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [27, 27, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [366, 260, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "7",
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "7",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-2.158, -36.969],
                      [-28.793, -36.969],
                      [-28.793, -30.367],
                      [-11.172, -30.367],
                      [-25.01, 0],
                      [-15.971, 0],
                      [-2.158, -32.398],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "2",
            ix: 2,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "2",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.143, 1.515],
                      [-0.542, 1.38],
                      [0, 1.456],
                      [2.158, 1.845],
                      [3.995, 0],
                      [2.014, -1.083],
                      [1.134, -1.921],
                      [0, -2.268],
                      [0, 0],
                      [-0.812, 1.109],
                      [-1.304, 0],
                      [-0.694, -0.829],
                      [0, -1.591],
                      [2.928, -3.335],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [2.539, -2.539],
                      [1.143, -1.515],
                      [0.542, -1.379],
                      [0, -3.453],
                      [-2.158, -1.845],
                      [-2.488, 0],
                      [-2.015, 1.083],
                      [-1.134, 1.921],
                      [0, 0],
                      [0, -1.659],
                      [0.812, -1.108],
                      [1.32, 0],
                      [0.694, 0.83],
                      [0, 1.964],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [28.057, -6.602],
                      [13.508, -6.602],
                      [18.281, -12.111],
                      [23.804, -18.192],
                      [26.33, -22.534],
                      [27.143, -26.787],
                      [23.905, -34.734],
                      [14.676, -37.502],
                      [7.922, -35.877],
                      [3.199, -31.37],
                      [1.498, -25.086],
                      [10.08, -25.086],
                      [11.299, -29.237],
                      [14.473, -30.9],
                      [17.494, -29.656],
                      [18.535, -26.025],
                      [14.143, -18.078],
                      [2.26, -5.586],
                      [2.26, 0],
                      [28.057, 0],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "st",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Stroke",
                nm: "Stroke 1",
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3, ix: 5 },
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 3 },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 42,
      },
      {
        ty: 4,
        nm: "80",
        sr: 1,
        st: 0,
        op: 31.0000012626559,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [9.862, 5.638, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [85.683, 145.91, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 1.987],
                      [0.677, 0],
                      [0, -1.987],
                      [-0.677, 0],
                    ],
                    o: [
                      [0, -1.987],
                      [-0.677, 0],
                      [0, 1.987],
                      [0.677, 0],
                    ],
                    v: [
                      [1.177, 0],
                      [0, -2.605],
                      [-1.178, 0],
                      [0, 2.605],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 2",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 3.415],
                      [-2.724, 0],
                      [0, -3.416],
                      [2.723, 0],
                    ],
                    o: [
                      [0, -3.416],
                      [2.723, 0],
                      [0, 3.415],
                      [-2.724, 0],
                    ],
                    v: [
                      [-4.666, 0],
                      [0, -5.387],
                      [4.666, 0],
                      [0, 5.387],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [14.807, 5.638], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 4,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -0.56],
                      [-0.471, 0],
                      [0, 0.559],
                      [0.471, 0],
                    ],
                    o: [
                      [0, 0.559],
                      [0.471, 0],
                      [0, -0.56],
                      [-0.471, 0],
                    ],
                    v: [
                      [-0.824, -2.164],
                      [0, -1.281],
                      [0.824, -2.164],
                      [0, -3.048],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 2",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0.662],
                      [0.662, 0],
                      [0, -0.663],
                      [-0.662, 0],
                    ],
                    o: [
                      [0, -0.663],
                      [-0.662, 0],
                      [0, 0.662],
                      [0.662, 0],
                    ],
                    v: [
                      [1.134, 1.974],
                      [0, 0.899],
                      [-1.133, 1.974],
                      [0, 3.048],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 3",
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.03],
                      [2.723, 0],
                      [0, 2.002],
                      [-0.869, 0.53],
                      [0, 0.853],
                      [-2.502, 0],
                      [0, -1.87],
                      [0.634, -0.5],
                    ],
                    o: [
                      [0, 2.002],
                      [-2.723, 0],
                      [0, -1.03],
                      [-0.633, -0.5],
                      [0, -1.87],
                      [2.503, 0],
                      [0, 0.853],
                      [0.869, 0.53],
                    ],
                    v: [
                      [4.622, 2.09],
                      [0, 5.388],
                      [-4.622, 2.09],
                      [-3.238, -0.28],
                      [-4.254, -2.311],
                      [0, -5.388],
                      [4.254, -2.311],
                      [3.238, -0.28],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [4.872, 5.638], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 43,
        parent: 44,
      },
      {
        ty: 4,
        nm: "Computer",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [191.982, 162.276, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [470.849, 271.516, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-4.485, 0],
                      [-0.029, 0],
                      [-0.032, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0.033, 0],
                      [0.029, 0],
                      [4.485, 0],
                    ],
                    v: [
                      [5.921, 1.881],
                      [-5.921, 1.881],
                      [-0.09, -1.882],
                      [0, -1.879],
                      [0.09, -1.882],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [66.865, 149.152], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.75],
                      [1.749, 0],
                      [0, 1.749],
                      [-1.75, 0],
                    ],
                    o: [
                      [0, 1.749],
                      [-1.75, 0],
                      [0, -1.75],
                      [1.749, 0],
                    ],
                    v: [
                      [3.167, 0],
                      [0, 3.167],
                      [-3.167, 0],
                      [0, -3.167],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [66.865, 143.017], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [3.646, 2.155],
                      [3.646, 4.385],
                      [-3.646, 4.385],
                      [-3.646, -4.385],
                      [3.483, -4.385],
                      [3.483, -2.155],
                      [-0.739, -2.155],
                      [-0.739, -1.127],
                      [2.969, -1.127],
                      [2.969, 1.003],
                      [-0.739, 1.003],
                      [-0.739, 2.155],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [298.9, 145.631], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [5.162, -4.385],
                      [1.454, 4.386],
                      [-1.452, 4.386],
                      [-5.162, -4.385],
                      [-1.98, -4.385],
                      [0.113, 0.74],
                      [2.256, -4.385],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [289.806, 145.632], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-1.478, -4.385],
                      [1.478, -4.385],
                      [1.478, 4.385],
                      [-1.478, 4.385],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [282.69, 145.632], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-3.458, -4.385],
                      [-0.501, -4.385],
                      [-0.501, 2.093],
                      [3.458, 2.093],
                      [3.458, 4.386],
                      [-3.458, 4.386],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [276.94, 145.632], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [131.044, 2.148],
                      [-131.044, 2.148],
                      [-131.044, -2.148],
                      [131.044, -2.148],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [184.347, 160.11], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.463, 0],
                      [0, 0],
                      [0, 0.463],
                      [0, 0],
                      [-0.463, 0],
                      [0, 0],
                      [0, -0.463],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.463, 0],
                      [0, 0],
                      [0, -0.463],
                      [0, 0],
                      [0.463, 0],
                      [0, 0],
                      [0, 0.463],
                    ],
                    v: [
                      [0.619, 4.954],
                      [-0.619, 4.954],
                      [-1.461, 4.114],
                      [-1.461, -4.113],
                      [-0.619, -4.954],
                      [0.619, -4.954],
                      [1.461, -4.113],
                      [1.461, 4.114],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [100.189, 172.953], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.463, 0],
                      [0, 0],
                      [0, 0.463],
                      [0, 0],
                      [-0.462, 0],
                      [0, 0],
                      [0, -0.463],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.462, 0],
                      [0, 0],
                      [0, -0.463],
                      [0, 0],
                      [0.463, 0],
                      [0, 0],
                      [0, 0.463],
                    ],
                    v: [
                      [0.619, 4.954],
                      [-0.62, 4.954],
                      [-1.461, 4.114],
                      [-1.461, -4.113],
                      [-0.62, -4.954],
                      [0.619, -4.954],
                      [1.461, -4.113],
                      [1.461, 4.114],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [94.085, 172.953], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 10",
            ix: 10,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-4.714, -0.001],
                      [-4.714, -5.443],
                      [-0.001, -2.722],
                      [4.714, -0.001],
                      [-0.001, 2.721],
                      [-4.714, 5.443],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [73.285, 172.895], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 11",
            ix: 11,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0.477],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.476, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.287, -1.904],
                      [0.287, 0.18],
                      [-2.011, 0.18],
                      [-2.011, 1.904],
                      [1.149, 1.904],
                      [2.011, 1.041],
                      [2.011, -1.904],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [296.829, 176.078], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 12",
            ix: 12,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.476, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, -0.475],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [0.287, 1.83],
                      [2.011, 1.83],
                      [2.011, -0.969],
                      [1.149, -1.83],
                      [-2.011, -1.83],
                      [-2.011, -0.107],
                      [0.287, -0.107],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [296.829, 168.896], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 13",
            ix: 13,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.476, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0.477],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-0.35, -1.904],
                      [-2.074, -1.904],
                      [-2.074, 1.041],
                      [-1.212, 1.904],
                      [2.074, 1.904],
                      [2.074, 0.18],
                      [-0.35, 0.18],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [289.998, 176.078], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 14",
            ix: 14,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -0.475],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.476, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-0.35, 1.83],
                      [-0.35, -0.107],
                      [2.074, -0.107],
                      [2.074, -1.83],
                      [-1.212, -1.83],
                      [-2.074, -0.969],
                      [-2.074, 1.83],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [289.998, 168.896], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 15",
            ix: 15,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.752, 0],
                      [0, 0],
                      [0, 0.749],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0.749],
                      [0, 0],
                      [-0.752, 0],
                      [0, 0],
                    ],
                    v: [
                      [-131.044, -11.423],
                      [131.044, -11.423],
                      [131.044, 10.064],
                      [129.683, 11.423],
                      [-129.683, 11.423],
                      [-131.044, 10.064],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [184.347, 173.681], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 16",
            ix: 16,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [3.547, 0],
                      [0, 0],
                      [0, 3.547],
                      [0, 0],
                      [-3.547, 0],
                      [0, 0],
                      [0, -3.547],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-3.547, 0],
                      [0, 0],
                      [0, -3.547],
                      [0, 0],
                      [3.547, 0],
                      [0, 0],
                      [0, 3.547],
                    ],
                    v: [
                      [124.622, 73.558],
                      [-124.622, 73.558],
                      [-131.044, 67.136],
                      [-131.044, -67.135],
                      [-124.622, -73.558],
                      [124.622, -73.558],
                      [131.044, -67.135],
                      [131.044, 67.136],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [184.347, 111.547], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 17",
            ix: 17,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.256, 0.256],
                      [0, 0],
                      [0.255, 0.255],
                      [0, 0],
                      [0.255, -0.256],
                      [0, 0],
                      [-0.256, -0.256],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.255, -0.255],
                      [0, 0],
                      [-0.256, -0.256],
                      [0, 0],
                      [-0.256, 0.256],
                      [0, 0],
                      [0.256, 0.256],
                    ],
                    v: [
                      [2.157, 2.273],
                      [2.274, 2.157],
                      [2.274, 1.231],
                      [-1.231, -2.274],
                      [-2.156, -2.274],
                      [-2.273, -2.157],
                      [-2.273, -1.231],
                      [1.231, 2.273],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [342.524, 24.141], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 18",
            ix: 18,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.256, 0.256],
                      [0, 0],
                      [-0.256, 0.255],
                      [0, 0],
                      [-0.255, -0.256],
                      [0, 0],
                      [0.255, -0.256],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.256, -0.255],
                      [0, 0],
                      [0.256, -0.256],
                      [0, 0],
                      [0.255, 0.256],
                      [0, 0],
                      [-0.256, 0.256],
                    ],
                    v: [
                      [-2.157, 2.273],
                      [-2.273, 2.157],
                      [-2.273, 1.231],
                      [1.231, -2.274],
                      [2.156, -2.274],
                      [2.274, -2.157],
                      [2.274, -1.231],
                      [-1.231, 2.273],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [342.525, 24.141], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 19",
            ix: 19,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.312, 0.181],
                      [0, 0],
                      [0, 0],
                      [0.312, 0.18],
                      [0, 0],
                      [0, 0],
                      [0, -0.36],
                    ],
                    o: [
                      [0, 0],
                      [0, 0.361],
                      [0, 0],
                      [0, 0],
                      [0.312, -0.18],
                      [0, 0],
                      [0, 0],
                      [-0.312, -0.181],
                      [0, 0],
                    ],
                    v: [
                      [-2.72, 0],
                      [-2.72, 2.555],
                      [-2.018, 2.961],
                      [0.195, 1.684],
                      [2.409, 0.406],
                      [2.409, -0.406],
                      [0.195, -1.684],
                      [-2.018, -2.961],
                      [-2.72, -2.556],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0, 0, 0], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [33.768, 24.235], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 20",
            ix: 20,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.312, 0.181],
                      [0, 0],
                      [0, 0],
                      [-0.312, 0.18],
                      [0, 0],
                      [0, 0],
                      [0, -0.36],
                    ],
                    o: [
                      [0, 0],
                      [0, 0.361],
                      [0, 0],
                      [0, 0],
                      [-0.312, -0.18],
                      [0, 0],
                      [0, 0],
                      [0.312, -0.181],
                      [0, 0],
                    ],
                    v: [
                      [2.721, 0],
                      [2.721, 2.555],
                      [2.018, 2.961],
                      [-0.196, 1.684],
                      [-2.409, 0.406],
                      [-2.409, -0.406],
                      [-0.196, -1.684],
                      [2.018, -2.961],
                      [2.721, -2.556],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0, 0, 0], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [24.439, 24.235], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 21",
            ix: 21,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.339, 0],
                      [0, 0],
                      [0, 1.339],
                      [0, 0],
                      [-1.339, 0],
                      [0, 0],
                      [0, -1.339],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-1.339, 0],
                      [0, 0],
                      [0, -1.339],
                      [0, 0],
                      [1.339, 0],
                      [0, 0],
                      [0, 1.339],
                    ],
                    v: [
                      [132.737, 2.76],
                      [-132.737, 2.76],
                      [-135.172, 0.326],
                      [-135.172, -0.326],
                      [-132.737, -2.76],
                      [132.737, -2.76],
                      [135.172, -0.326],
                      [135.172, 0.326],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8235, 0.8627, 0.9255], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [179.735, 24.235], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 22",
            ix: 22,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.441],
                      [1.44, 0],
                      [0, 1.441],
                      [-1.442, 0],
                    ],
                    o: [
                      [0, 1.441],
                      [-1.442, 0],
                      [0, -1.441],
                      [1.44, 0],
                    ],
                    v: [
                      [2.61, 0],
                      [0.001, 2.609],
                      [-2.61, 0],
                      [0.001, -2.609],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3255, 0.5725, 0.9529], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [334.242, 24.235], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 23",
            ix: 23,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.441],
                      [1.44, 0],
                      [0, 1.441],
                      [-1.442, 0],
                    ],
                    o: [
                      [0, 1.441],
                      [-1.442, 0],
                      [0, -1.441],
                      [1.44, 0],
                    ],
                    v: [
                      [2.61, 0],
                      [0.001, 2.609],
                      [-2.61, 0],
                      [0.001, -2.609],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8745, 0.3804, 0.3608], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [325.785, 24.235], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 24",
            ix: 24,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.698],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.698, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -1.698],
                      [0, 0],
                      [1.698, 0],
                    ],
                    v: [
                      [169.913, -6.531],
                      [169.913, 9.602],
                      [-169.912, 9.602],
                      [-169.912, -6.531],
                      [-166.84, -9.602],
                      [166.84, -9.602],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [184.803, 24.493], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 25",
            ix: 25,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.698],
                      [0, 0],
                      [1.698, 0],
                      [0, 0],
                      [0, 1.697],
                      [0, 0],
                      [-1.698, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 1.697],
                      [0, 0],
                      [-1.698, 0],
                      [0, 0],
                      [0, -1.698],
                      [0, 0],
                      [1.698, 0],
                    ],
                    v: [
                      [169.913, -85.475],
                      [169.913, 85.475],
                      [166.84, 88.546],
                      [-166.84, 88.546],
                      [-169.912, 85.475],
                      [-169.912, -85.475],
                      [-166.84, -88.546],
                      [166.84, -88.546],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [184.803, 103.437], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 26",
            ix: 26,
            cix: 2,
            np: 3,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [1.698, 0],
                      [0, 0],
                      [0, 1.697],
                      [0, 0],
                      [-1.698, 0],
                      [0, 0],
                      [0, -1.697],
                    ],
                    o: [
                      [0, 1.697],
                      [0, 0],
                      [-1.698, 0],
                      [0, 0],
                      [0, -1.697],
                      [0, 0],
                      [1.698, 0],
                      [0, 0],
                    ],
                    v: [
                      [169.913, 84.489],
                      [166.84, 87.56],
                      [-166.84, 87.56],
                      [-169.912, 84.489],
                      [-169.912, -86.46],
                      [-166.84, -89.531],
                      [166.84, -89.531],
                      [169.913, -86.46],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 2",
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [5.147, 1.054],
                      [0.093, 0.014],
                      [0.045, 0.006],
                      [0.095, 0.011],
                      [0.147, 0.012],
                      [0.086, 0.006],
                      [0, 0],
                      [0.123, 0.005],
                      [0.169, 0],
                      [0, 0],
                      [0, -6.236],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-0.093, -0.022],
                      [-0.045, -0.009],
                      [-0.096, -0.019],
                      [-0.147, -0.019],
                      [-0.087, -0.011],
                      [0, 0],
                      [-0.121, -0.011],
                      [-0.166, -0.009],
                      [0, 0],
                      [-6.236, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -5.456],
                    ],
                    v: [
                      [175.534, -103.941],
                      [175.258, -103.994],
                      [175.123, -104.017],
                      [174.839, -104.062],
                      [174.402, -104.113],
                      [174.141, -104.138],
                      [174.126, -104.138],
                      [173.761, -104.16],
                      [173.259, -104.172],
                      [-173.26, -104.172],
                      [-184.552, -92.879],
                      [-184.552, 104.172],
                      [184.552, 104.172],
                      [184.552, -92.879],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [184.803, 104.422], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 27",
            ix: 27,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -5.457],
                      [0, 0],
                      [6.236, 0],
                      [0, 0],
                      [0, 6.236],
                      [0, 0],
                      [-6.236, 0],
                      [0, 0],
                      [-0.166, -0.008],
                      [-0.121, -0.012],
                      [0, 0],
                      [-0.087, -0.011],
                      [-0.146, -0.02],
                      [-0.095, -0.02],
                      [-0.045, -0.008],
                      [-0.092, -0.022],
                    ],
                    o: [
                      [0, 0],
                      [0, 6.236],
                      [0, 0],
                      [-6.236, 0],
                      [0, 0],
                      [0, -6.236],
                      [0, 0],
                      [0.169, 0],
                      [0.123, 0.006],
                      [0, 0],
                      [0.087, 0.006],
                      [0.146, 0.011],
                      [0.096, 0.011],
                      [0.045, 0.006],
                      [0.094, 0.014],
                      [5.147, 1.053],
                    ],
                    v: [
                      [184.552, -106.393],
                      [184.552, 106.393],
                      [173.259, 117.686],
                      [-173.26, 117.686],
                      [-184.553, 106.393],
                      [-184.553, -106.393],
                      [-173.26, -117.686],
                      [173.259, -117.686],
                      [173.761, -117.675],
                      [174.126, -117.652],
                      [174.141, -117.652],
                      [174.403, -117.627],
                      [174.839, -117.576],
                      [175.123, -117.531],
                      [175.258, -117.509],
                      [175.534, -117.455],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [184.802, 117.936], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 28",
            ix: 28,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.236],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [6.236, 0],
                    ],
                    v: [
                      [17.456, -92.879],
                      [17.456, 104.172],
                      [-17.456, 104.172],
                      [-17.456, -104.172],
                      [6.163, -104.172],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.3451, 0.3451, 0.349], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [366.258, 104.422], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 29",
            ix: 29,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -6.236],
                      [0, 0],
                      [6.236, 0],
                      [0, 0],
                      [0, 6.236],
                      [0, 0],
                      [-6.236, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 6.236],
                      [0, 0],
                      [-6.236, 0],
                      [0, 0],
                      [0, -6.236],
                      [0, 0],
                      [6.236, 0],
                    ],
                    v: [
                      [191.732, -106.393],
                      [191.732, 106.393],
                      [180.439, 117.686],
                      [-180.44, 117.686],
                      [-191.732, 106.393],
                      [-191.732, -106.393],
                      [-180.44, -117.686],
                      [180.439, -117.686],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [191.982, 117.936], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 30",
            ix: 30,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-42.266, 8.33],
                      [42.254, 1.562],
                      [42.266, -8.331],
                      [-42.156, -8.331],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [191.448, 243.939], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 31",
            ix: 31,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [42.26, 27.13],
                      [-42.26, 27.13],
                      [-42.26, -27.131],
                      [42.26, -27.131],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [191.442, 262.739], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 32",
            ix: 32,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-8.794, 0],
                      [0, 0],
                      [0, -8.794],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, -8.794],
                      [0, 0],
                      [8.794, 0],
                      [0, 0],
                    ],
                    v: [
                      [116.585, 12.661],
                      [-116.584, 12.661],
                      [-116.584, 3.26],
                      [-100.662, -12.661],
                      [100.661, -12.661],
                      [116.585, 3.26],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [192.1, 302.531], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 33",
            ix: 33,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [42.26, 27.13],
                      [-42.26, 27.13],
                      [-42.26, -27.131],
                      [42.26, -27.131],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [201.8, 262.739], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 34",
            ix: 34,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.277, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -2.277],
                    ],
                    v: [
                      [10.114, -12.661],
                      [-14.237, -12.661],
                      [-14.237, 12.661],
                      [14.237, 12.661],
                      [14.237, -8.538],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [304.029, 302.53], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 35",
            ix: 35,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -5.006],
                      [97.04, 0],
                      [0, 5.006],
                      [-97.04, 0],
                    ],
                    o: [
                      [0, 5.006],
                      [-97.04, 0],
                      [0, -5.006],
                      [97.04, 0],
                    ],
                    v: [
                      [175.706, 0],
                      [0.001, 9.063],
                      [-175.706, 0],
                      [0.001, -9.063],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [195.129, 315.239], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 44,
      },
      {
        ty: 4,
        nm: "Clock3",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0.676, 6.787, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [26.79, 29.294, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [1],
                t: 114.495,
              },
              { s: [0], t: 241.000009816131 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.182, 0.512],
                      [0, 0],
                      [-0.123, -0.276],
                      [0.279, -0.121],
                      [0, 0],
                      [0.075, 0],
                      [0.107, 0.142],
                    ],
                    o: [
                      [0, 0],
                      [0.279, -0.121],
                      [0.122, 0.275],
                      [0, 0],
                      [-0.072, 0.031],
                      [-0.17, 0],
                      [0, 0],
                    ],
                    v: [
                      [-5.829, 2.05],
                      [6.163, -3.148],
                      [6.89, -2.868],
                      [6.606, -2.15],
                      [-5.789, 3.223],
                      [-6.011, 3.268],
                      [-6.454, 3.048],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [7.261, 3.519], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 45,
        parent: 47,
      },
      {
        ty: 4,
        nm: "Clock2",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [12.227, 15.79, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [27.654, 29.044, 0], ix: 2 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 0,
              },
              { s: [360], t: 241.000009816131 },
            ],
            ix: 10,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.781, 1.038],
                      [0, 0],
                      [0.243, -0.18],
                      [-0.181, -0.242],
                      [0, 0],
                      [-0.17, 0],
                      [-0.072, 0.031],
                    ],
                    o: [
                      [0, 0],
                      [-0.181, -0.242],
                      [-0.245, 0.179],
                      [0, 0],
                      [0.107, 0.142],
                      [0.075, 0],
                      [0, 0],
                    ],
                    v: [
                      [5.419, 6.552],
                      [-5.133, -7.477],
                      [-5.904, -7.59],
                      [-6.019, -6.828],
                      [4.795, 7.55],
                      [5.238, 7.77],
                      [5.46, 7.724],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2235, 0.2235, 0.2314], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [6.45, 8.02], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 46,
        parent: 47,
      },
      {
        ty: 4,
        nm: "Clock",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [27.365, 28.772, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [237.625, 96.069, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -12.005],
                      [0.045, -0.566],
                      [11.566, 0],
                      [0.891, -11.204],
                      [0, 0.58],
                      [-12.157, 0],
                    ],
                    o: [
                      [0, 0.58],
                      [-0.891, -11.204],
                      [-11.57, 0],
                      [-0.045, -0.566],
                      [0, -12.005],
                      [12.153, 0],
                    ],
                    v: [
                      [22.044, 10.025],
                      [21.975, 11.745],
                      [0.001, -8.307],
                      [-21.976, 11.745],
                      [-22.044, 10.025],
                      [0.001, -11.745],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.365, 17.005], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [12.155, 0],
                      [0, 12.004],
                      [-12.155, 0],
                      [0, -12.004],
                    ],
                    o: [
                      [-12.155, 0],
                      [0, -12.004],
                      [12.155, 0],
                      [0, 12.004],
                    ],
                    v: [
                      [0, 21.77],
                      [-22.044, 0],
                      [0, -21.77],
                      [22.044, 0],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.365, 27.029], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -14.79],
                      [14.975, 0],
                      [0, 14.79],
                      [-14.976, 0],
                    ],
                    o: [
                      [0, 14.79],
                      [-14.976, 0],
                      [0, -14.79],
                      [14.975, 0],
                    ],
                    v: [
                      [27.115, 0],
                      [0, 26.779],
                      [-27.115, 0],
                      [0, -26.779],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.365, 27.029], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -14.79],
                      [14.975, 0],
                      [0, 14.789],
                      [-14.976, 0],
                    ],
                    o: [
                      [0, 14.789],
                      [-14.976, 0],
                      [0, -14.79],
                      [14.975, 0],
                    ],
                    v: [
                      [27.115, 0],
                      [0, 26.779],
                      [-27.115, 0],
                      [0, -26.779],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [27.365, 30.515], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 47,
      },
      {
        ty: 4,
        nm: "Bg",
        sr: 1,
        st: 0,
        op: 5131.00020898991,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [276.265, 169.001, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [364.933, 219.252, 0], ix: 2 },
          r: { a: 0, k: 0, ix: 10 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
        },
        ef: [],
        shapes: [
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 1",
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0.023, 160.071],
                      [55.597, 3.551],
                      [0.996, 0.046],
                      [72.113, -10.964],
                      [86.288, -36.113],
                      [-227.702, -7.307],
                    ],
                    o: [
                      [-0.009, -53.652],
                      [-0.977, -0.062],
                      [-79.704, -3.715],
                      [-72.113, 10.964],
                      [-77.498, 32.433],
                      [182.182, 5.848],
                    ],
                    v: [
                      [264.093, -43.115],
                      [163.816, -159.108],
                      [160.857, -159.271],
                      [2.966, -54.81],
                      [-210.506, -63.083],
                      [-81.293, 158.906],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [264.093, 159.376], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 2",
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -3.317],
                      [3.318, 0],
                      [0, 3.316],
                      [-3.316, 0],
                    ],
                    o: [
                      [0, 3.316],
                      [-3.316, 0],
                      [0, -3.317],
                      [3.318, 0],
                    ],
                    v: [
                      [6.006, 0],
                      [-0.001, 6.005],
                      [-6.006, 0],
                      [-0.001, -6.006],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [506.184, 7.462], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 3",
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-2.421, -4.879],
                      [4.88, -2.42],
                      [2.42, 4.88],
                      [-4.88, 2.42],
                    ],
                    o: [
                      [2.42, 4.88],
                      [-4.88, 2.42],
                      [-2.42, -4.879],
                      [4.88, -2.42],
                    ],
                    v: [
                      [8.836, -4.383],
                      [4.382, 8.835],
                      [-8.836, 4.381],
                      [-4.382, -8.836],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [494.354, 318.793], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 4",
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-3.829, 1.48],
                      [-1.203, -2.004],
                      [5.132, 0.941],
                    ],
                    o: [
                      [2.182, -0.843],
                      [2.053, 3.421],
                      [-4.704, -2.274],
                    ],
                    v: [
                      [-1.655, -4.674],
                      [4.251, -2.695],
                      [-1.6, 4.576],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [477.199, 332.152], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 5",
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-1.47, -5.245],
                      [5.245, -1.471],
                      [1.471, 5.244],
                      [-5.244, 1.47],
                    ],
                    o: [
                      [1.47, 5.245],
                      [-5.245, 1.47],
                      [-1.47, -5.245],
                      [5.245, -1.47],
                    ],
                    v: [
                      [9.496, -2.662],
                      [2.662, 9.497],
                      [-9.497, 2.663],
                      [-2.663, -9.496],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [75.852, 36.328], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 6",
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -5.392],
                      [5.392, 0],
                      [0, 5.392],
                      [-5.391, 0],
                    ],
                    o: [
                      [0, 5.392],
                      [-5.391, 0],
                      [0, -5.392],
                      [5.392, 0],
                    ],
                    v: [
                      [9.762, 0],
                      [0, 9.762],
                      [-9.762, 0],
                      [0, -9.762],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [524.576, 22.402], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 7",
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-4.038, 0.743],
                      [-0.81, -2.194],
                      [4.867, 1.88],
                    ],
                    o: [
                      [2.3, -0.423],
                      [1.38, 3.743],
                      [-4.198, -3.109],
                    ],
                    v: [
                      [-0.475, -5.278],
                      [4.96, -2.234],
                      [-2.143, 3.82],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [56.231, 46.642], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 8",
            ix: 8,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -4.455],
                      [4.139, 0],
                      [0, 4.455],
                      [-4.139, 0],
                    ],
                    o: [
                      [0, 4.455],
                      [-4.139, 0],
                      [0, -4.455],
                      [4.139, 0],
                    ],
                    v: [
                      [7.495, 0],
                      [0, 8.066],
                      [-7.494, 0],
                      [0, -8.066],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [545.036, 94.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: "gr",
            bm: 0,
            hd: false,
            mn: "ADBE Vector Group",
            nm: "Group 9",
            ix: 9,
            cix: 2,
            np: 2,
            it: [
              {
                ty: "sh",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Shape - Group",
                nm: "Path 1",
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [94.853, 14.004],
                      [19.611, 14.802],
                      [58.476, -18.739],
                      [-21.009, -40.619],
                      [-193.142, 18.926],
                      [92.179, 35.08],
                    ],
                    o: [
                      [-23.98, -3.54],
                      [-29.292, -22.109],
                      [-80.656, 25.848],
                      [21.009, 40.619],
                      [172.834, -16.936],
                      [-79.949, -30.426],
                    ],
                    v: [
                      [17.51, -70.321],
                      [-48.808, -98.426],
                      [-197.875, -139.418],
                      [-257.882, 1.825],
                      [105.251, 142.464],
                      [219.587, -103.415],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: "fl",
                bm: 0,
                hd: false,
                mn: "ADBE Vector Graphic - Fill",
                nm: "Fill 1",
                c: { a: 0, k: [0.8078, 0.8275, 0.902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: "tr",
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [278.709, 193.514], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 48,
      },
    ],
    v: "4.8.0",
    fr: 59.9400024414062,
    op: 242.000009856862,
    ip: 0,
    assets: [],
  };